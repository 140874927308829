import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
  query Organization($id: String!) {
    org: organization(id: $id) {
      id
      name
      organizationTheme {
        images {
          url
          name
        }
      }
      contact {
        phone
        email
        address {
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      primaryContact {
        name {
          first
          last
          prefix
        }
        phone
        email
      }
      type
    }
  }
`;
