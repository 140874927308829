import React, { FunctionComponent } from 'react';
import styles from './datepicker.module.scss';
import '../../../assets/css/datepicker-base.scss';
import { DateRangePicker } from 'react-date-range';

export type DateRange = {
  startDate?: Date;
  endDate?: Date;
  color?: string;
  key?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  showDateDisplay?: boolean;
};
type Props = {
  onDateSelectionChange: (item: DateRange) => void;
  dateRange: DateRange;
  datePickerClass?: string;
  parentStyle: string;
  locationView?: boolean;
};

const DatePicker: FunctionComponent<Props> = ({ onDateSelectionChange, dateRange, parentStyle, locationView }) => {
  console.log('date: ', parentStyle);
  return (
    <>
      {/* added a div to cover  */}
      {locationView && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            zIndex: 9999,
            height: '167.5px',
            width: '107px',
            marginTop: 170,
            marginLeft: -252,
          }}
        />
      )}
      <DateRangePicker
        className={parentStyle}
        classNames={{
          calendarWrapper: styles.definedRangeText,
          definedRangesWrapper: styles.dpDefinedRangesWrapper,
          monthAndYearWrapper: styles.monthYearWrapper,
          monthAndYearPickers: styles.monthAndYearText,
          nextButton: styles.nextButton,
          prevButton: styles.prevButton,
          weekDays: styles.weekDays,
          monthName: styles.monthName,
          staticRangeLabel: styles.staticRangeLabel,
          staticRanges: styles.staticRanges,
          inputRanges: styles.inputRanges,
          staticRange: styles.staticRange,
        }}
        //months={1}
        onChange={item => {
          onDateSelectionChange(item);
          onDateSelectionChange(item)
        }}
        ranges={[dateRange]}
        direction='horizontal'
        weekdayDisplayFormat='EEEEE'
        showMonthAndYearPickers={false}
        showSelectionPreview={true}
        showMonthArrow={true}
        showPreview={true}
        rangedCalendars={true}
        moveRangeOnFirstSelection={false}
        editableDateInputs={false}
        scroll={{ enable: true }}
        inputRanges={[]}
        showDateDisplay={false}
        fixedHeight={true}
        rangeColors={['#6FCF97']}
      />
    </>
  );
};

export default DatePicker;
