import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';
import { auth } from '../firebase/firebase';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_WEB_API_URL}/graphql`,
});
let token = '';
auth.onAuthStateChanged(async r => {
  if (!r) {
    token = '';
    return;
  }
  try {
    token = (await r?.getIdTokenResult()).token;
  } catch (err) {
    console.error('token refresh problem', err);
  }
});
const authLink = setContext((_, { headers }) =>
  // get the authentication token from local storage if it exists

  // return the headers to the context so httpLink can read them
  ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }),
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(from([errorLink, httpLink])),
  cache: new InMemoryCache(),
});
