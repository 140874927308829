import React, { FunctionComponent } from 'react';
import styles from './mood-tooltip.module.scss';
import { displayDateTimeFormat } from '../../../../utils/data-parser';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type MoodProps = {
  reverse: boolean;
  date: string;
  title: string;
  value: {
    pa: number;
    overall: number;
    na: number;
  };
};

const MoodTooltip: FunctionComponent<MoodProps> = ({ reverse, date, title, value }) => {
  const timezone = getPatientTimezone();
  return (
    <div className={`${reverse ? styles.moodToolTipReverse : styles.moodToolTip}`}>
      <ul className={styles.moodTooltipContent}>
        <li> {displayDateTimeFormat(date, timezone)}</li>
        <li>
          <span>Positive Affect:</span> {value.pa}
        </li>
        <li>
          <span>
            {title}: {value.overall}
          </span>
        </li>
        <li>
          <span>Negative Affect:</span> {value.na}
        </li>
      </ul>
    </div>
  );
};

export default MoodTooltip;
