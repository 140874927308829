import React from 'react';
import { useField, Field, FieldAttributes } from 'formik';
import styles from './form.module.scss';
import MaskedInput from 'react-text-mask';

type Props = {
  id?: number;
  name: string;
  label: string;
} & FieldAttributes<{}>;

const HeightInput: React.FC<Props> = props => {
  const [field, meta] = useField(props);
  const mask = [/[0-9]/, /\d/, "'", ' ', /\d/, /\d/, "''"];

  return (
    <div className={styles.field__small}>
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <Field name={props.name} type='phone'>
        {({ field, meta }) => (
          <MaskedInput
            className={meta.touched && meta.error ? styles.inputError : ''}
            {...field}
            mask={mask}
            id='height'
            type='text'
            placeholder="00' 00''"
            {...field}
            {...props}
          />
        )}
      </Field>
      {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default HeightInput;
