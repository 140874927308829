import { get, post, destroy } from './base';
import { RemoteData, done, failed, Done, Failed } from '../../utils/remote-data';
import { Devices, IndistinguishableDevices } from '../../interfaces/device';
import { castDateTimeFilters, DateTimeFiltersRequest } from 'utils/requestHelper';

export const getDevices = async (organizationId: string, assignedToPatient: boolean): Promise<RemoteData<Devices>> => {
  try {
    const res = await get(`/organizations/${organizationId}/devices?assignedToPatient=${assignedToPatient}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getIndistinguishableDevices = async (
  organizationId: string,
): Promise<RemoteData<IndistinguishableDevices>> => {
  try {
    const res = await get(`/organizations/${organizationId}/indistinguishable-devices?deviceModelType=BAC`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const assignDeviceToPatient = async (patientId: string, deviceName: string): Promise<Done<any> | Failed> => {
  try {
    const res = await post(`/patients/${patientId}/devicesIssued/${encodeURI(deviceName)}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const unassignDeviceFromPatient = async (
  organizationId: string,
  deviceName: string,
  deviceCondition: string,
): Promise<Done<any> | Failed> => {
  try {
    const res = await destroy(
      `/patients/${organizationId}/devicesIssued/${encodeURI(deviceName)}?deviceCondition=${deviceCondition}`,
    );
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getDeviceMinutes = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<any>> => {
  try {
    const res = await get(`/device-minutes?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getHealthbandInfo = async (
  patientId: string,
): Promise<RemoteData<any>> => {
  try {
    const res = await get(`/tryterra/devices/${patientId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};