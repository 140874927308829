import { AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';
import { Arrow } from '../../../assets/icons';
import { BacSchedule } from '../../../interfaces/bac-schedule';
import { ScheduleType } from '../../../interfaces/enums';
import { createPatientBacSchedule, updatePatientBacSchedule } from '../../../services/api/bac';
import styles from './bac-schedule.module.scss';
import { APIError } from '../../../utils/remote-data';
import { spawn } from 'child_process';

type Props = {
  scheduleType: ScheduleType;
  selectedType?: ScheduleType | undefined;
  expanded: boolean;
  title: string;
  schedule?: BacSchedule;
  handleChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseButton;
  onCloseArrow: () => void;
  updateSchedule: (bacTest: BacSchedule) => void;
  timezone: string;
};

const ScheduleForm: FunctionComponent<Props> = props => {
  const [formValues, setFormValues] = useState<Partial<BacSchedule>>({
    startWindow: '08:00',
    endWindow: '22:00',
    endDate: moment().add(1, 'year').utc().format('YYYY-MM-DDTHH:mm:ss'),
    density: 2,
    type: props.scheduleType,
  });
  const [error, setError] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToasts();

  useEffect(() => {
    if (props.schedule?.id && props.schedule.type === props.scheduleType) {
      setFormValues({
        ...props.schedule,
        endDate: moment(props.schedule.endDate).utc().format('YYYY-MM-DDTHH:mm:ss'),
      });
    }
  }, [props.schedule]);

  const displayToast = (message: string, type: AppearanceTypes) =>
    addToast(message, { appearance: type, autoDismiss: true });

  const onChangeFormFields = (target: EventTarget & HTMLInputElement): void => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value });
  };

  const onEndDateChange = (target: EventTarget & HTMLInputElement): void => {
    const { value } = target;
    setFormValues({
      ...formValues,
      endDate: moment(value).utc().format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const isValidFields = () => {
    const startWindowDate = moment(formValues.startWindow, ['HH.mm']);
    const endWindowDate = moment(formValues.endWindow, ['HH.mm']);
    const endDate = moment(formValues.endDate);
    return (
      startWindowDate.isValid() &&
      endWindowDate.isValid() &&
      endWindowDate.isAfter(startWindowDate) &&
      !!formValues.density &&
      endDate.isValid()
    );
  };
  const submitScheduleChanges = async (event): Promise<void> => {
    try {
      event.preventDefault();
      if (!isValidFields()) {
        displayToast('Invalid values', 'error');
        setError(true);
        return;
      }
      setError(false);
      let result: AxiosResponse<BacSchedule>;

      const body: Partial<BacSchedule> = {
        ...formValues,
        startWindow: moment(formValues.startWindow!, ['HH.mm']).format('HH:mm'),
        endWindow: moment(formValues.endWindow!, ['HH.mm']).format('HH:mm'),
        endDate: moment(formValues.endDate!)
          .tz(props.timezone)
          .set({ hour: 0, minute: 0, second: 0 })
          .add(1, 'd')
          .add(Math.abs(moment(formValues.endDate).tz(props.timezone).utcOffset()), 'm')
          .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
        timezone: props.timezone,
      };
      if (props.schedule?.id) {
        result = await updatePatientBacSchedule(body, props.schedule.id, id!);
      } else {
        result = await createPatientBacSchedule(body, id!);
      }
      displayToast('Saved Schedule Successfully', 'success');
      props.updateSchedule(result.data);
    } catch (err) {
      setError(true);
      displayToast((err as APIError).message!, 'error');
    }
  };

  const parseTime = (time: string): string => {
    if (!time) {
      return '';
    }
    const [h, m] = time.split(':');
    const period = +h >= 12 ? 'PM' : 'AM';
    const hour = +h % 12 ? +h % 12 : 12;
    return `${hour}:${m} ${period} `;
  };

  return (
    <div className={styles.collapseContainer}>
      <div className={`${styles.collapseHeader} clearfix`}>
        <ul>
          <li>
            <label className={styles.container}>
              {props.title}
              <input
                type='radio'
                value={props.scheduleType}
                checked={props.selectedType === props.scheduleType}
                name='schedule'
                onChange={props.handleChange}
              />
              <span className={styles.checkmark}></span>
            </label>
          </li>
          <li>
            <span>From</span> {parseTime(formValues?.startWindow!)}
            <span>To</span> {parseTime(formValues?.endWindow!)}
          </li>
          <li>
            <span>End date: </span>
            {moment(formValues?.endDate).format('MM/DD/YYYY')}
          </li>
          <li>
            {props.scheduleType === ScheduleType.Interval ? (
              <span>
                Every {formValues?.density} {formValues?.density && formValues?.density > 1 ? 'hours' : 'hour'}
              </span>
            ) : (
              <span>Max number of tests: {formValues?.density}</span>
            )}
          </li>
        </ul>
        <div className={styles.collapseIcon}>
          <span onClick={props.onCloseArrow}>
            <Arrow directionUp={props.expanded} />
          </span>
        </div>
      </div>

      <section className={`${styles.collapseInnerContent} ${!props.expanded ? styles.collapsed : ''}`}>
        <div className={`${styles.formContent} ${!props.expanded ? styles.collapsed : ''} clearfix`}>
          <ul className={styles.row}>
            <li className={styles.inputField}>
              <label className={styles.inputLabel}>Test window [within 24 hour day]:</label>
              <label className={`${styles.windowColumn} clearfix`}>
                <div>
                  <span>From</span>
                  <input
                    type='time'
                    className={`${styles.windowField} ${error ? styles.messageError : ''}`}
                    value={formValues.startWindow}
                    name='startWindow'
                    onChange={e => onChangeFormFields(e.currentTarget)}
                  />
                </div>
                <div>
                  <span>To</span>
                  <input
                    type='time'
                    className={`${styles.windowField} ${error ? styles.messageError : ''}`}
                    value={formValues.endWindow}
                    name='endWindow'
                    onChange={e => onChangeFormFields(e.currentTarget)}
                  />
                </div>
              </label>
            </li>

            <li className={styles.inputField}>
              <label className={styles.inputLabel}>Scheduled BAC test end date:</label>
              <input
                type='date'
                className={`${styles.dateField} ${error ? styles.messageError : ''}`}
                name='endDate'
                min={moment().add(1, 'day').format('YYYY-MM-DD')}
                value={moment(formValues.endDate).format('YYYY-MM-DD') || ''}
                onChange={e => onEndDateChange(e.currentTarget)}
              />
            </li>

            <li className={styles.inputField}>
              <label className={styles.inputLabel}>
                {' '}
                {props.scheduleType === ScheduleType.Random ? <span>Daily Test Frequency</span> : <span>Test Frequency</span>}
              </label>
              {props.scheduleType === ScheduleType.Random ? (
                <input
                  type='number'
                  className={`${styles.density} ${error ? styles.messageError : ''}`}
                  name='density'
                  value={formValues?.density || ''}
                  min={1}
                  max={8}
                  onChange={e => onChangeFormFields(e.currentTarget)}
                />
              ) : (
                <div className='frequency-buttons'>
                  {[1, 2, 4, 6, 8].map((value, index) => (
                    <label key={index} className={`${styles.container} ${styles.densityButtons}`}>
                      Every {value} {index === 0 ? 'hour' : 'hours'}
                      <input
                        type='radio'
                        value={value}
                        checked={formValues.density == value}
                        name='density'
                        onChange={e => onChangeFormFields(e.currentTarget)}
                      />
                      <span className={styles.checkmark}></span>
                    </label>
                  ))}
                </div>
              )}
            </li>
          </ul>

          <div className={`${styles.rowButtons}`}>
            <a className={styles.saveButton} onClick={event => submitScheduleChanges(event)}>
              Save Schedule
            </a>
            <a className={styles.cancelButton} onClick={props.onCloseButton}>
              Cancel
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScheduleForm;
