import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import styles from './practitioner-device-stats-container.module.scss';
import DeviceStats from './components/device-stats';
import { updateBACInventory } from '../../services/api/organization';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import PractitionerStats from './components/practitioner-stats';
import ClientAssignedStats from './components/client-assigned-stats';
import { usePractitionerReportQuery } from '../../graphql/codegen/hooks';
import { Loading } from '../common/loading';
import { PtsMetric } from '../../graphql/codegen/schemas';

const PractitionerDeviceStatsContainer = () => {
  const { organization, loading: orgLoading } = useContext(OrganizationContext);
  const [allowDeviceUpdate, setAllowDeviceUpdate] = useState<boolean>(true);
  const queryDates = useMemo(() => {
    return {
      startOfDay: moment().startOf('day').format(),
      endOfDay: moment().endOf('day').format(),
      startLastWeek: moment().subtract(14, 'd').format(),
      endLastWeek: moment().subtract(7, 'd').format(),
      startLastMonth: moment().subtract(1, 'month').format(),
    };
  }, []);
  const org = organization?.data?.org;

  const { loading, data, refetch } = usePractitionerReportQuery({
    variables: {
      id: org?.id,
      metric: PtsMetric.RelapseRisk,
      orgReportGroups: {
        groups: [
          { name: 'stable', higherBound: 24 },
          { name: 'risk', lowerBound: 25, higherBound: 54 },
          { name: 'critical', lowerBound: 55 },
        ],
      },
      ...queryDates,
    },
    skip: orgLoading || !org?.id,
    fetchPolicy: 'cache-and-network',
  });

  const updateBACInventoryAction = async (increase: boolean): Promise<void> => {
    if (org) {
      setAllowDeviceUpdate(false);
      const res = await updateBACInventory(org?.id!, increase);
      if (res.status === 'Done') {
        await refetch();
      }
      setAllowDeviceUpdate(true);
    }
  };

  if (loading)
    return (
      <div className={styles.container}>
        <Loading />
      </div>
    );

  return (
    <div className={styles.container}>
      <PractitionerStats
        practitionerStats={{
          admin: data?.organizationReport.practitionerByRole?.find(value => value.role === 'ADMIN')!,
          clinician: data?.organizationReport.practitionerByRole?.find(value => value.role === 'CLINICIAN')!,
          aftercareManager: data?.organizationReport.practitionerByRole?.find(
            value => value.role === 'AFTERCARE_MANAGER',
          )!,
        }}
      />
      <DeviceStats
        devicesInventory={data?.organizationReport.devices!}
        updateBACInventory={updateBACInventoryAction}
        allowDeviceUpdate={allowDeviceUpdate}
      />
      <ClientAssignedStats
        reportResult={{
          today: data?.organizationReport?.today?.clientsByPractitioner!,
          lastMonth: data?.organizationReport?.lastMonth?.clientsByPractitioner!,
          lastWeek: data?.organizationReport?.lastWeek?.clientsByPractitioner!,
        }}
      />
      <span className={styles.horizontalLine} />
      <span className={styles.verticalLine} />
    </div>
  );
};

export default PractitionerDeviceStatsContainer;
