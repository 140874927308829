import React, { useState, useEffect } from 'react';
import { useField, useFormikContext, FieldAttributes } from 'formik';
import styles from './form.module.scss';
import { format } from 'date-fns';
import moment from 'moment-timezone';

type Props = {
  id?: string;
  name: string;
  label: string;
  selected?: string;
  size?: string;
} & FieldAttributes<any>;

const DateField: React.FC<Props> = props => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<string>(props);
  const [selectedDate, setSelectedDate] = useState<{ day?: string; month?: string; year?: string }>({
    day: void 0,
    month: void 0,
    year: void 0,
  });

  const onDateSelection = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.currentTarget;
    setSelectedDate(date => ({ ...date, [name]: value }));
  };

  useEffect(() => {
    if (field.value) {
      let propDate = moment.utc(field.value);
      setSelectedDate({
        day: propDate.format('DD'),
        month: propDate.format('MM'),
        year: propDate.format('YYYY'),
      });
    }
  }, []);

  useEffect(() => {
    let date = '';
    if (Object.values(selectedDate).every(value => !!value && /^[0-9]+$/gim.test(value))) {
      const { year, month, day } = selectedDate;
      if (/^(0?[1-9]|1[012])$/gm.test(month!) && /^(0?[1-9]|[12]\d|3[01])$/gm.test(day!)) {
        date = format(
          new Date(Number((year?.length === 2 ? '20' : '') + year), Number(month) - 1, Number(day)),
          'yyyy-MM-dd',
        );
      }
    }
    setFieldValue(props.name, date);
  }, [selectedDate]);

  return (
    <div className={styles.dateFieldContainer}>
      {!!props.label && <label htmlFor={props.id || props.name}>{props.label}</label>}
      <div className={styles.dateFields}>
        <div className={styles.formField}>
          <label className={styles.dateLabel} htmlFor={`${props.name}-month`}>
            Month
          </label>
          <input
            className={meta.touched && meta.error ? styles.inputError : props.disabled ? styles.inputDisabled : ''}
            {...field}
            {...props}
            type='text'
            name='month'
            id={`${props.name}-month`}
            maxLength={2}
            onChange={onDateSelection}
            value={selectedDate.month}
            disabled={props.disabled}
            placeholder='MM'
          />
        </div>

        <div className={styles.slashSpacer}>
          <div className={styles.slash}>/</div>
        </div>

        <div className={styles.formField}>
          <label className={styles.dateLabel} htmlFor={`${props.id || props.name}-day`}>
            Day
          </label>
          <input
            className={meta.touched && meta.error ? styles.inputError : props.disabled ? styles.inputDisabled : ''}
            {...field}
            {...props}
            type='text'
            name='day'
            id={`${props.name}-day`}
            maxLength={2}
            onChange={onDateSelection}
            value={selectedDate.day}
            disabled={props.disabled}
            placeholder='DD'
          />
        </div>

        <div className={styles.slashSpacer}>
          <div className={styles.slash}>/</div>
        </div>

        <div className={`${styles.formField} ${styles.year}`}>
          <label className={styles.dateLabel} htmlFor={`${props.name}-year`}>
            Year
          </label>
          <input
            className={meta.touched && meta.error ? styles.inputError : props.disabled ? styles.inputDisabled : ''}
            {...field}
            {...props}
            type='text'
            name='year'
            id={`${props.name}-year`}
            maxLength={4}
            onChange={onDateSelection}
            value={selectedDate.year}
            disabled={props.disabled}
            placeholder='YYYY'
          />
        </div>
      </div>
      {meta.touched && !!meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default DateField;
