import React from 'react';
import { MeetingStatus } from './constants';
import { Meeting } from '../interfaces/meeting';
import { ReactComponent as CheckLogo } from '../assets/icons/pass_icon.svg';
import { ReactComponent as UndeterminedIcon } from '../assets/icons/icn_undetermined.svg';
import { ReactComponent as MeetingWaitingIcon } from '../assets/icons/icn_waiting.svg';
import { BacMiss } from '../assets/icons/bac-miss';

const getMeetingStatus = (meetingStatus: string, meeting: Meeting): JSX.Element => {
  if (meetingStatus.toLowerCase().includes('attended')) {
    return (
      <span style={{ color: '#6fcf97' }}>
        <CheckLogo style={{ marginRight: '6px' }} />
        {MeetingStatus[meetingStatus].toLocaleUpperCase()}
      </span>
    );
  }
  if (meetingStatus.toLowerCase().includes('missed')) {
    return (
      <span style={{ color: '#ED6F6A' }}>
        <BacMiss style={{ marginRight: '6px', color: '#ED6F6A' }} />
        {MeetingStatus[meetingStatus].toLocaleUpperCase()}
      </span>
    );
  }
  if (meetingStatus.toLowerCase().includes('upcoming')) {
    return (
      <span style={{ fontSize: '12px', color: '#a3a6b3' }}>
        UPCOMING
        {meeting.allowRemote && (
          <>
            <br /> REMOTE
          </>
        )}
      </span>
    );
  }
  if (MeetingStatus[meetingStatus].includes(MeetingStatus.PENDING)) {
    return (
      <span style={{ fontSize: '12px', fontWeight: 'bold', color: '#323335' }}>
        {' '}
        {MeetingStatus[meetingStatus].toLocaleUpperCase()}{' '}
      </span>
    );
  }
  if (MeetingStatus[meetingStatus].includes(MeetingStatus.WAITING_FOR_DATA)) {
    return (
      <div style={{ lineHeight: '18px' }}>
        <span style={{ color: '#A3A6B3', display: 'flex', alignItems: 'center', fontWeight: 600 }}>
          <MeetingWaitingIcon style={{ marginRight: '6px' }} />
          {MeetingStatus[meetingStatus].toLocaleUpperCase()}
        </span>
        <div style={{ fontSize: '11px', fontStyle: 'italic', color: '#355873' }}>Location data pending</div>
      </div>
    );
  }
  if (MeetingStatus[meetingStatus].includes(MeetingStatus.UNDETERMINED)) {
    return (
      <div style={{ lineHeight: '18px', color: '#355873' }}>
        <span style={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
          <UndeterminedIcon style={{ marginRight: '6px' }} />
          {MeetingStatus[meetingStatus].toLocaleUpperCase()}
        </span>
        <div style={{ fontSize: '11px', fontStyle: 'italic' }}>Location data not available</div>
      </div>
    );
  }
  return <></>;
};

export default getMeetingStatus;
