import React from 'react';
import Modal from '../modal/Modal';
import styles from './remove-practitioner.module.scss';
import { Patient } from '../../interfaces/patient';
import { Practitioner } from '../../interfaces/practitioner';
import { Done, Failed } from '../../utils/remote-data';
import { useToasts } from 'react-toast-notifications';

type Props = {
  selectedPatient: Patient | undefined;
  practitioner: Practitioner | undefined;
  removePatient: (careTeamId: string, practitionerId: string) => Promise<Done<any> | Failed>;
  closeModal: () => void;
  getPractitionerPatientsAction: () => Promise<void>;
  getPractitionersAction?: () => Promise<void>;
};

const RemoveClient: React.FC<Props> = ({
  selectedPatient,
  practitioner,
  removePatient,
  closeModal,
  getPractitionerPatientsAction,
  getPractitionersAction,
}) => {
  const { addToast } = useToasts();

  const handleRemoveClient = async (): Promise<void> => {
    const result = await removePatient(selectedPatient?.careTeam.id!, practitioner?.id!);
    if (result.status === 'Done') {
      addToast('Patient removed successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      getPractitionersAction && getPractitionersAction();
      getPractitionerPatientsAction();
      closeModal();
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  return (
    <Modal show={true} closeModal={() => {}} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.removeModal}>
        <div className={styles.header}>
          <span>VIEW PRACTITIONER</span>
        </div>
        <div className={styles.body}>
          <span className={styles.confirmationText}>
            {selectedPatient?.careTeam?.careTeamMembers.length === 1
              ? `You can\'t remove ${selectedPatient?.name.first} ${selectedPatient?.name.last}.
                Each patient must have at least 1 practitioner in their ViviHealth Account. If you still would like to remove this patient, please provide them a new practitioner first.`
              : `Are you sure you would like to remove ${selectedPatient?.name.first} ${selectedPatient?.name.last} from ${practitioner?.name.first} ${practitioner?.name.last}'s care?`}
          </span>
          {selectedPatient?.careTeam?.careTeamMembers?.length! > 1 && (
            <span className={styles.footNote}>Note: this cannot be undone.</span>
          )}
        </div>
        <div className={styles.buttons}>
          <button type='button' className={styles.closeBtn} onClick={() => closeModal()}>
            Cancel
          </button>
          <button
            type='button'
            className={styles.submitBtn}
            onClick={() => handleRemoveClient()}
            disabled={selectedPatient?.careTeam?.careTeamMembers.length === 1}
          >
            Remove Client
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveClient;
