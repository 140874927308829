import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { organizationTimezone } from '../contexts/Practitioner';
import moment from 'moment-timezone';
import firebase from 'firebase/app';
import { getSessionStorageOrDefault } from './data-parser';

export const useDidMountEffect = (
  func: { (): void; (): void; (): void; (): void },
  deps: React.DependencyList | undefined,
) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const useIsMountedRef = () => {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return (): void => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
};

export const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
    setIsMobile(mobile);
  }, []);

  return { isMobile };
};

type THook<T extends HTMLElement> = [React.RefObject<T>, boolean];

export const useHover = <T extends HTMLElement>(): THook<T> => {
  const [value, setValue] = useState(false);
  const ref = useRef<T>(null);

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseOut);

      return () => {
        node.removeEventListener('mouseenter', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseOut);
      };
    }
  }, [ref.current]);

  return [ref, value];
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useStateWithSessionStorage = <T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(getSessionStorageOrDefault<T>(key, defaultValue));

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
};

export const useFirebaseDateWithOrganizationTimezone = () => {
  const timezone = organizationTimezone();
  return useMemo(
    () => (timestamp: firebase.firestore.Timestamp) => {
      return moment(timestamp.toDate()).tz(timezone);
    },
    [timezone],
  );
};
