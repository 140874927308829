import React from 'react';

export const DeleteIcon = props => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='15' cy='15' r='14.25' stroke={props.color || '#A3A6B3'} strokeWidth='1.5' />
      <path
        d='M9 9L21 21'
        stroke={props.color || '#A3A6B3'}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 9L9 21'
        stroke={props.color || '#A3A6B3'}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
