import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import TableEmpty from '../../common/table/table-empty';
import styles from './milestone-plan.module.scss';
import UpsertMilestonePlanContainer from './upsert-milestone-plan-container';
import { RecoveryMilestonePlan } from '../../../interfaces/recovery-milestone-plan';
import { PatientContext } from '../../../contexts/PatientContext';

type Props = {};

const MilestonePlan: FunctionComponent<Props> = props => {
  const { patient } = useContext(PatientContext);
  const [showMilestonePlan, setShowMilestonePlan] = useState<boolean>(false);
  const [selectedMilestonePlan, setSelectedMilestonePlan] = useState<RecoveryMilestonePlan | undefined>(undefined);

  const showMilestonePlanModal = (show: boolean): void => {
    setShowMilestonePlan(show);
    document.body.style.overflow = show ? 'hidden' : '';
  };
  return (
    <>
      <TableEmpty>
        <p>No Milestone Plans created yet. </p>
        <p>
          <button className={styles.button} onClick={() => showMilestonePlanModal(true)}>
            Create Recovery Milestone Plan
          </button>
        </p>
      </TableEmpty>
      {showMilestonePlan && (
        <UpsertMilestonePlanContainer
          setShow={showMilestonePlanModal}
          editMode={!!selectedMilestonePlan}
          patient={patient!}
        />
      )}
    </>
  );
};
export default MilestonePlan;
