import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../modal/Modal';
import styles from './update-email-modal.module.scss';
import { Patient } from '../../interfaces/patient';
import { Formik, Form } from 'formik';
import { editClientEmailValidationSchema } from '../../utils/form-validations';
import TextInput from '../common/form/text-input';
import { Loading } from '../common/loading';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  patient: Patient;
  handleUpdateEmail: (email: string) => void;
  showLoading: boolean;
};

const UpdateEmailModal: React.FC<Props> = ({ setShow, patient, handleUpdateEmail, showLoading }) => {
  return showLoading ? (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.loadingState}>
        <Loading />
      </div>
    </Modal>
  ) : (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={editClientEmailValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateEmail(values.email.toLowerCase());
        setSubmitting(false);
      }}
    >
      <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
        <Form className={styles.content}>
          <h2>CHANGE EMAIL</h2>
          <div className={styles.form}>
            <p>Are you sure you want to change the email of {`${patient.name.first} ${patient.name.last}`}</p>
            <p className={styles.p_grey}>Note: the Client must sign up in the ViviCompanion App with this new email.</p>
            <TextInput
              name='email'
              label='New Email Address:'
              size='big'
              placeholder='Email address'
              labelStrong={true}
            />
          </div>
          <div className={styles.buttons}>
            <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
              Cancel
            </button>
            <button className={styles.submitBtn} type='submit'>
              Confirm
            </button>
          </div>
        </Form>
      </Modal>
    </Formik>
  );
};

export default UpdateEmailModal;
