import React, { FunctionComponent, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import tableStyles from '../../admin-clients.module.scss';
import { RemoteData } from '../../../../utils/remote-data';
import Table from '../../../common/table/table';
import { displayDateTimeFormat } from '../../../../utils/data-parser';
import {
  PermissionLabel,
  UserMobilePermissions,
  UserMobilePermissionsHistory,
} from '../../../../interfaces/user-mobile-permissions-history';
import moment from 'moment-timezone';
import { LocationPermissionType, PhonePermissionType } from '../../../../interfaces/patient';
import { getPermissionStateIcon, getPhonePermissionTyeImage } from '../../../../utils/phone-permission-helper';

type Props = {
  userPermissionsHistory: RemoteData<UserMobilePermissionsHistory[]>;
};

const emptyPermissions = (): Partial<UserMobilePermissions> => ({
  motionApproval: undefined,
  cameraApproval: undefined,
  pushApproval: undefined,
  locationPermission: undefined,
  locationApproval: undefined,
  bluetoothApproval: undefined,
  contactsApproval: undefined,
});

const PermissionsHistoryTable: FunctionComponent<Props> = ({ userPermissionsHistory }) => {
  const getPermissionWhichChangedStatus = (
    oldPermissions: UserMobilePermissions,
    newPermissions: UserMobilePermissions,
  ) => {
    let permissionChanged: Partial<{
      oldState: PhonePermissionType;
      newState: PhonePermissionType;
      permissionName: string;
    }> = {};
    Object.keys(oldPermissions || emptyPermissions).some(key => {
      if (oldPermissions[key] !== newPermissions[key]) {
        permissionChanged = {
          oldState: oldPermissions[key] ?? 'off',
          newState: newPermissions[key],
          permissionName: key,
        };
        return true;
      }
    });
    return permissionChanged;
  };

  const columns: Column<UserMobilePermissionsHistory>[] = useMemo(
    () => [
      {
        Header: 'Date Permission Changed',
        accessor: 'modifiedOn',
        Cell: ({ cell: { value } }) => displayDateTimeFormat(value, moment.tz.guess()),
        width: 250,
      },
      {
        Header: 'Permission',
        width: 275,
        accessor: (originalRow): string =>
          getPermissionWhichChangedStatus(originalRow.oldPermissions, originalRow.permissions).permissionName!,
        Cell: ({ cell: { value } }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getPhonePermissionTyeImage(PermissionLabel[value])}
          </div>
        ),
      },
      {
        Header: 'From',
        width: 180,
        accessor: (originalRow): string =>
          getPermissionWhichChangedStatus(originalRow.oldPermissions, originalRow.permissions).oldState!,
        Cell: ({
          cell: { value },
        }: CellProps<UserMobilePermissionsHistory, PhonePermissionType | LocationPermissionType>) => (
          <div>{getPermissionStateIcon(value)}</div>
        ),
      },
      {
        Header: 'To',
        width: 200,
        accessor: (originalRow): string =>
          getPermissionWhichChangedStatus(originalRow.oldPermissions, originalRow.permissions).newState!,
        Cell: ({
          cell: { value, row },
        }: CellProps<UserMobilePermissionsHistory, PhonePermissionType | LocationPermissionType>) => (
          <div>{getPermissionStateIcon(value)}</div>
        ),
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'order', desc: false }] }),
    [],
  );

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: tableStyles.tr,
      style: {
        height: '70px',
      },
    };
  };

  const getHeaderProps = column => {
    return {
      className: tableStyles.th__modal,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: tableStyles.td,
      style: {
        justifyContent: 'flex-start',
      },
    };
  };

  const getTableBodyProps = () => {
    return {
      className: tableStyles.tbody,
      style: {
        height: '291px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className:
        userPermissionsHistory.status === 'Done' && !userPermissionsHistory.data
          ? tableStyles.noContent
          : tableStyles.table,
      style: {
        width: '100%',
        boxShadow: 'none',
      },
    };
  };
  return (
    <Table<UserMobilePermissionsHistory>
      columns={columns}
      data={userPermissionsHistory.status === 'Done' ? userPermissionsHistory.data : []}
      isLoading={userPermissionsHistory.status === 'Loading'}
      error={userPermissionsHistory.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={row => getRowProps(row)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={cell => getCellProps(cell)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 150, minWidth: 80 }}
      initialState={defaultSorting}
      emptyDataMessage='There is no data at this time.'
      isModal={true}
    />
  );
};

export default PermissionsHistoryTable;
