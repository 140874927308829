import React, { FC } from 'react';
import Modal from '../modal/Modal';
import styles from './clear-alerts-modal.module.scss';

type Props = {
  show: boolean;
  hide: () => void;
  clearAlertsAction: () => Promise<void>;
};

const ClearAlertsModal: FC<Props> = ({ show, hide, clearAlertsAction }) => {
  return (
    <Modal show={show} closeModal={() => hide()} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <span>CLEAR ALL ALERTS</span>
        </div>
        <div className={styles.modalBody}>
          <p>Are you sure you would like to clear all alerts for this client?</p>
          <span>Note: this cannot be undone.</span>
        </div>
        <div className={styles.modalFooter}>
          <button
            type='button'
            className={`${styles.btn} ${styles.secondary}`}
            onClick={() => hide()}
            onKeyPress={event => event.key === 'Enter' && hide()}
          >
            Cancel
          </button>
          <button
            type='button'
            className={`${styles.btn} ${styles.primary} ${styles.btnMargin}`}
            onClick={() => clearAlertsAction()}
            onKeyPress={event => event.key === 'Enter' && clearAlertsAction()}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ClearAlertsModal;
