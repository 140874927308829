import React, { useEffect, useMemo, useState } from 'react';
import styles from './relapse.module.scss';
import { getCurrentRelapseRisk } from '../../services/api/firestore';
import { Tooltip } from '../common/tooltip';
import { useFirebaseDateWithOrganizationTimezone, useHover } from '../../utils/hooks';
import { displayDateTimeFormat, parseRelapseRisk } from '../../utils/data-parser';
import moment from 'moment-timezone';
import ArrowUp from '../../assets/icons/icn_arrow_big.svg';
import { RelapseRisk } from '../../interfaces/vitals';
import { RelapseRiskScoreComponentWeight } from '../../utils/constants';
import firebase from 'firebase/app';
import { getPatientTimezone } from '../../contexts/PatientContext';
import * as strings from './strings';

const RelapsePanel = ({ patient }) => {
  const [relapseRisk, setRelapseRisk] = useState<RelapseRisk>({
    score: 0,
    timestamp: moment().valueOf(),
    components: undefined,
  });
  const [relapseValueText, setRelapseValueText] = useState('');
  const [hoverRef, isHovered] = useHover<HTMLParagraphElement>();
  const dateTz = useFirebaseDateWithOrganizationTimezone();
  const patientTimezone = getPatientTimezone();

  useEffect(() => {
    if (!patient) {
      return;
    }

    const unsubscribeInsight = getCurrentRelapseRisk(patient.firebaseUid, {
      next: (Snapshot: firebase.firestore.DocumentSnapshot) => {
        if (Snapshot) {
          const current = Snapshot.data();
          if (current) {
            const { score, timestamp, components } = current;
            setRelapseRisk({
              score: 100 - score,
              components,
              timestamp: dateTz(timestamp).valueOf(),
            });
            setRelapseValueText(parseRelapseRisk(score));
          }
        }
      },
    });

    return (): void => {
      unsubscribeInsight();
    };
  }, [patient]);

  const componentsOrderedByContribution: { title: string; value: number }[] = useMemo(() => {
    return relapseRisk.components
      ? Object.entries(relapseRisk.components)
          .flatMap((value: [string, number]) =>
            Object.keys(RelapseRiskScoreComponentWeight).includes(value[0])
              ? [
                  {
                    title: value[0],
                    value: RelapseRiskScoreComponentWeight[value[0]] * (1 - value[1]),
                  },
                ]
              : [],
          )
          .sort((a, b) => b.value - a.value)
      : [];
  }, [relapseRisk]);

  return (
    <div className={styles.relapse}>
      <div className={styles.header}>
        <span className={styles.title}>Compliance</span>
        <Tooltip baseStyles={styles.infoTooltip} type='right' background='#F5F6FA'>
          <div>
            <h3 className={styles.mainTitle}>{strings.complianceIndicatorToolTipTitle}</h3>
            <p>{strings.complianceIndicatorToolTipDescription}</p>
            <p>{strings.complianceIndicatorToolTipDescriptionP2}</p>
            <ul>
              {strings.toolTipListItemStrings.map((item, index) => (
              <li key={index}>{item}</li>
              ))}
            </ul>
            {/* <div className={styles.bottomContainer}>
              <div>
                <p>
                  <strong>Compliance Ranges</strong>
                </p>
                <div className={styles.relapseRange}>
                  <p>
                    <span className={styles.range}>0-24</span>
                    <span className={styles.text}>
                      <b>Lower</b> risk of recurrence
                    </span>
                  </p>
                  <p>
                    <span className={styles.range}>24-54</span>{' '}
                    <span className={styles.text}>
                      <b>Moderate</b> risk of recurrence
                    </span>
                  </p>
                  <p>
                    <span className={styles.range}>55-100</span>{' '}
                    <span className={styles.text}>
                      <b>Higher</b> risk of recurrence
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.statusContainer}>
                <p>
                  <strong>Client Triage Status</strong>
                </p>
                <div className={`${styles.statusLabel} ${styles.stable}`}>
                  <strong>Stable</strong>
                </div>
                <div className={`${styles.statusLabel} ${styles.atRisk}`}>
                  <strong>At Risk</strong>
                </div>
                <div className={`${styles.statusLabel} ${styles.critical}`}>
                  <strong>Critical</strong>
                </div>
              </div>
              <div className={styles.inlineText}>
                <em>
                  The Compliance Score also determines the client’s triage status and corresponding color state.
                </em>
              </div>
            </div> */}
          </div>
        </Tooltip>
      </div>
            
      <div className={styles.guageContainer}>
        <svg className={styles.gauge} viewBox='0 0 100 50'>
          <g fillOpacity='0' strokeWidth='10'>
            <path d='M5 50a45 45 0 1 1 90 0' stroke='#A3A6B3' />
            <path
              strokeDashoffset={`calc(142 - (${relapseRisk.score || 0} * 142 / 100))`}
              d='M5 50a45 45 0 1 1 90 0'
              stroke='#333335'
              strokeDasharray='142'
            />
          </g>
        </svg>
        <p className={styles.percentage}>{relapseRisk.score}%</p>
        {/* <span className={styles.value}>{<>&nbsp;</>}</span>
        <span className={styles.value}>{<>&nbsp;</>}</span> */}
      </div>
      
      {/* <div ref={hoverRef}>
        <p className={styles.percentage}>{relapseRisk.score}%</p>
        {isHovered && (
          <div className={styles.relapseScoreHover}>
            <span>{displayDateTimeFormat(relapseRisk.timestamp, patientTimezone)}</span>
            <p>
              <strong>
                COMPLIANCE: {relapseRisk.score}% {relapseValueText}
              </strong>
            </p>
            <div className={styles.relapseContent}>
              <div className={styles.textVertical}>
                <div className={styles.rotate}>
                  <span>
                    <img alt='arrow' src={ArrowUp} />
                  </span>
                  <strong>Highest Contributor</strong>
                </div>
              </div>
              <div className={styles.componentsContainer}>
                {componentsOrderedByContribution.map((component, index) => (
                  <div key={index}>{component.title}</div>
                ))}
              </div> 
            </div>
            <div className={styles.hoverRight} />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default RelapsePanel;
