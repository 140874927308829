import React from 'react';

export const Signal = props => {
  return (
    <svg style={props.style} width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.00002 7.61816C5.57998 7.61816 5.23816 7.95998 5.23816 8.38003C5.23816 8.80005 5.57998 9.14189 6.00002 9.14189C6.42009 9.14189 6.7619 8.80005 6.7619 8.38003C6.7619 7.95998 6.42009 7.61816 6.00002 7.61816ZM6.00002 8.634C5.85998 8.634 5.74602 8.52007 5.74602 8.38003C5.74602 8.23999 5.85998 8.12601 6.00002 8.12601C6.14006 8.12601 6.25391 8.23999 6.25391 8.38003C6.25391 8.52007 6.14011 8.634 6.00002 8.634Z'
        fill='#323335'
      />
      <path
        d='M2.94743 5.32762C2.84833 5.42688 2.84833 5.58769 2.94743 5.68682C3.04663 5.78602 3.20735 5.78602 3.30657 5.68682C4.79507 4.19819 7.2047 4.19793 8.69356 5.68682C8.79276 5.78602 8.95347 5.78602 9.05262 5.68682C9.1518 5.58772 9.1518 5.4269 9.05262 5.32762C7.36552 3.64059 4.63472 3.64034 2.94743 5.32762Z'
        fill='#323335'
      />
      <path
        d='M4.34412 6.72425C4.24491 6.82346 4.24491 6.98417 4.34412 7.0834C4.44332 7.18265 4.60406 7.1825 4.70331 7.0834C5.41989 6.36671 6.57998 6.36656 7.29681 7.0834C7.39606 7.1825 7.55678 7.18265 7.65603 7.0834C7.75513 6.9842 7.75513 6.82348 7.65603 6.72425C6.74084 5.80911 5.2594 5.80896 4.34412 6.72425Z'
        fill='#323335'
      />
      <path
        d='M5.99994 2.03174C4.3042 2.03174 2.70992 2.69201 1.51077 3.89104C1.41169 3.99026 1.41169 4.15098 1.51077 4.2502C1.60999 4.34943 1.77083 4.34943 1.86993 4.2502C4.1524 1.96774 7.84713 1.96736 10.13 4.2502C10.2292 4.34943 10.3898 4.34943 10.489 4.2502C10.5882 4.151 10.5882 3.99029 10.489 3.89104C9.28999 2.69201 7.69573 2.03174 5.99994 2.03174Z'
        fill='#323335'
      />
      <path
        d='M11.9256 2.45461C10.3428 0.871732 8.23849 0 5.99999 0C3.76154 0 1.65718 0.871732 0.0743057 2.45461C-0.0247686 2.55368 -0.0247686 2.71455 0.0743057 2.81365C0.173532 2.91288 0.334372 2.91288 0.433472 2.81365C3.50284 -0.255724 8.49716 -0.255724 11.5666 2.81365C11.6657 2.91288 11.8264 2.91288 11.9256 2.81365C12.0248 2.71457 12.0248 2.55371 11.9256 2.45461Z'
        fill='#323335'
      />
    </svg>
  );
};
