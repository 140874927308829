import React from 'react';
import { useField, Field, FieldAttributes } from 'formik';
import styles from './form.module.scss';
import MaskedInput from 'react-text-mask';

type Props = {
  id?: number;
  name: string;
  label: string;
} & FieldAttributes<{}>;

const PhoneInput: React.FC<Props> = props => {
  const [field, meta] = useField(props);
  const phoneNumberMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  return (
    <div className={styles.field}>
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <Field name={props.name} type='phone'>
        {({ field, meta }) => (
          <MaskedInput
            className={meta.touched && meta.error ? styles.inputError : ''}
            {...field}
            mask={phoneNumberMask}
            id='phone'
            placeholder='123-456-7890'
            type='text'
            {...field}
            {...props}
          />
        )}
      </Field>
      {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default PhoneInput;
