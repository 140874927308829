import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { Calendar } from 'react-date-range';
import MaskedInput from 'react-text-mask';
import { useField, useFormikContext } from 'formik';
import styles from './form.module.scss';
import datepickerStyles from '../date-picker/datepicker.module.scss';

const DateTimeInput = props => {
  const node = useRef<HTMLDivElement>(null);
  const timezone = moment.tz.guess();
  const { setFieldValue } = useFormikContext();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [fullDate, setFullDate] = useState<string>('');
  const [field, meta] = useField(props);
  const [selectedDate, setSelectedDate] = useState<Date | string>(moment().local().toDate());
  const [hour, setHour] = useState<string>('');
  const [hourType, setHourType] = useState<string>('AM');
  const hourMask = [/[0-1]/, parseInt(hour?.charAt(0)) < 1 ? /[0-9]/ : /[0-2]/, ':', /[0-5]/, /[0-9]/];
  const [displayDate, setDisplayDate] = useState<string>('');
  const fieldSize = props.size === 'big' ? styles.field__big : styles.field;

  const handleClickOutside = e => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowCalendar(false);
  };

  useEffect(() => {
    if (field.value) {
      const date = moment(`${field.value}Z`).local().format('lll');
      setDisplayDate(date);
      setSelectedDate(moment(field.value).local().toDate());
      const dateTime = moment(`${field.value}Z`).local().format('hh:mm A');
      setHour(dateTime.split(' ')[0]);
      setHourType(dateTime.split(' ')[1]);
    } else {
      const date = moment().local().format('hh:mm A');
      setHour(date.split(' ')[0]);
      setHourType(date.split(' ')[1]);
    }
  }, []);

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showCalendar]);

  useEffect(() => {
    if (selectedDate && hour && hourType && !hour.includes('_')) {
      let _hour = hour.split(':')[0];
      const _minutes = hour.split(':')[1];
      if (_hour === '12') {
        if (hourType === 'AM') {
          _hour = '00';
        }
      } else if (hourType !== 'AM') {
        _hour = `${parseInt(_hour) + 12}`;
      }

      const date = moment(selectedDate).hour(parseInt(_hour)).minutes(parseInt(_minutes)).seconds(0);
      setDisplayDate(date.tz(timezone).local().format('lll'));
      setFullDate(moment.tz(date, timezone).utc().format().split('Z')[0]);
    }
  }, [selectedDate, hour, hourType]);

  useEffect(() => {
    if (fullDate) {
      setFieldValue(props.name, fullDate);
    }
  }, [fullDate]);

  const onDateSelection = date => {
    setSelectedDate(date);
  };

  return (
    <div className={styles.dateContainer}>
      <div className={fieldSize} onClick={() => setShowCalendar(true)}>
        <label htmlFor={props.id || props.name}>{props.label}</label>
        <input
          className={meta.touched && meta.error ? styles.inputError : props.disabled ? styles.inputDisabled : ''}
          value={displayDate}
          readOnly
        />
        <input {...field} {...props} disabled={props.disabled} value={fullDate} style={{ display: 'none' }} />
        {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
      </div>
      {showCalendar && (
        <div className={styles.calendarContainer} ref={node}>
          <Calendar
            rangeColors={['#417EB9']}
            color='#417EB9'
            onChange={date => onDateSelection(date)}
            date={selectedDate}
            className={datepickerStyles.parentStyle}
            classNames={{
              nextButton: datepickerStyles.nextButton,
              prevButton: datepickerStyles.prevButton,
              weekDays: datepickerStyles.weekDays,
              monthPicker: datepickerStyles.monthPicker,
              yearPicker: datepickerStyles.yearPicker,
              selected: datepickerStyles.selected,
              dayHovered: datepickerStyles.dayHovered,
            }}
            weekdayDisplayFormat='EEEEE'
          />
          <MaskedInput
            className={!hour ? styles.hourInput__warning : styles.hourInput}
            mask={hourMask}
            id='hour'
            type='text'
            placeholder={hour}
            onChange={e => setHour(e.target.value)}
          />
          <select
            className={styles.time}
            name='set'
            onChange={e => setHourType(e.target.value)}
            defaultValue={hourType}
          >
            <option value='AM'>AM</option>
            <option value='PM'>PM</option>
          </select>
        </div>
      )}
    </div>
  );
};

export default DateTimeInput;
