import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import Modal from '../modal/Modal';
import styles from './upsert-client.module.scss';
import { Patient } from '../../interfaces/patient';
import {
  DEVICE_DISCHARGE_REASON,
  EPISODE_OF_CARE,
  TREATMENT_COMPLETED_REASON,
  TREATMENT_NOT_COMPLETED_REASON,
} from '../../utils/constants';
import { displayFullName } from '../../utils/data-parser';
import { DischargePatientRequest } from '../../services/api/patient';
import { useOnClickOutside } from '../../utils/hooks';
import { Form, Formik } from 'formik';
import { dischargeValidationScheme } from '../../utils/form-validations';
import { caseOf } from '../../utils/case-of';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  client: Patient;
  dischargePatientAction: (patientId: string, values: DischargePatientRequest) => Promise<void>;
};

const DischargeClientConfirmation: FC<Props> = ({ setShow, client, dischargePatientAction }) => {
  const [showSelectOptions, setShowSelectOptions] = useState<boolean>(false);
  const [showEocDropdown, setShowEocDropdown] = useState<boolean>(false);
  const [showDeviceReasonDropdown, setShowDeviceReasonDropdown] = useState<boolean>(false);
  const [showBacReasonDropdown, setShowBacReasonDropdown] = useState<boolean>(false);
  const eocDropdownRef = useRef<HTMLDivElement>(null);
  const reasonDropdownRef = useRef<HTMLDivElement>(null);
  const deviceDropdownRef = useRef<HTMLDivElement>(null);
  const bacDropdownRef = useRef<HTMLDivElement>(null);
  const TreatmentReason = { ...TREATMENT_COMPLETED_REASON, ...TREATMENT_NOT_COMPLETED_REASON };

  const formValues: DischargePatientRequest = {
    episodeOfCareType: '',
    reason: '',
  };

  useOnClickOutside(reasonDropdownRef, event => {
    setShowSelectOptions(false);
  });

  useOnClickOutside(eocDropdownRef, event => {
    setShowEocDropdown(false);
  });
  useOnClickOutside(bacDropdownRef, event => {
    setShowBacReasonDropdown(false);
  });

  useOnClickOutside(deviceDropdownRef, event => {
    setShowDeviceReasonDropdown(false);
  });

  const handleSelectOption = (
    value: string | boolean,
    field: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setShowSelectOptions(false);
    setShowEocDropdown(false);
    setShowDeviceReasonDropdown(false);
    setShowBacReasonDropdown(false);
    setFieldValue(field, value, true);
  };

  const handleDischargePatient = async (formValues: DischargePatientRequest) => {
    const devices = [client.devicesIssued?.deviceName1, client.devicesIssued?.deviceName2];
    const dischargePayload: DischargePatientRequest = {
      episodeOfCareType: formValues.episodeOfCareType,
      reason: formValues.reason,
      unassignDevices: formValues.unassignedDeviceReason
        ? devices
            .filter(device => !!device)
            .map(device => ({
              name: device!,
              condition: formValues.unassignedDeviceReason!,
            }))
        : [],
    };
    if (formValues.unnasignBac) {
      dischargePayload.unassignDevices?.push({name: null, condition: formValues.unnasignBac })
    }
    await dischargePatientAction(client.id, dischargePayload);
  };

  return (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      <Formik
        initialValues={formValues}
        validationSchema={dischargeValidationScheme({
          healthBand: client.devicesIssued?.deviceName1,
          bac: client.devicesIssued?.bacDeviceProvided,
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleDischargePatient(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className={styles.removeModal}>
              <div className={styles.header}>
                <span>{`DISCHARGE ${client.name.first.toUpperCase()} ${client.name.last.toUpperCase()}`}</span>
              </div>
              <div className={styles.body}>
                <div className={styles.eocContainer}>
                  <span className={styles.confirmationText}>
                    {`Select which episode of care to move ${client.name.first} ${client.name.last} to`}
                  </span>
                  <span className={styles.footNote}>
                    Note: {client.name.first}’s client profile will be updated to reflect this new episode of care.
                  </span>
                  <div ref={eocDropdownRef} className={styles.outerSelect}>
                    <div className={styles.customSelect} onClick={() => setShowEocDropdown(!showEocDropdown)}>
                      {EPISODE_OF_CARE[values.episodeOfCareType] ?? 'Select episode of care'}
                    </div>
                    {showEocDropdown && (
                      <div className={styles.customSelectOptions}>
                        {Object.entries(EPISODE_OF_CARE).map(([key, label]) => (
                          <div
                            className={`${styles.customOption} ${styles.selectable}`}
                            key={key}
                            onClick={() => handleSelectOption(key, 'episodeOfCareType', setFieldValue)}
                          >
                            {label}
                          </div>
                        ))}
                      </div>
                    )}
                    {touched.episodeOfCareType && errors.episodeOfCareType && (
                      <span className={styles.errorText}> Please select the client episode of care</span>
                    )}
                  </div>
                </div>

                <div className={styles.eocContainer}>
                  <span className={styles.confirmationText}>
                    {`Current program status for ${displayFullName(client.name)}`}
                  </span>
                  <span className={styles.footNote} />
                  <div ref={reasonDropdownRef} className={styles.outerSelect}>
                    <div className={styles.customSelect} onClick={() => setShowSelectOptions(!showSelectOptions)}>
                      {TreatmentReason[values.reason] ?? 'Select reason for discharge'}
                    </div>
                    {showSelectOptions && (
                      <div className={styles.customSelectOptions}>
                        <div className={styles.customOption}>
                          <b>If treatment program completed</b>
                        </div>
                        {Object.entries(TREATMENT_COMPLETED_REASON).map(([key, label]) => (
                          <div
                            className={`${styles.customOption} ${styles.selectable}`}
                            key={key}
                            onClick={() => handleSelectOption(key, 'reason', setFieldValue)}
                          >
                            &emsp;{label}
                          </div>
                        ))}
                        <div className={styles.customOption}>
                          <b>If treatment program not completed</b>
                        </div>
                        {Object.entries(TREATMENT_NOT_COMPLETED_REASON).map(([key, label]) => (
                          <div
                            className={`${styles.customOption} ${styles.selectable}`}
                            key={key}
                            onClick={() => handleSelectOption(key, 'reason', setFieldValue)}
                          >
                            &emsp;{label}
                          </div>
                        ))}
                      </div>
                    )}
                    {touched.reason && errors.reason && (
                      <span className={styles.errorText}> Please select the client reason of discharge</span>
                    )}
                  </div>
                </div>

                <div className={styles.eocContainer}>
                  <span className={styles.confirmationText}>Unassign devices</span>
                  <div className={styles.deviceParentContainer}>
                    <div className={styles.deviceContainer}>
                      <span className={styles.deviceName}>
                        <div className={styles.deviceRow}>
                          <span>Device Name:</span>
                          <span>{client.devicesIssued?.deviceName1 ?? 'No device assigned'}</span>
                        </div>
                        <div className={styles.deviceRow}>
                          <span>Device Type:</span>
                          <span>HealthBand</span>
                        </div>
                      </span>
                      <div ref={deviceDropdownRef} className={styles.outerSelect}>
                        <div
                          className={styles.customSelect}
                          onClick={() => setShowDeviceReasonDropdown(!showDeviceReasonDropdown)}
                        >
                          {DEVICE_DISCHARGE_REASON[values.unassignedDeviceReason!]?.HEALTHBAND ??
                            'Select unassign healthband device'}
                        </div>
                        {showDeviceReasonDropdown && (
                          <div className={styles.customSelectOptions}>
                            {Object.entries(DEVICE_DISCHARGE_REASON).map(([key, label]) => (
                              <div
                                className={`${styles.customOption} ${styles.selectable}`}
                                key={key}
                                onClick={() => handleSelectOption(key, 'unassignedDeviceReason', setFieldValue)}
                              >
                                {label.HEALTHBAND}
                              </div>
                            ))}
                          </div>
                        )}
                        {errors.unassignedDeviceReason && (
                          <span className={styles.errorText}> Please select the unassigned device reason</span>
                        )}
                      </div>
                      {!client.devicesIssued?.deviceName1 && <div className={styles.selectDisabled} />}
                    </div>
                    <div className={styles.deviceContainer}>
                      <span className={styles.deviceName}>
                        <div className={styles.deviceRow}>
                          <span>BAC Device Provided:</span>
                          <span>{client.devicesIssued?.bacDeviceProvided ? 'Yes' : 'No'}</span>
                        </div>
                      </span>
                      <div ref={bacDropdownRef} className={styles.outerSelect}>
                        <div
                          className={styles.customSelect}
                          onClick={() => setShowBacReasonDropdown(!showBacReasonDropdown)}
                        >
                          {DEVICE_DISCHARGE_REASON[values.unnasignBac!]?.BAC ??
                            'Select unassign bac device'}
                        </div>
                        {showBacReasonDropdown && (
                          <div className={styles.customSelectOptions}>
                            {Object.entries(DEVICE_DISCHARGE_REASON).map(([key, label]) => (
                              <div
                                className={`${styles.customOption} ${styles.selectable}`}
                                key={key}
                                onClick={() => handleSelectOption(key, 'unnasignBac', setFieldValue)}
                              >
                                {label.BAC}
                              </div>
                            ))}
                          </div>
                        )}
                        {errors.unnasignBac && <span className={styles.errorText}> Please select if BAC provided</span>}
                      </div>
                      {!client.devicesIssued?.bacDeviceProvided && <div className={styles.selectDisabled} />}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttons}>
                <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
                  Cancel
                </button>
                <button className={styles.submitBtn} type='submit'>
                  Confirm
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DischargeClientConfirmation;
