import React from 'react';

export const NoSignal = props => {
  return (
    <svg style={props.style} width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.41677 9.26001C6.03028 9.26001 5.71576 9.57453 5.71576 9.96103C5.71576 10.3475 6.03028 10.662 6.41677 10.662C6.80329 10.662 7.11781 10.3475 7.11781 9.96103C7.11781 9.57453 6.80329 9.26001 6.41677 9.26001ZM6.41677 10.1947C6.28792 10.1947 6.18306 10.0899 6.18306 9.96103C6.18306 9.83217 6.28792 9.72729 6.41677 9.72729C6.54563 9.72729 6.65039 9.83217 6.65039 9.96103C6.65039 10.0899 6.54568 10.1947 6.41677 10.1947Z'
        fill='#323335'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.76204 6.47211C7.54047 6.54881 8.29826 6.8856 8.89519 7.48253C8.98646 7.57381 9.13434 7.57381 9.22557 7.48253C9.31683 7.39135 9.31683 7.24338 9.22557 7.15203C8.64308 6.56956 7.92537 6.20562 7.17391 6.06024L6.76204 6.47211ZM8.30642 4.92773C9.00286 5.18906 9.65616 5.60007 10.2169 6.1608C10.3082 6.2521 10.4559 6.2521 10.5473 6.1608C10.6385 6.06952 10.6385 5.92164 10.5473 5.83032C9.99925 5.28232 9.36154 4.85662 8.66721 4.56694L8.30642 4.92773ZM7.58442 4.23551L7.18015 4.63978C5.56318 4.40872 3.86152 4.9158 2.61652 6.1608C2.52534 6.2521 2.37734 6.2521 2.28604 6.1608C2.19488 6.0695 2.19488 5.92162 2.28604 5.83032C3.38942 4.72705 4.85638 4.11951 6.41669 4.11951C6.81346 4.11951 7.20418 4.15879 7.58442 4.23551ZM9.72031 3.51383C10.3759 3.84919 10.9906 4.29089 11.5387 4.83894C11.6299 4.93024 11.7778 4.93024 11.8691 4.83894C11.9603 4.74778 11.9603 4.59976 11.869 4.50858C11.3289 3.96836 10.7227 3.51816 10.0686 3.16553L9.72031 3.51383ZM9.09442 2.72551L8.72442 3.09551C6.20056 2.25171 3.30082 2.83285 1.29473 4.83894C1.20354 4.93024 1.05555 4.93024 0.964245 4.83894C0.873084 4.74776 0.873084 4.59974 0.964245 4.50858C2.42071 3.05211 4.357 2.25 6.41669 2.25C7.34524 2.25 8.24871 2.41302 9.09442 2.72551ZM5.78129 6.03864L5.11455 6.70539C4.6865 6.87659 4.28528 7.13565 3.93842 7.48253C3.84712 7.57381 3.69924 7.57381 3.60796 7.48253C3.51678 7.39133 3.51678 7.24335 3.60796 7.15203C4.22186 6.53813 4.98588 6.16702 5.78129 6.03864ZM4.89305 8.7677C4.80177 8.6764 4.80177 8.52852 4.89305 8.43724C5.73524 7.59505 7.09837 7.59519 7.94047 8.43724C8.03165 8.52854 8.03165 8.67642 7.94047 8.7677C7.84914 8.85903 7.70126 8.85889 7.60994 8.7677C6.95035 8.10811 5.88291 8.10825 5.22356 8.7677C5.13223 8.85889 4.98433 8.85903 4.89305 8.7677Z'
        fill='#323335'
      />
      <line x1='1.83337' y1='10.7525' x2='11.5859' y2='1' stroke='#323335' strokeWidth='0.35' strokeLinecap='round' />
    </svg>
  );
};
