import React, { FC } from 'react';
import styles from './loader-ring.module.scss';

type Props = {
  size: number;
  auxStyles?: string;
};

const LoaderRing: FC<Props> = ({ size, auxStyles }) => {
  return (
    <div className={`${styles.loaderRing} ${auxStyles ?? ''}`}>
      <div style={{ width: size, height: size }} />
      <div style={{ width: size, height: size }} />
      <div style={{ width: size, height: size }} />
      <div style={{ width: size, height: size }} />
    </div>
  );
};

export default LoaderRing;
