import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Loading } from './common/loading';
import { getPatientByShortCode } from '../services/api/patient';

type Props = {};

const VerifyPatientCode: FunctionComponent<Props> = props => {
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    (async (): Promise<void> => {
      const result = await getPatientFromShortCode();
      if (result.status === 'Done') {
        history.push(`/patients/${result.data.patientId}`);
      } else if (result.status === 'Failed') {
        addToast(`${result.errorApi.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
        history.push('/');
      }
    })();
  }, [code]);

  const getPatientFromShortCode = async () => {
    return getPatientByShortCode(code);
  };
  return (
    <div>
      <Loading style={{ position: 'absolute', top: '50%' }} />
    </div>
  );
};

export default VerifyPatientCode;
