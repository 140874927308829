import React, { useMemo, Dispatch, SetStateAction } from 'react';
import { Column } from 'react-table';
import moment from 'moment-timezone';
import { RemoteData } from '../../utils/remote-data';
import { Insights, Insight } from '../../interfaces/insight';
import styles from './insights.module.scss';
import CheckMarkGray from '../../assets/icons/check-mark-gray.svg';
import WarningMark from '../../assets/icons/warning-mark.svg';
import Table from '../common/table/table';

type Props = {
  insights: RemoteData<Insights>;
  filterResultInsights: Insights | undefined;
  setSelectedInsight: Dispatch<SetStateAction<Insight | undefined>>;
  getPatientStatusElement: (score: number) => JSX.Element;
};

const InsightsTable: React.FC<Props> = ({
  insights,
  setSelectedInsight,
  getPatientStatusElement,
  filterResultInsights,
}) => {
  console.log(insights, 'site')
  const columns: Column<Insight>[] = useMemo(
    () => [
      {
        Header: 'INSIGHT',
        accessor: 'patientTitle',
        width: 270,
      },
      {
        Header: 'TIMESTAMP',
        accessor: 'endTimestamp',
        width: 250,
        Cell: ({ cell: { value } }) => moment.utc(value).local().format('lll Z'),
        sortType: 'basic',
      },
      {
        Header: 'STATUS',
        accessor: originalRow => getPatientStatusElement(originalRow.relapseRisk!),
        width: 150,
        sortType: (rowA, rowB, columnID, desc) => {
          const scoreA = rowA.original.viviHealthScore || 0;
          const scoreB = rowB.original.viviHealthScore || 0;
          return scoreA - scoreB;
        },
      },
      {
        Header: 'CLEARED ON',
        accessor: 'hasAlert',
        Cell: ({ cell }) => {
          if (!cell.row.original.hasAlert) {
            return <span className={styles.clearedOn}>N/A</span>;
          }
          if (cell.row.original.hasAlert && !cell.row.original.clearedAt) {
            return <span className={styles.clearedOn}>--</span>;
          }
          return (
            <span className={styles.clearedOn}>
              <img src={CheckMarkGray} /> Cleared - {moment.utc(cell.row.original.clearedAt).local().format('lll')}
            </span>
          );
        },
        width: 280,
        sortType: (rowA, rowB, columnID, desc) => {
          const statusA = !rowA.original.hasAlert ? 2 : rowA.original.hasAlert && !rowA.original.clearedAt ? 1 : 3;
          const statusB = !rowB.original.hasAlert ? 2 : rowB.original.hasAlert && !rowB.original.clearedAt ? 1 : 2;
          return statusA > statusB ? 1 : -1;
        },
      },
      {
        Header: '',
        accessor: 'id',
        width: 100,
        Cell: ({ cell }): JSX.Element =>
          cell.row.original.hasAlert && !cell.row.original.clearedAt ? (
            <span className={styles.action} onClick={() => setSelectedInsight(cell.row.original)}>
              <img src={WarningMark} />
              Clear alert
            </span>
          ) : (
            <span className={styles.action} onClick={() => setSelectedInsight(cell.row.original)}>
              View
            </span>
          ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'endTimestamp', desc: true }] }),
    [],
  );

  const getRowProps = (row, idx) => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
      style: {
        backgroundColor: row.original.hasAlert && !row.original.clearedAt ? '#ECF2F8' : '#FFFFFF',
      },
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = (cell, idx) => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: insights.status === 'Done' && !insights.data.values ? styles.noContent : styles.table,
      style: {
        width: 'calc(100% - 36px)',
        marginLeft: '18px',
      },
    };
  };

  return (
    <Table<Insight>
      columns={columns}
      data={filterResultInsights ? filterResultInsights.values : insights.status === 'Done' ? insights.data.values : []}
      isLoading={insights.status === 'Loading'}
      error={insights.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={(row, idx) => getRowProps(row, idx)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={(cell, idx) => getCellProps(cell, idx)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 180, minWidth: 100 }}
      initialState={defaultSorting}
    />
  );
};

export default InsightsTable;
