import React, { FunctionComponent, useMemo } from 'react';
import moment from 'moment-timezone';
import { GeofenceLocation, GeofencePeriods } from '../../../interfaces/geofence';
import { CellProps, Column } from 'react-table';
import Table from '../../common/table/table';
import { RemoteData } from '../../../utils/remote-data';
import styles from './geofence.module.scss';
import { GeofenceType } from '../../../interfaces/enums';
import SpecialGeofence from '../../../assets/icons/special-geofence';
import NonCompliantGeofence from '../../../assets/icons/non-compliant-geofence';
import { getPatientTimezone } from '../../../contexts/PatientContext';
import { displayTableDateTimeFormat } from '../../../utils/data-parser';

type Props = {
  geofenceLocations: RemoteData<GeofencePeriods>;
  onTableActionClick: (geofenceLocation: GeofenceLocation) => void;
};

const GeofenceLocationsTable: FunctionComponent<Props> = ({ geofenceLocations, onTableActionClick }) => {
  const timezone = getPatientTimezone();

  const tableColumns: Column<GeofenceLocation>[] = useMemo(
    () => [
      {
        accessor: 'timestamp',
        Header: 'Date',
        width: 200,
        Cell: ({ cell: { value } }: CellProps<GeofenceLocation, string>) => displayTableDateTimeFormat(value, timezone),
        sortType: 'basic',
      },
      {
        accessor: 'name',
        Header: 'Geofence Name',
        width: 239,
        sortType: 'basic',
      },
      {
        accessor: 'type',
        Header: 'Geofence Type',
        width: 285,
        Cell: ({ cell: { value, row } }) =>
          value === GeofenceType.Special ? <SpecialGeofence /> : <NonCompliantGeofence />,
        sortType: 'basic',
      },
      {
        accessor: 'geofencePoint',
        Header: 'Address',
        width: 171,
        Cell: ({ cell: { value, row } }) => value && <span className={styles.ellipsis}>{value?.address}</span>,
        sortType: (rowA, rowB, columnID, desc) => {
          const addressA = rowA.original.geofencePoint?.address || '';
          const addressB = rowB.original.geofencePoint?.address || '';
          return !addressA ? -1 : !addressB ? 1 : addressA.localeCompare(addressB);
        },
      },
      {
        accessor: (originalRow): string | number => {
          const hours = Math.floor(originalRow.timeSpentMinutes / 60);
          const minutes = originalRow.timeSpentMinutes % 60;
          if (hours === 0) {
            return `${originalRow.timeSpentMinutes % 60} minutes`;
          } else {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes > 0 ? `${minutes} min` : ''}`;
          }
        },
        Header: 'Time Spent',
        width: 150,
        sortType: (rowA, rowB, columnID, desc) => {
          return rowA.original.timeSpentMinutes - rowB.original.timeSpentMinutes;
        },
      },
      {
        Header: '',
        accessor: 'id',
        minWidth: 60,
        width: 60,
        Cell: ({ cell: { value, row } }): JSX.Element => (
          <span className={styles.action} onClick={() => onTableActionClick(row.original)}>
            View
          </span>
        ),
        disableSortBy: true,
      },
    ],
    [timezone, onTableActionClick],
  );
  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: geofenceLocations.status === 'Done' && !geofenceLocations.data.size ? styles.noContent : styles.table,
    };
  };

  return (
    <Table<GeofenceLocation>
      columns={tableColumns}
      data={geofenceLocations.status === 'Done' ? geofenceLocations.data.values : []}
      isLoading={geofenceLocations.status === 'Loading'}
      error={geofenceLocations.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={row => getRowProps(row)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={cell => getCellProps(cell)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 150, minWidth: 50 }}
      initialState={defaultSorting}
      emptyDataMessage='There is no data at this time.'
    />
  );
};

export default GeofenceLocationsTable;
