import React, { useState, useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import moment from 'moment';
import { PhoneInput, DaySelectorInput, Checkbox } from '../common/form';
import styles from './sms-notifications-form.module.scss';
import { Tooltip } from '../common/tooltip';
import { SMSConfig } from '../../interfaces/sms-config';
import { RemoteData } from '../../utils/remote-data';

type Props = {
  formValues: any;
  formTouched: FormikTouched<any>;
  formErrors: FormikErrors<any>;
  config: RemoteData<SMSConfig>;
  getSMSConfigurationAction: () => Promise<void>;
  isAuthenticatedPractitionerProfile: boolean;
};

const SMSNotifications: React.FC<Props> = ({
  formValues,
  formTouched,
  formErrors,
  config,
  getSMSConfigurationAction,
  isAuthenticatedPractitionerProfile,
}) => {
  const { setFieldValue } = useFormikContext();
  const [start, setStart] = useState<string>('');
  const [startHourType, setStartHourType] = useState<string | undefined>(undefined);
  const [end, setEnd] = useState<string>('');
  const [endHourType, setEndHourType] = useState<string | undefined>(undefined);

  const getHourMask = field => [/[0-1]/, parseInt(field?.charAt(0), 8) < 1 ? /[0-9]/ : /[0-2]/, ':', /[0-5]/, /[0-9]/];

  useEffect(() => {
    if (!start) {
      if (formValues.startWindow) {
        setStart(moment(formValues.startWindow, 'HH:mm').format('hh:mm'));
        setStartHourType(moment(formValues.startWindow, 'HH:mm').format('A'));
      }
    }
    if (!end) {
      if (formValues.endWindow) {
        setEnd(moment(formValues.endWindow, 'HH:mm').format('hh:mm'));
        setEndHourType(moment(formValues.endWindow, 'HH:mm').format('A'));
      }
    }
  }, [formValues.startWindow, formValues.endWindow]);
  useEffect(() => {
    if (config.status === 'Not Asked') {
      getSMSConfigurationAction();
    }
    // This is a forced fix since weekDays field value was getting lost between modal windows changes.
    if (formValues.weekDays === '' && config.status === 'Done') {
      setFieldValue('weekDays', config.data.weekDays);
    }
  }, [config.status]);

  useEffect(() => {
    if (formValues.notificationOptions) {
      if (
        !formValues.notificationOptions.INSIGHT_ONLY_ALERT &&
        (formValues.notificationOptions.INSIGHT_ALL_NEGATIVE || formValues.notificationOptions.INSIGHT_ALL_POSITIVE)
      ) {
        setFieldValue('notificationOptions.INSIGHT_ONLY_ALERT', true);
      }
    }
  }, [formValues.notificationOptions]);

  useEffect(() => {
    setFieldValue('startWindow', moment(`${start} ${startHourType}`, 'hh:mm A').format('HH:mm'));
    setFieldValue('endWindow', moment(`${end} ${endHourType}`, 'hh:mm A').format('HH:mm'));
  }, [start, end, startHourType, endHourType]);

  return (
    <div className={styles.main}>
      <div className={styles.row}>
        <PhoneInput label='Cell phone Number on Profile:' name='sms' />
        <div className={styles.notificationsConfirm}>
          <span className={styles.checkboxTitle}>
            Opt-in to SMS notifications:
            <span className={styles.tooltipIcon}>
              <Tooltip baseStyles={styles.tooltip} type='left' iconWidth='10' iconHeight='10'>
                <p>
                  By checking this box, you are agreeing that you accept any occurring consequences for the receipt of
                  SMS messages from ViviHealth regarding information delivered to your ViviClinic account. For further
                  details, please see the Privacy Policy and Software License, available on the HELP ("?") button in the
                  upper right of the ViviClinic window.
                </p>
              </Tooltip>
            </span>
          </span>
          <Checkbox
            name='active'
            field='I agree to receive SMS messages on my mobile device'
            errorHandledInForm={false}
            hasError={!formValues.active && !!formTouched.active}
            customErrorMessage='Please verify that you agree to receive SMS messages to save your notification preferences.'
          />
        </div>
      </div>
      <p className={styles.notificationsTitle}>Select notification types to receive:</p>
      <ul>
        <li>
          <Checkbox
            name='notificationOptions.INSIGHT_ONLY_BAC'
            field='BAC Alert-Insights only (you will be notified only if a client fails a BAC test )'
          />
        </li>
        <li>
          <Checkbox
            name='notificationOptions.INSIGHT_ONLY_ALERT'
            field='Alert-Insights (you will be notified of client activity that requires urgent attention)'
          />
        </li>
        <li>
          <Checkbox
            name='notificationOptions.INSIGHT_ALL_NEGATIVE'
            field='All Negative Insights (you will also be notified of any other irregularities in client activity)'
          />
        </li>
        <li>
          <Checkbox
            name='notificationOptions.INSIGHT_ALL_POSITIVE'
            field='All Positive Insights (you will also be notified of any client adherence to their program)'
          />
        </li>
      </ul>
      <div className={styles.timeSettings}>
        <p>Allowable time frames to receive SMS:</p>
        <p>Select a time frame that you would like to receive notifications:</p>
        <MaskedInput
          className={styles.hourInput}
          mask={getHourMask(start)}
          id='hour1'
          type='text'
          value={start}
          onChange={e => setStart(e.target.value)}
        />
        <select
          className={styles.time}
          name='set'
          onChange={e => setStartHourType(e.target.value)}
          defaultValue='AM'
          value={startHourType}
        >
          <option value='AM'>AM</option>
          <option value='PM'>PM</option>
        </select>
        <span>to</span>
        <MaskedInput
          className={formErrors.endWindow ? styles.hourInput__warning : styles.hourInput}
          mask={getHourMask(end)}
          id='hour2'
          type='text'
          value={end}
          onChange={e => setEnd(e.target.value)}
        />
        <select
          className={styles.time}
          name='set'
          onChange={e => setEndHourType(e.target.value)}
          value={endHourType}
          defaultValue='PM'
        >
          <option value='AM'>AM</option>
          <option value='PM'>PM</option>
        </select>
        <DaySelectorInput name='weekDays' label='Repeat On:' />
        {formErrors.endWindow && <span className={styles.datepickerError}>{formErrors.endWindow}</span>}
      </div>
      <div
        className={
          !isAuthenticatedPractitionerProfile
            ? styles.formBlock
            : !formValues.active
            ? `${styles.formBlock} ${styles.formInputs}`
            : ''
        }
      />
    </div>
  );
};
export default SMSNotifications;