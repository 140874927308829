import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { endOfWeek, startOfWeek } from 'date-fns';
import moment from 'moment-timezone';
import { Marker, MarkerClusterer, MarkerProps } from '@react-google-maps/api';
import { displayTableDateTimeFormat, parseTimeSpent } from '../../../utils/data-parser';
import { done, loading, RemoteData } from '../../../utils/remote-data';
import { GeofencePeriods, GeoLocationArray } from '../../../interfaces/geofence';
import { BacResult } from '../../../interfaces/bac-result';
import { Meetings } from '../../../interfaces/meeting';
import styles from './locations.module.scss';
import { DropDown } from '../../dropdown';
import DatePicker, { DateRange } from '../../common/date-picker/date-range-picker';
import { PractitionerContext } from '../../../contexts/Practitioner';
import { getGeofenceLocations, getPatientGeolocations } from '../../../services/api/geofence';
import { getBacResultsByPatient } from '../../../services/api/bac';
import { Loading } from '../../common/loading';
import Map from '../../common/map/map';
import { getMeetings } from '../../../services/api/meeting';
import bacPassedIcon from '../../../assets/icons/icn-bac-passed.svg';
import bacFailedIcon from '../../../assets/icons/icn-bac-failed.svg';
import meetingAttendedIcon from '../../../assets/icons/icn_map_pin_attended.svg';
import meetingMissedIcon from '../../../assets/icons/icn_map_pin_missed.svg';
import specialIcon from '../../../assets/icons/icn-special.svg';
import nonCompliantIcon from '../../../assets/icons/icn_map_pin_non_compliant.svg';
import locationIconNow from '../../../assets/icons/map-pin-location-now.svg';
import locationIconPast from '../../../assets/icons/map-pin-location-past-days.svg';
import locationIconYesterday from '../../../assets/icons/map-pin-location-yesterday.svg';
import { Slider } from '@mui/material';
import { useDidMountEffect } from '../../../utils/hooks';
import CustomMarker from '../../common/map/custom-marker';
import { firebaseSendCurrentScreen } from '../../../utils/analytics';
import { Tooltip as InfoTooltip } from '../../common/tooltip';
import LocationInfoBox from './components/location-info-box';
import { caseOf } from '../../../utils/case-of';
import { getPatientTimezone } from '../../../contexts/PatientContext';

const renderCustomMarker = (markerProps: MarkerProps, timestamp: string, key: string): JSX.Element => {
  return (
    <CustomMarker key={key} markerProps={markerProps} useOnHover={true}>
      <div className={styles.smallBox}>
        <p className={styles.title}>{timestamp}</p>
      </div>
    </CustomMarker>
  );
};

const Locations: FunctionComponent = () => {
  const { practitioner } = useContext(PractitionerContext);
  const patientTimezone = getPatientTimezone();
  const orgTimezone = moment.tz.guess();
  const { addToast } = useToasts();
  const { id } = useParams<{ id: string }>();
  const [geofenceLocations, setGeofenceLocations] = useState<RemoteData<GeofencePeriods>>(loading());
  const [bacTests, setBacTests] = useState<RemoteData<{ values: BacResult[] }>>(loading());
  const [meetings, setMeetings] = useState<RemoteData<Meetings>>(loading());
  const [geolocations, setGeolocations] = useState<RemoteData<GeoLocationArray>>(loading());
  const [markers, setMarkers] = useState<{ lat: number; lng: number; timestamp?: string }[]>([]);
  const [displayMarkers, setDisplayMarkers] = useState<{ lat: number; lng: number; timestamp?: string }[]>([]);
  const [firstDate, setFirstDate] = useState<number>(0);
  const [lastDate, setLastDate] = useState<number>(0);
  const [changeValueForHour, setChangeValueForHour] = useState<number[]>([0, 23]);
  const [filters, setFilters] = useState({
    test: true,
    meetings: false,
    geofence: true,
    pathLocation: true,
  });
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });
  const [changeValueForDay, setChangeValueForDay] = useState<number[]>([
    dateRangeFilter?.startDate?.getTime()!,
    dateRangeFilter?.endDate?.getTime()!,
  ]);
  const mapOptions: google.maps.MapOptions = useMemo(
    () => ({
      clickableIcons: false,
      zoom: 8,
      center: { lat: 37.2741851, lng: -104.6752892 },
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }),
    [],
  );

  const FILTER_ICONS = useMemo(
    () => [
      {
        identifier: 'geofence',
        name: 'Geofence',
        onChangeEvent: (event: ChangeEvent<HTMLInputElement>) =>
          setFilters({ ...filters, geofence: event.target.checked }),
      },
      {
        identifier: 'test',
        name: 'BAC/Drug test',
        onChangeEvent: (event: ChangeEvent<HTMLInputElement>) =>
          setFilters({
            ...filters,
            test: event.target.checked,
          }),
      },
      {
        identifier: 'meetings',
        name: 'Meetings',
        onChangeEvent: (event: ChangeEvent<HTMLInputElement>) =>
          setFilters({ ...filters, meetings: event.target.checked }),
      },
      {
        identifier: 'pathLocation',
        name: 'Show Location Path',
        onChangeEvent: (event: ChangeEvent<HTMLInputElement>) => handleOnLocationChange(event),
      },
    ],
    [filters],
  );

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('LocationsPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
    }
  }, [practitioner]);

  const nonCompliantPin: any = {
    url: nonCompliantIcon,
    scaledSize: { height: 40, width: 40 },
  };

  const getPathIconBasedOnLocationDate = (date: string | Date) => {
    return caseOf()
      .case(0, locationIconNow)
      .case(1, locationIconYesterday)
      .defaultCase(() => locationIconPast)
      .eval(moment().tz(patientTimezone).startOf('day').diff(moment(date).tz(patientTimezone).startOf('day'), 'days'));
  };

  const onDateChange = async (item: any, days = 6): Promise<void> => {
    // if path location on, date range limited to 7 days , so diff <= 6
    if (filters.pathLocation && moment(item.selection.endDate).diff(moment(item.selection.startDate), 'days') > 6) {
      addToast(
        'Maximum of 7 days of location data can be charted. Only first portion of selected date range is shown',
        {
          appearance: 'warning',
          autoDismiss: true,
        },
      );
      setDateRangeFilter({
        ...item.selection,
        endDate: moment(item.selection.startDate).add(6, 'day').utc().toDate(),
      });
      setFirstDate(item.selection.startDate?.getTime()!);
      setLastDate(item.selection.endDate?.getTime()!);
      setChangeValueForDay([
        item.selection.startDate.getTime(),
        moment(item.selection.startDate).add(6, 'day').utc().toDate().getTime(),
      ]);
      setChangeValueForHour([0, 23]);
    } else {
      setDateRangeFilter(item.selection);
      setFirstDate(item.selection.startDate?.getTime()!);
      setLastDate(item.selection.endDate?.getTime()!);
      setChangeValueForDay([item.selection.startDate.getTime(), item.selection.endDate.getTime()]);
      setChangeValueForHour([0, 23]);
    }
  };

  const handleOnLocationChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    setFilters({ ...filters, pathLocation: e.target.checked });
    if (e.target.checked && moment(dateRangeFilter.endDate).diff(moment(dateRangeFilter.startDate), 'days') > 6) {
      addToast(
        'Maximum of 7 days of location data can be charted. Only first portion of selected date range is shown',
        {
          appearance: 'warning',
          autoDismiss: true,
        },
      );
    }
  }, []);

  const getMarkers = () => {
    if (
      geofenceLocations.status === 'Done' &&
      bacTests.status === 'Done' &&
      meetings.status === 'Done' &&
      geolocations.status === 'Done'
    ) {
      const locationMarkers: google.maps.LatLngLiteral[] = [
        ...geofenceLocations.data.values.map(geo => ({
          lat: geo.geofencePoint.latitude,
          lng: geo.geofencePoint.longitude,
          timestamp: geo.timestamp,
        })),
        ...bacTests.data.values.flatMap(test =>
          !!test.location.longitude && !!test.location.latitude
            ? [{ lat: test.location.latitude, lng: test.location.longitude, timestamp: test.timestamp }]
            : [],
        ),
        ...meetings.data.values.map(meeting => ({
          lat: meeting.location.latitude,
          lng: meeting.location.longitude,
          timestamp: meeting.timestamp,
        })),
        ...geolocations.data.values.map(value => ({
          lat: value.locationPoint.latitude,
          lng: value.locationPoint.longitude,
          timestamp: value.timestamp,
        })),
      ];
      setMarkers(locationMarkers);
      setDisplayMarkers(locationMarkers);
    }
    setFirstDate(dateRangeFilter.startDate?.getTime()!);
    setLastDate(dateRangeFilter.endDate?.getTime()!);
  };

  const requestPatientGeolocations = async () => {
    const endDate: string =
      moment(dateRangeFilter.endDate).diff(moment(dateRangeFilter.startDate), 'days') > 7
        ? moment(dateRangeFilter.startDate).add(6, 'day').utc().format('YYYY-MM-DD')
        : moment(dateRangeFilter.endDate).format('YYYY-MM-DD');
    const res = await getPatientGeolocations(id!, {
      startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
      endDate,
      timezone: orgTimezone,
    });
    setGeolocations(res);
  };

  const requestGeofenceLocations = async () => {
    const res = await getGeofenceLocations(id!, {
      startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
      endDate: moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      timezone: orgTimezone,
    });
    setGeofenceLocations(res);
  };

  const requestBacResults = async () => {
    const res = await getBacResultsByPatient(id!, {
      startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
      endDate: moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      timezone: orgTimezone,
    });
    setBacTests(res);
  };

  const requestMeetings = async () => {
    const res = await getMeetings(id!, {
      startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
      endDate: moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      timezone: orgTimezone,
    });
    setMeetings(res);
  };
  const fetching = () =>
    geofenceLocations.status === 'Loading' ||
    bacTests.status === 'Loading' ||
    meetings.status === 'Loading' ||
    geolocations.status === 'Loading';

  useEffect(() => {
    getMarkers();
  }, [geofenceLocations, bacTests, meetings, geolocations]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (filters.geofence) {
        await requestGeofenceLocations();
      } else {
        setGeofenceLocations(done({ values: [], size: 0 }));
      }
    })();
  }, [filters.geofence]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (filters.test) {
        await requestBacResults();
      } else {
        setBacTests(done({ values: [] }));
      }
    })();
  }, [filters.test]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (filters.meetings) {
        await requestMeetings();
      } else {
        setMeetings(done({ values: [], size: 0 }));
      }
    })();
  }, [filters.meetings]);

  useEffect(() => {
    (async (): Promise<void> => {
      setGeolocations(loading());
      if (filters.pathLocation) {
        await requestPatientGeolocations();
      } else {
        setGeolocations(done({ values: [], size: 0 }));
      }
    })();
  }, [filters.pathLocation]);

  useDidMountEffect(() => {
    (async (): Promise<void> => {
      setMarkers([]);
      if (filters.geofence) {
        setGeofenceLocations(loading());
        requestGeofenceLocations();
      }
      if (filters.test) {
        setBacTests(loading());
        requestBacResults();
      }
      if (filters.meetings) {
        setMeetings(loading());
        requestMeetings();
      }
      if (filters.pathLocation) {
        setGeolocations(loading());
        await requestPatientGeolocations();
      }
    })();
  }, [dateRangeFilter]);

  //start of my code

  // Range of markers to display by days

  const setTheMarkers = (val: number[], slide: string) => {
    let count = 0;
    if (slide === 'day') {
      setDisplayMarkers(
        markers.filter(item => {
          const weekDay = moment(item.timestamp).date();
          const first = moment(Math.min(...val)).date();
          const last = moment(Math.max(...val)).date();

          const answer = weekDay >= first && weekDay <= last;
          return answer;
        }),
      );
    } else {
      setDisplayMarkers(
        markers.filter(item => {
          const date = new Date(`${item.timestamp}Z`);
          const newDate = `${date.toDateString()} ${date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}`;
          let weekDay = date.getHours();

          return weekDay >= Math.min(...val) && weekDay <= Math.max(...val);
        }),
      );
    }
  };

  const getValues = (e, slide: string): void => {
    let val = e.target.value;
    if (slide === 'day') {
      setChangeValueForDay(val);
      setTheMarkers(val, slide);
    } else {
      setChangeValueForHour(val);
      setTheMarkers(val, slide);
    }
  };

  return (
    <div>
      <header className={styles.locationHeader}>
        <nav>
          <ul className={styles.navLeft}>
            {FILTER_ICONS.map((icon, index) => (
              <li key={index}>
                <label className={styles.labelCheck}>
                  <input
                    type='checkbox'
                    name={icon.identifier}
                    checked={filters[icon.identifier]}
                    onChange={icon.onChangeEvent}
                  />
                  <span />
                  {icon.name}
                </label>
              </li>
            ))}
          </ul>
          {/* <ul
            className='sliders'
            style={{
              display: 'flex',
              flexDirection: 'row',
              zIndex: 1,
              justifyContent: 'space-between',
              width: '500px',
            }}
          >
            <li className={styles.hoursList}>
              <div className={styles.houesContainer}>
                <span style={{ marginRight: '10px' }}>Days</span>
                <Slider
                  size='small'
                  onChange={e => getValues(e, 'day')}
                  value={changeValueForDay}
                  min={firstDate}
                  max={lastDate}
                  disableSwap
                  sx={{
                    color: 'light-blue',
                    '& .MuiSlider-thumb': {
                      borderRadius: '5px',
                    },
                  }}
                />{' '}
              </div>
              <div className={styles.timeBox}>
                {changeValueForDay && (
                  <div className={styles.theBorder}>
                    <span className={styles.theHours}>
                      {new Date(changeValueForDay[0]).getDate()} - {new Date(changeValueForDay[1]).getDate()}
                    </span>
                  </div>
                )}
              </div>
            </li>
            <li className={styles.hoursList}>
              <div className={styles.houesContainer}>
                <span style={{ marginRight: '10px' }}>Hours</span>
                <Slider
                  size='small'
                  onChange={e => getValues(e, 'hour')}
                  value={changeValueForHour}
                  min={0}
                  max={23}
                  disableSwap
                  sx={{
                    color: 'light-blue',
                    '& .MuiSlider-thumb': {
                      borderRadius: '5px',
                    },
                  }}
                />
              </div>
              <div className={styles.timeBox}>
                {changeValueForHour[0] >= 12 && changeValueForHour[1] > 12 && (
                  <div className={styles.theBorder}>
                    <div className={styles.theHours}>
                      {changeValueForHour[0] === 12 ? '12' : changeValueForHour[0] - 12}:59 p.m -{' '}
                      {changeValueForHour[1] - 12}:00 p.m
                    </div>
                  </div>
                )}
                {changeValueForHour[0] < 12 && changeValueForHour[1] < 12 && (
                  <div className={styles.theBorder}>
                    <div className={styles.theHours}>
                      {changeValueForHour[0] === 0 ? '12' : changeValueForHour[0]}: a.m - {changeValueForHour[1]}:59 a.m
                    </div>
                  </div>
                )}
                {changeValueForHour[0] < 12 && changeValueForHour[1] >= 12 && (
                  <div className={styles.theBorder}>
                    <div className={styles.theHours}>
                      {changeValueForHour[0] === 0 ? '12' : changeValueForHour[0]}:00 a.m -{' '}
                      {changeValueForHour[1] - 12 === 0 ? '12' : changeValueForHour[1] - 12}:59 p.m
                    </div>
                  </div>
                )}
              </div>
            </li>
          </ul> */}
          <ul className={styles.navRight}>
            <li>
              <InfoTooltip baseStyles={styles.hoverTooltip} type='right' background='#F5F6FA'>
                <LocationInfoBox />
              </InfoTooltip>
            </li>
            <li className={styles.options}>
              <DropDown contentStyle='filter'>
                <span className={styles.dropdownButton}>
                  {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
                  {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
                  <i className={styles.arrow}>
                    <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                    </svg>
                  </i>
                </span>
                <>
                  <DatePicker
                    onDateSelectionChange={onDateChange}
                    dateRange={dateRangeFilter}
                    parentStyle={styles.filterDatepicker}
                    locationView={true}
                  />
                </>
              </DropDown>
            </li>
          </ul>
        </nav>
      </header>
      <section className={styles.mapContainer}>
        {fetching() && !markers.length && (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        )}
        {geofenceLocations.status === 'Done' &&
          bacTests.status === 'Done' &&
          meetings.status === 'Done' &&
          geolocations.status === 'Done' &&
          !!displayMarkers.length && (
            <Map
              mapContainerStyle={{ width: '100%', height: '100%' }}
              //markers
              markers={displayMarkers}
              fitBounds={!!displayMarkers.length}
              mapOptions={mapOptions}
            >
              {bacTests.data.values.flatMap(test =>
                !!test.location.longitude && !!test.location.latitude
                  ? renderCustomMarker(
                      {
                        position: {
                          lat: test.location.latitude,
                          lng: test.location.longitude,
                        },
                        icon: { url: test.status.toLowerCase().includes('pass') ? bacPassedIcon : bacFailedIcon },
                      },
                      displayTableDateTimeFormat(test.timestamp as string, patientTimezone),
                      `${test.id}-${test.location.latitude}-${test.location.longitude}`,
                    )
                  : null,
              )}
              {geofenceLocations.data.values.map(geofence => {
                return geofence.type === 'NON_COMPLIANT' ? (
                  <CustomMarker
                    key={geofence.id}
                    markerProps={{
                      icon: nonCompliantPin,
                      position: {
                        lat: geofence.geofencePoint.latitude,
                        lng: geofence.geofencePoint.longitude,
                      },
                    }}
                  >
                    <div className={styles.box}>
                      <h3 className={styles.title}>Non - Compliant {geofence.name}</h3>
                      <p>
                        <span>Date: </span>{' '}
                        {moment.utc(geofence.timestamp).tz(patientTimezone).format('MM/DD/YYYY hh:mm a')}
                      </p>
                      <p>
                        <span>Geofence: </span> {geofence.geofencePoint.address}
                      </p>
                      <p>
                        <span>Time spent: </span> {parseTimeSpent(geofence.timeSpentMinutes)}
                      </p>
                      <p>
                        <span>Visited: </span> {geofence.monthVisitCount}
                      </p>
                    </div>
                  </CustomMarker>
                ) : (
                  <Marker
                    key={geofence.id}
                    position={{
                      lat: geofence.geofencePoint.latitude,
                      lng: geofence.geofencePoint.longitude,
                    }}
                    icon={{ url: specialIcon }}
                    clickable={true}
                    animation={2}
                  />
                );
              })}
              {meetings.data.values.map(meeting =>
                renderCustomMarker(
                  {
                    position: {
                      lat: meeting.location.latitude,
                      lng: meeting.location.longitude,
                    },
                    icon: {
                      url:
                        meeting.attended.toLowerCase().includes('attended') ||
                        meeting.attended.toLowerCase().includes('upcoming')
                          ? meetingAttendedIcon
                          : meetingMissedIcon,
                    },
                  },
                  displayTableDateTimeFormat(meeting.timestamp as string, patientTimezone),
                  `${meeting.id}-${meeting.location.latitude}`,
                ),
              )}
              <MarkerClusterer minimumClusterSize={50}>
                {clusterer =>
                  displayMarkers.map((geolocation, i) => {
                    const date = new Date(`${geolocation.timestamp}`);
                    const theDate = date.getTimezoneOffset();
                    const newDate = new Date(date.getTime() - theDate * 60000);
                    const displayDate = ` ${newDate.toDateString()} ${newDate.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}`;
                    return renderCustomMarker(
                      {
                        animation: 1,
                        position: {
                          lat: geolocation.lat,
                          lng: geolocation.lng,
                        },
                        icon: { url: getPathIconBasedOnLocationDate(geolocation.timestamp || '') },
                        clusterer,
                      },
                      displayDate as any,
                      `${i}`,
                    );
                  })
                }
              </MarkerClusterer>
            </Map>
          )}
        {(!Object.values(filters).some(val => val) || !markers.length) && (
          <Map
            mapContainerStyle={{ width: '100%', height: '100%' }}
            markers={[{ lat: 37.2741851, lng: -104.6752892 }]}
            fitBounds={false}
            mapOptions={{ ...mapOptions, zoom: 5 }}
          >
            {' '}
          </Map>
        )}
      </section>
    </div>
  );
};

export default Locations;
