import React from 'react';
import ScoreRange from '../score-range';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>Activity levels</h3>
      <p>
        The <strong>Activity Levels</strong> chart shows the amount and intensity of activity and exercise over time for
        your client. It provides a near real-time view of such activity and is reported out every 15 minutes.
      </p>
      <p>
        The Activity Levels are shown between 0 (low or no activity) and 100 (significant activity). The system
        estimates when your client may be walking, running fast, or even sitting still.
      </p>
      <p>
        <strong>Activity Ranges:</strong>
      </p>
      <h6>
        <ScoreRange
          elemStyles={{ background: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)', width: '185px' }}
          range='0-9'
        >
          <span>
            <strong>Low</strong> Activity Level
          </span>
        </ScoreRange>
      </h6>
      <h6>
        <ScoreRange
          elemStyles={{ background: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)', width: '185px' }}
          range='10-79'
        >
          <span>
            <strong>Medium</strong> Activity Level
          </span>
        </ScoreRange>
      </h6>
      <h6>
        <ScoreRange
          elemStyles={{ background: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 70%)', width: '185px' }}
          range='80-100'
        >
          <span>
            <strong>Higher</strong> Activity Level
          </span>
        </ScoreRange>
      </h6>
    </div>
  );
};

export default InfoBox;
