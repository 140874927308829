import React from 'react';
import { SelectInput, TextInput } from '../../common/form';
import SelectArrayInput from '../../common/form/select-array-input';
import { RemoteData } from '../../../utils/remote-data';
import { Practitioners } from '../../../interfaces/practitioner';
import { Loading } from '../../common/loading';
import { Organization } from '../../../interfaces/common';
import styles from '../upsert-client.module.scss';
import StaticTextInput from '../../common/form/static-text-input';
import { EPISODE_OF_CARE } from '../../../utils/constants';

type Props = {
  formValues: any;
  practitioners: RemoteData<Practitioners>;
  organization: Organization | undefined;
  editMode: boolean;
};

const ClinicInfo: React.FC<Props> = ({ formValues, practitioners, editMode }) => {
  return practitioners.status === 'Done' ? (
    <div className={styles.body}>
      <div className={styles.clinicInfoSection}>
        <SelectArrayInput
          name='careTeamIds'
          label='Practitioner to be Assigned:'
          itemsHeader='Practitioners Assigned:'
          items={practitioners.data.values}
          selectedItems={formValues.careTeamIds}
          size='big'
          edit={editMode}
        />
      </div>
      <div className={styles.clinicInfoSection}>
        <TextInput name='emrIdentifier' label='Patient ID:' size='big' />
        {editMode ? (
          <StaticTextInput
            label='Episode of care:'
            disabled
            value={EPISODE_OF_CARE[formValues.episodeOfCare]}
            size='big'
          />
        ) : (
          <SelectInput
            name='episodeOfCare'
            label='Episode of care:'
            size='big'
            defaultValue={formValues?.episodeOfCare || undefined}
          >
            {Object.entries(EPISODE_OF_CARE).flatMap(([key, value]) =>
              EPISODE_OF_CARE.SELF_CARE !== value ? (
                <option key={key} value={key}>
                  {value}
                </option>
              ) : null,
            )}
          </SelectInput>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.loadingState}>
      <Loading />
    </div>
  );
};

export default ClinicInfo;
