import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { ApolloQueryResult, NetworkStatus, useApolloClient } from '@apollo/client';
import { getOrganizationById } from '../services/api/organization';
import { PractitionerContext } from './Practitioner';
import { OrganizationQuery } from '../graphql/codegen/operations';
import { GET_ORGANIZATION } from '../graphql/queries';

export const OrganizationContext = createContext<{
  organization: ApolloQueryResult<OrganizationQuery> | undefined;
  getOrganizationAction: (organizationId: string) => Promise<void>;
  loading: boolean;
}>({
  organization: undefined,
  getOrganizationAction: async organizationId => {
    await getOrganizationById(organizationId);
  },
  loading: false,
});

export const OrganizationContextProvider: FunctionComponent = ({ children }) => {
  const [organization, setOrganization] = useState<ApolloQueryResult<OrganizationQuery> | undefined>({
    loading: true,
    data: {},
    networkStatus: NetworkStatus.loading,
  });
  const { practitioner } = useContext(PractitionerContext);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (practitioner && organization?.loading) {
      getOrganizationAction(practitioner.organization.id);
    }
  }, []);

  const getOrganizationAction = async (organizationId: string): Promise<void> => {
    const res = await apolloClient.query<OrganizationQuery>({
      query: GET_ORGANIZATION,
      variables: {
        id: organizationId,
      },
      errorPolicy: 'ignore',
    });
    setOrganization(res);
  };

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        getOrganizationAction,
        loading: organization?.loading!,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
