import { LocationPermissionType, PhonePermissionType } from './patient';

export interface UserMobilePermissionsHistory {
  id: number;
  fbUserId: string;
  permissions: UserMobilePermissions;
  oldPermissions: UserMobilePermissions;
  modifiedOn: string;
}

export interface UserMobilePermissions {
  bluetoothApproval: PhonePermissionType;
  cameraApproval: PhonePermissionType;
  contactsApproval: PhonePermissionType;
  locationApproval: PhonePermissionType;
  motionApproval: PhonePermissionType;
  pushApproval: PhonePermissionType;
  locationPermission: LocationPermissionType;
}

export const PermissionLabel = {
  bluetoothApproval: 'Bluetooth',
  cameraApproval: 'Camera',
  contactsApproval: 'Contacts',
  locationApproval: 'Location',
  motionApproval: 'Motion & Fitness',
  pushApproval: 'Notifications',
  locationPermission: 'Location',
};
