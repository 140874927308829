import React, { FunctionComponent } from 'react';
import styles from '../chart.module.scss';
import { displayDateTimeFormat } from '../../../../utils/data-parser';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type ActivityTooltipProps = {
  reverse: boolean;
  date: string;
  value: {
    count: number
  };
};

const StepsTooltip: FunctionComponent<ActivityTooltipProps> = ({ reverse, date, value }) => {
  const timezone = getPatientTimezone();
  return (
    <div className={`${reverse ? styles.chartToolTipReverse : styles.chartToolTip}`}>
      <div className={styles.chartTooltipContent}>
        <span>{displayDateTimeFormat(date, timezone)}</span>
      </div>
      {typeof value.count == 'number' && (
        <div className={styles.chartToolTipTitle}>
          <span style={{marginRight: '5px'}}>Steps: </span> {value.count}
        </div>
      )}
      {/* {typeof value.activityScore == 'number' && (
        <div className={styles.chartToolTipTitle}>
          <span>ACTIVITY SCORE: </span> {value.activityScore}
        </div>
      )} */}
    </div>
  );
};

export default StepsTooltip;
