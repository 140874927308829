import * as React from 'react';
import { QuestionnaireInstance } from 'graphql/codegen/schemas';
import styles from './view-phq-survey-modal.module.scss';
import PHQ_SCORE from './phq_score.jpeg';

interface Props {
  viewSurvey: QuestionnaireInstance;
}
const questions = {
  'Little interest or pleasure in doing things': null,
  'Feeling down, depressed, or hopeless': null,
  'Trouble falling or staying asleep, or sleeping too much': null,
  'Feeling tired or having little energy': null,
  'Poor appetite or overeating': null,
  'Feeling bad about yourself or that you are a failure or have let yourself or your family down': null,
  'Trouble concentrating on things, such as reading the newspaper or watching television': null,
  'Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual\n        ':
    null,
  'Thoughts that you would be better off dead, or of hurting yourself': null,
};

const ViewPhqSurveyModal: React.FC<Props> = props => {
  for (const prop in questions) {
    if (Object.prototype.hasOwnProperty.call(questions, prop)) {
      const value = props?.viewSurvey?.questions?.find(question => question.subtext === prop);
      questions[prop] = value?.answer?.value;
    }
  }
  const topQuestions = props?.viewSurvey?.questions?.filter(
    ({ text }) => text === 'Over the past 2 weeks, how often have you been bothered by:',
  );
  const scores = {
    'not-at-all': 0,
    'several-days': 0,
    'more-than-half': 0,
    'nearly-every-day': 0,
  };
  topQuestions.forEach(question => {
    if (question?.answer?.id) {
      scores[question?.answer?.id] = scores[question?.answer?.id] + question?.answer?.value || 0;
    }
  });

  const bottomQuestion = props?.viewSurvey?.questions?.find(
    ({ subtext }) =>
      subtext?.includes('If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?'),
  );

  const topQuestionAnswers = topQuestions?.[0].availableAnswers;

  return (
    <div className={styles.borderBox}>
      <div className={`${styles.titlerow}`}>
        <div className={styles.questionBox}>
          <span className={`${styles.text}`}>
            Over the last 2 weeks, how often have you been bothered by the following problems?
          </span>
        </div>
        {topQuestionAnswers.map(item => (
            <div className={`${styles.leftBorderTitle} ${styles.answer}`} key={item.id}>
              <span className={`${styles.text}`}>{item.text}</span>
            </div>
          ))}
      </div>
      {Object.keys(questions).map((question, index) => (
          <div className={`${styles.row}`} key={question}>
            <div className={styles.questionBox}>
              <span className={`${styles.text} ${styles.fifty}`}>
                {index + 1}. {question}
              </span>
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 0 && <span className={`${styles.text} ${styles.selectedAnswer}`}>0</span>}
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 1 && <span className={`${styles.text} ${styles.selectedAnswer}`}>1</span>}
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 2 && <span className={`${styles.text} ${styles.selectedAnswer}`}>2</span>}
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 3 && <span className={`${styles.text} ${styles.selectedAnswer}`}>3</span>}
            </div>
          </div>
        ))}
      <div className={`${styles.titlerow}`} style={{ borderBottomColor: 'white' }}>
        <div className={styles.emptyQuestionBox}> </div>
        <div className={`${styles.answer}`}>
          <span className={`${styles.text}`} style={{ fontSize: 20 }}>Add columns</span>
        </div>
        <div className={`${styles.noBorderAnswer} ${styles.answer}`} style={{
          fontSize: 20, backgroundColor: '#dadbdd',
          margin: 10
        }}>{scores['several-days']}</div>
        <div className={`${styles.noBorderAnswer} ${styles.answer}`} style={{
          fontSize: 20, backgroundColor: '#dadbdd',
          margin: 10
        }}>{scores['more-than-half']}</div>
        <div className={`${styles.noBorderAnswer} ${styles.answer}`} style={{
          fontSize: 20, backgroundColor: '#dadbdd',
          margin: 10
        }}>{scores['nearly-every-day']}</div>
      </div>
      <div className={`${styles.titlerow}`} style={{ borderBottomColor: 'white' }}>
        <div className={styles.emptyQuestionBox}> </div>
        <div className={`${styles.answer}`}>
          <span className={`${styles.text}`} style={{ fontSize: 20 }}>Total</span>
        </div>
        <div style={{ minHeight: 75, width: '45%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ fontSize: 20,           backgroundColor: '#dadbdd',
    margin: 10, width: '100%', minHeight: 75, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>{Object.values(scores).reduce((a, b) => a + b)}</div>
        </div>
      </div>
      <div
        style={{
          width: 'calc(100% - 40)',
          margin: 20,
          borderColor: 'black',
          borderWidth: 1,
          borderStyle: 'solid',
          padding: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ width: '40%', fontSize: 16 }}>
            10. If you checked off any problems, how difficult have these problems made it for you to do your work, take
            care of things at home, or get along with other people?
          </span>
          <div style={{ width: 300, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', margin: 10, justifyContent: 'space-between' }}>
              <span style={{ fontSize: 16 }}>Not difficult at all</span>
              <div
                style={{
                  marginLeft: 40,
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  borderBottomStyle: 'solid',
                  width: 100,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {bottomQuestion?.answer?.value === 0 ? (
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                    <path d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' />
                  </svg>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', margin: 10, justifyContent: 'space-between' }}>
              <span style={{ fontSize: 16 }}>Somewhat difficult</span>
              <div
                style={{
                  marginLeft: 40,
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  borderBottomStyle: 'solid',
                  width: 100,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {bottomQuestion?.answer?.value === 1 ? (
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                    <path d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' />
                  </svg>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', margin: 10, justifyContent: 'space-between' }}>
              <span style={{ fontSize: 16 }}>Very difficult</span>
              <div
                style={{
                  marginLeft: 40,
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  borderBottomStyle: 'solid',
                  width: 100,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {bottomQuestion?.answer?.value === 2 ? (
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                    <path d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' />
                  </svg>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', margin: 10, justifyContent: 'space-between' }}>
              <span style={{ fontSize: 16 }}>Extremely difficult</span>
              <div
                style={{
                  marginLeft: 40,
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  borderBottomStyle: 'solid',
                  width: 100,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {bottomQuestion?.answer?.value === 3 ? (
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                    <path d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' />
                  </svg>
                ) : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 50 }}>
        <img src={PHQ_SCORE} style={{ width: 350, marginLeft: 50 }} />
      </div>
    </div>
  );
};

export default ViewPhqSurveyModal;
