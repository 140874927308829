import React from 'react';
import styles from './form.module.scss';

type Props = {
  label: string;
  disabled?: boolean;
  size?: string;
  value: string | number | undefined;
};

const StaticTextInput: React.FC<Props> = props => {
  const fieldSize =
    props.size === 'small' ? styles.field__small : props.size === 'big' ? styles.field__big : styles.field;

  return (
    <div className={fieldSize}>
      <label>{props.label}</label>
      <input disabled={props.disabled} value={props.value ? props.value : ''} />
    </div>
  );
};

export default StaticTextInput;
