import { useQuery } from '@apollo/client';
import { SelectInput } from 'components/common/form';
import { Loading } from 'components/common/loading';
import { Form, Formik } from 'formik';
import { useRequestCustomerSurveyMutation } from 'graphql/codegen/hooks';
import { AvailableQuestionnairesQuery } from 'graphql/codegen/operations';
import { GET_AVAILABLE_QUESTIONNAIRES } from 'graphql/queries';
import React, { FunctionComponent } from 'react';
import { useToasts } from 'react-toast-notifications';
import { submitSurveyValidationSchema } from 'utils/form-validations';
import { ReactComponent as BacLogo } from '../../../assets/icons/list.svg';
import { Patient } from '../../../interfaces/patient';
import styles from './request-survey-modal.module.scss';

type Props = {
  closeModal: () => void;
  patient: Patient;
};

interface SubmitRequestValues {
  questionnaireId: number | undefined;
  sendAfter: number;
}

const BacTestRequest: FunctionComponent<Props> = ({ closeModal, patient }) => {
  const { data: questionnaires } = useQuery<AvailableQuestionnairesQuery>(GET_AVAILABLE_QUESTIONNAIRES);
  const [requestSurvey, { loading: sendingRequest, error: errorSendingRequest }] = useRequestCustomerSurveyMutation();

  const { addToast } = useToasts();

  return (
    <Formik<SubmitRequestValues>
      initialValues={{ questionnaireId: undefined, sendAfter: 5 }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        //  handleUpsertMeeting(values);
        setSubmitting(true);

        requestSurvey({
          variables: { fbId: patient.firebaseUid!, qId: values.questionnaireId!, after: values.sendAfter * 60 },
        })
          .then(() => {
            addToast('Request scheduled', { autoDismiss: true, appearance: 'success' });
            closeModal();
            resetForm({ values: { questionnaireId: undefined, sendAfter: 5 } });
            setSubmitting(false);
          })
          .catch(() => {
            addToast('Error scheduling the survey', { autoDismiss: true, appearance: 'error' });
            setSubmitting(false);
          });
      }}
      validationSchema={submitSurveyValidationSchema}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form className={styles.form}>
          <section className={styles.bacTestRequestModal}>
            <div className={styles.modalHeader}>
              <h4>REQUEST SURVEY NOW</h4>
            </div>

            <section className={styles.bacTestRequestContainer}>
              <figure className={`${styles.bacImage}`}>{sendingRequest ? <Loading /> : <BacLogo />}</figure>

              <div className={styles.mainText}>
                <p>
                  Select a survey to send to {patient?.name.first} {patient?.name.last}
                </p>
                <div className={styles.dropbox}>
                  <SelectInput label='' name='questionnaireId' size='big' defaultValue={undefined}>
                    <option value={undefined}>Select Survey</option>
                    {questionnaires?.questionnaires?.map(v => (
                      <option key={v.id} value={v.id}>
                        {v.clinicName}
                      </option>
                    ))}
                  </SelectInput>
                </div>
                <h3>
                  The survey will be sent out in
                  <button
                    type='button'
                    className={styles.minus}
                    onClick={() => setFieldValue('sendAfter', Math.max(1, values.sendAfter - 1))}
                  >
                    &minus;
                  </button>
                  {values.sendAfter}
                  <button
                    type='button'
                    className={styles.plus}
                    onClick={() => setFieldValue('sendAfter', values.sendAfter + 1)}
                  >
                    +
                  </button>
                  minutes.
                </h3>
              </div>

              <div className={`${styles.rowButtons}`}>
                <button type='button' className={styles.cancelButton} onClick={closeModal}>
                  Cancel
                </button>
                <button type='submit' className={styles.saveButton}>
                  Send Now
                </button>
              </div>
            </section>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default BacTestRequest;
