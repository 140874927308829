import { ActorRefFrom, assign, Machine, MachineOptions, send, spawn, StateMachine } from 'xstate';
import { Insight } from '../interfaces/insight';
import { Patient, PhonePermissions, ViviPatient } from '../interfaces/patient';
import {
  getCurrentActivityScore,
  getCurrentBatteryLevel,
  getCurrentEngagementScore,
  getCurrentInsights,
  getCurrentRelapseRisk,
  getCurrentVitals,
  getCurrentViviHealthScore,
  getCurrentWristBandStatus,
  getPhonePermissions,
} from '../services/api/firestore';
import { getPatientById } from '../services/api/patient';
import { logged, optional } from '../utils/func.utils';
import { SimpleMachineEvent } from './common';
import { PractitionerPatientMachineStateActions } from './patients.machine';
import firebase from 'firebase/app';
import { defaultPhonePermissions } from '../utils/constants';

export enum SubscriptionMachineStates {
  Idle = 'Idle',
  Listening = 'Listening',
  Error = 'Error',
  Completed = 'Completed',
}

export enum SubscriptionMachineActions {
  DataCame = 'DataCame',
  ErrorWithSub = 'ErrorWithSub',
  Completed = 'Completed',
}

export interface SubscriptionMachineContext<T, P> {
  loaded: boolean;
  params?: P;
  data?: T;
}

export function createSubscriptionMachine<T, P, E = T, Err = any>(
  subscribe: (
    params: P,
    d: { complete?: () => void; next: (data: E) => void; error?: (err: Error) => void },
  ) => () => void,
  initialParams?: P,
  mapData: (d?: E) => T = d => d as unknown as T,
) {
  return Machine<SubscriptionMachineContext<T, P>, SimpleMachineEvent<any>>(
    {
      initial: initialParams ? SubscriptionMachineStates.Listening : SubscriptionMachineStates.Idle,

      states: {
        [SubscriptionMachineStates.Idle]: {},
        [SubscriptionMachineStates.Listening]: {
          entry: assign((ctx, evt) => ({ loaded: false })),

          invoke: {
            src: (ctx, evt) => (cb, onRecieve) => {
              return subscribe(ctx.params!, {
                next: data => cb({ type: SubscriptionMachineActions.DataCame, data }),
                error: err => cb({ type: SubscriptionMachineActions.ErrorWithSub, data: err }),
                complete: () => cb({ type: SubscriptionMachineActions.Completed }),
              });
            },
          },
          on: {
            [SubscriptionMachineActions.DataCame]: {
              actions: assign((ctx, evt: SimpleMachineEvent<E>) => ({
                data: mapData(evt.data),
                loaded: true,
              })),
            },
            [SubscriptionMachineActions.ErrorWithSub]: {
              target: SubscriptionMachineStates.Error,
            },
            [SubscriptionMachineActions.Completed]: {
              target: SubscriptionMachineStates.Completed,
            },
          },
        },
        [SubscriptionMachineStates.Completed]: {
          type: 'final',
        },
        [SubscriptionMachineStates.Error]: {
          type: 'final',
        },
      },
    },
    {},
    { params: initialParams, loaded: false },
  );
}

interface Score {
  score: number;
  timestamp?: firebase.firestore.Timestamp;
  trend?: number;
}

type SubscriptionActor<T> = ActorRefFrom<StateMachine<SubscriptionMachineContext<T, any>, any, any>>;

export interface PatientMachineCtx {
  patientData: Patient | null;
  detailedPatientData?: Patient | null;
  patientId?: string;
  subscriptions?: {
    viviHealth: SubscriptionActor<Score>;
    currentActivity: SubscriptionActor<Score>;
    currentInsights: SubscriptionActor<any>;
    currentEngagement: SubscriptionActor<Score>;
    currentVitals: SubscriptionActor<{
      hr: number;
      hrv: number;
      rr: number;
      vitalsTimestamp?: firebase.firestore.Timestamp;
    }>;
    currentRelapseRisk: SubscriptionActor<Score>;
    currentStrap: SubscriptionActor<{ isConnected: boolean; wornPercentage?: number }>;
    currentBattery: SubscriptionActor<{ batteryLevel: number }>;
    currentPhonePermissions: SubscriptionActor<PhonePermissions>;
  };
}

export enum PatientMachineActions {
  LoadDetails = 'LoadDetails',
}

export enum PatientMachineStates {
  Listening = 'Listening',
  Loading = 'Loading',
  Error = 'Error',
  LoadingDetails = 'LoadingDetails',
}

export const createPatientMachine = (
  patientData: Patient | string,
  options?: Partial<MachineOptions<PatientMachineCtx, any>>,
) =>
  Machine<PatientMachineCtx>(
    {
      initial: typeof patientData === 'string' ? PatientMachineStates.Loading : PatientMachineStates.Listening,
      on: {
        [PractitionerPatientMachineStateActions.ChangePatientInsight]: {
          actions: send((ctx, evt) => ({ type: SubscriptionMachineActions.DataCame, data: evt.data.insight })),
        },
        [PatientMachineActions.LoadDetails]: {
          target: PatientMachineStates.LoadingDetails,
        },
      },

      states: {
        [PatientMachineStates.Loading]: {
          invoke: {
            src: 'loadPatientById',
            onDone: {
              actions: assign((ctx, evt) => ({
                patientData: evt.data.data,
                patientId: evt.data.data.firebaseUid,
              })),
              target: PatientMachineStates.Listening,
            },
            onError: {
              target: PatientMachineStates.Error,
            },
          },
        },
        [PatientMachineStates.Error]: {},
        [PatientMachineStates.LoadingDetails]: {
          invoke: {
            src: 'loadDetailedPatient',
            onDone: {
              actions: assign(logged((ctx, evt) => ({ detailedPatientData: evt.data.data }))),
              target: PatientMachineStates.Listening,
            },
            onError: {
              target: PatientMachineStates.Listening,
            },
          },
        },
        [PatientMachineStates.Listening]: {
          exit: (ctx, evt) => {
            Object.values(ctx.subscriptions || {}).forEach(sub => sub.stop?.());
          },
          entry: assign((ctx, evt) => {
            return {
              subscriptions: {
                viviHealth: spawn(
                  createSubscriptionMachine(getCurrentViviHealthScore, ctx.patientId, doc => ({
                    score: parseInt(doc?.data()?.score || 0, 10),
                    timestamp: (doc?.data()?.timestamp as firebase.firestore.Timestamp) || undefined,
                    trend: parseInt(doc?.data()?.trend || 0, 10),
                  })),
                  { sync: true },
                ),
                currentActivity: spawn(
                  createSubscriptionMachine(getCurrentActivityScore, ctx.patientId, doc => ({
                    score: parseInt(doc?.data()?.score || 0, 10),
                    timestamp: (doc?.data()?.timestamp as firebase.firestore.Timestamp) || undefined,
                  })),
                  { sync: true },
                ),
                currentRelapseRisk: spawn(
                  createSubscriptionMachine(getCurrentRelapseRisk, ctx.patientId, doc => ({
                    score: parseInt(doc?.data()?.score || 0, 10),
                  })),
                  { sync: true },
                ),
                currentInsights: spawn(
                  createSubscriptionMachine(
                    getCurrentInsights,
                    ctx.patientId,
                    optional(doc => {
                      const current = doc.data() as {
                        alertInsights: Insight[];
                        mostRecentInsight: Insight;
                      };
                      const currentInsight = current?.alertInsights?.length
                        ? current.alertInsights[current.alertInsights.length - 1]
                        : current?.mostRecentInsight;
                      if (currentInsight) {
                        currentInsight.generatedTimestamp = (currentInsight.generatedTimestamp as any).toDate();
                        currentInsight.startTimestamp = (currentInsight.startTimestamp as any)?.toDate();
                        currentInsight.endTimestamp = (
                          currentInsight.endTimestamp as unknown as firebase.firestore.Timestamp
                        )
                          .toDate()
                          .toISOString();
                      }

                      return currentInsight;
                    }),
                  ),
                  { sync: true },
                ),
                currentEngagement: spawn(
                  createSubscriptionMachine(
                    getCurrentEngagementScore,
                    ctx.patientId,

                    optional(
                      doc => ({
                        score: (doc.data()?.score as number) || 0,
                        timestamp: doc.data()?.timestamp,
                        trend: doc.data()?.trend,
                      }),
                      {
                        score: 0,
                        timestamp: undefined,
                        trend: undefined,
                      } as { score: number; timestamp?: firebase.firestore.Timestamp },
                    ),
                  ),
                  {
                    sync: true,
                  },
                ),
                currentVitals: spawn(
                  createSubscriptionMachine(
                    getCurrentVitals,
                    ctx.patientId,
                    optional(
                      doc => ({
                        hr: (doc.data()?.hr as number) || 0,
                        hrv: (doc.data()?.hrv as number) || 0,
                        rr: (doc.data()?.rr as number) || 0,
                        vitalsTimestamp: doc.data()?.timestamp,
                      }),
                      { hr: 0, hrv: 0, rr: 0, vitalsTimestamp: undefined } as {
                        hr: number;
                        hrv: number;
                        rr: number;
                        vitalsTimestamp?: any;
                      },
                    ),
                  ),
                  { sync: true },
                ),
                currentStrap: spawn(
                  createSubscriptionMachine(
                    getCurrentWristBandStatus,
                    ctx.patientId,
                    optional(
                      doc => ({
                        isConnected: !!doc.data()?.state,
                        wornPercentage: (doc.data()?.wornPercentage as number) || 0,
                      }),
                      { isConnected: false, wornPercentage: 0 },
                    ),
                  ),
                  { sync: true },
                ),
                currentBattery: spawn(
                  createSubscriptionMachine(getCurrentBatteryLevel, ctx.patientId, doc => ({
                    batteryLevel: parseInt(doc?.data()?.batteryLevel || 0, 10),
                  })),
                  { sync: true },
                ),
                currentPhonePermissions: spawn(
                  createSubscriptionMachine(
                    getPhonePermissions,
                    ctx.patientId,
                    document => (document?.data() ?? defaultPhonePermissions) as PhonePermissions,
                  ),
                  { sync: true },
                ),
              },
            } as Partial<PatientMachineCtx>;
          }),
        },
      },
    },
    {
      services: {
        loadPatientById: (ctx, evt) => getPatientById(ctx.patientId!),
        loadDetailedPatient: (ctx, evt) => getPatientById(ctx.patientData?.id || ctx.patientId!, true),
        ...(options?.services || {}),
      },
    },
    {
      patientData: typeof patientData === 'string' ? null : patientData,
      patientId: typeof patientData === 'string' ? patientData : patientData.firebaseUid,
      detailedPatientData: null,
    },
  );

export function getFullPatientFromState(machineState: ActorRefFrom<StateMachine<PatientMachineCtx, any, any, any>>) {
  const ctx = machineState.state.context;
  const getActorState = <T>(actor?: ActorRefFrom<StateMachine<SubscriptionMachineContext<T, any>, any, any>>) => {
    return actor?.state.context.data;
  };
  let allSubsLoaded = false;
  if (ctx?.subscriptions) {
    allSubsLoaded = Object.values(ctx.subscriptions).every(
      sub => sub.state.value === SubscriptionMachineStates.Listening && sub.state.context.loaded,
    );
  }
  return {
    ...ctx?.patientData,
    viviScore: getActorState(ctx?.subscriptions?.viviHealth)?.score,
    viviScoreTimestamp: getActorState(ctx?.subscriptions?.viviHealth)?.timestamp,
    viviScoreTrend: getActorState(ctx?.subscriptions?.viviHealth)?.trend,
    engagement: getActorState(ctx?.subscriptions?.currentEngagement)?.score,
    engagementTimestamp: getActorState(ctx?.subscriptions?.currentEngagement)?.timestamp,
    engagementTrend: getActorState(ctx?.subscriptions?.currentEngagement)?.trend,
    hr: getActorState(ctx?.subscriptions?.currentVitals)?.hr,
    hrv: getActorState(ctx?.subscriptions?.currentVitals)?.hrv,
    rr: getActorState(ctx?.subscriptions?.currentVitals)?.rr,
    activityScore: getActorState(ctx?.subscriptions?.currentActivity)?.score,
    activityScoreTimestamp: getActorState(ctx?.subscriptions?.currentActivity)?.timestamp,
    vitalsTimestamp: getActorState(ctx?.subscriptions?.currentVitals)?.vitalsTimestamp,
    insight: getActorState(ctx?.subscriptions?.currentInsights),
    isConnected: getActorState(ctx?.subscriptions?.currentStrap)?.isConnected,
    wornPercentage: getActorState(ctx?.subscriptions?.currentStrap)?.wornPercentage,
    batteryLevel: getActorState(ctx?.subscriptions?.currentBattery)?.batteryLevel,
    relapseRiskScore: getActorState(ctx?.subscriptions?.currentRelapseRisk)?.score,
    phonePermissions: getActorState(ctx?.subscriptions?.currentPhonePermissions),
    state: allSubsLoaded ? 'LISTENING' : 'LOADING_SUBSCRIPTIONS',
  } as UIViviPatient;
}

export interface UIViviPatient extends ViviPatient {
  state: 'LOADING' | 'LISTENING' | 'LOADING_SUBSCRIPTIONS';
}
