import React, { FunctionComponent } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 30,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 17,
    paddingTop: 17,
  },
});

type Props = {
  headers: { title: string; width: number }[];
};

const TableHeader: FunctionComponent<Props> = ({ headers }) => {
  return (
    <View style={styles.tableHeader}>
      {headers.map(({ title, width }, index) => (
        <Text style={{ width, color: '#323335' }} key={index}>
          {title}
        </Text>
      ))}
    </View>
  );
};

export default TableHeader;
