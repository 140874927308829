import React, { FunctionComponent, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import tableStyles from '../admin-clients.module.scss';
import styles from '../upsert-client.module.scss';
import { Ally, Allies } from '../../../interfaces/common';
import { RemoteData } from '../../../utils/remote-data';
import Table from '../../common/table/table';
import { SUPPORTER_ACCOUNT_STATE } from '../../../utils/constants';
import { displayFullName } from '../../../utils/data-parser';

type Props = {
  allies: RemoteData<Allies>;
};

const AlliesTable: FunctionComponent<Props> = ({ allies }) => {
  const columns: Column<Ally>[] = useMemo(
    () => [
      {
        Header: 'SUPPORTER',
        accessor: (originalRow): object => {
          return {
            nameDisplay: displayFullName(originalRow.name),
          };
        },
        Cell: ({ cell: { value } }) => <span className={tableStyles.overflowWrapped}>{value.nameDisplay}</span>,
        width: 323,
      },
      {
        Header: 'Status',
        width: 200,
        accessor: originalRow => {
          return {
            status: !originalRow.supporter
              ? 'Normal'
              : SUPPORTER_ACCOUNT_STATE[originalRow.supporterState] === SUPPORTER_ACCOUNT_STATE.ACTIVE
              ? 'ViviCircle'
              : 'Pending',
            appVersion: originalRow.userConfiguration?.appVersion
              ? `- v${originalRow.userConfiguration?.appVersion}`
              : '',
          };
        },
        Cell: ({ cell: { value } }) => `${value.status} ${value.appVersion}`,
      },
      {
        Header: 'PHONE #',
        width: 155,
        accessor: 'phone',
        Cell: ({ cell: { value, row } }: CellProps<Ally, string>) => (row.original.supporter ? value ?? '' : ''),
      },
      {
        Header: 'Times Called',
        width: 200,
        accessor: 'phoneCount',
      },
      {
        Header: 'Times Texted',
        accessor: 'textCount',
        width: 259,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'order', desc: false }] }),
    [],
  );

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: tableStyles.tr,
      style: {
        height: '32px',
      },
    };
  };

  const getHeaderProps = column => {
    return {
      className: tableStyles.th__modal,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: tableStyles.td,
      style: {
        justifyContent: 'flex-start',
      },
    };
  };

  const getTableBodyProps = () => {
    return {
      className: tableStyles.tbody,
      style: {
        height: '291px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: allies.status === 'Done' && !allies.data.values ? tableStyles.noContent : tableStyles.table,
      style: {
        width: '100%',
        boxShadow: 'none',
      },
    };
  };
  return (
    <div className={styles.body__noMargin}>
      <Table<Ally>
        columns={columns}
        data={allies.status === 'Done' ? allies.data.values : []}
        isLoading={allies.status === 'Loading'}
        error={allies.status === 'Failed'}
        getTableProps={() => getTableProps()}
        getRowProps={row => getRowProps(row)}
        getHeaderProps={column => getHeaderProps(column)}
        getCellProps={cell => getCellProps(cell)}
        getTableBodyProps={() => getTableBodyProps()}
        defaultColumn={{ width: 150, minWidth: 80 }}
        initialState={defaultSorting}
        emptyDataMessage='There is no data at this time.'
        isModal={true}
      />
    </div>
  );
};

export default AlliesTable;
