import React, { FunctionComponent } from 'react';
import styles from './permission-card.module.scss';
import { LocationPermissionType, PhonePermissionType } from '../../../../interfaces/patient';
import { getPermissionStatusComponent } from '../../../../utils/phone-permission-helper';

type Props = {
  title: string;
  subtitle: string;
  permissionType: PhonePermissionType | LocationPermissionType;
  image: React.FC;
  permissionStatus?: JSX.Element;
};

const PermissionCard: FunctionComponent<Props> = ({ title, subtitle, permissionType, image: Image }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>{title}</div>
      <div className={styles.cardSubtitle}>{subtitle}</div>
      <div className={styles.imageContainer}>
        <figure>
          <Image />
        </figure>
        <div className={styles.statusGroup}>{getPermissionStatusComponent(title, permissionType)}</div>
      </div>
    </div>
  );
};

export default PermissionCard;
