import React from 'react';
import { Field, ErrorMessage, FieldAttributes } from 'formik';
import styles from './form.module.scss';

type Props = {
  name: string;
  field: string;
  handleOnChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  errorHandledInForm?: boolean;
  hasError?: boolean;
  customErrorMessage?: string;
} & FieldAttributes<any>;

const CheckBox: React.FC<Props> = props => {
  return (
    <>
      <label htmlFor={props.name} className={`${styles.labelCheck} ${props.hasError ? styles.labelCheckError : ''}`}>
        {props.handleOnChange ? (
          <Field type='checkbox' name={props.name} checked={props.checked} onChange={props.handleOnChange} />
        ) : (
          <Field type='checkbox' name={props.name} />
        )}
        <span />
        {props.field}
      </label>
      {props.errorHandledInForm && (
        <span className={styles.checkboxError}>
          <ErrorMessage name={props.name} />
        </span>
      )}
      {!props.errorHandledInForm && props.hasError && (
        <span className={styles.checkboxError}>{props.customErrorMessage}</span>
      )}
    </>
  );
};

export default CheckBox;
