import React from 'react';

export const Collapse = props => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      style={props.style}
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6863 6.34314L1 6.34314'
        stroke='#323335'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
