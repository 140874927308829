import { get, put } from './base';
import { RemoteData, done, failed, Done, Failed } from '../../utils/remote-data';
import { Organization } from '../../interfaces/organization';
import { Organization as OrganizationGQL } from '../../graphql/codegen/schemas';

export const getOrganizationById = async (organizationId: string): Promise<RemoteData<Organization>> => {
  try {
    const res = await get(`/organizations/${organizationId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const updateBACInventory = async (organizationId: string, increase: boolean): Promise<RemoteData<any>> => {
  try {
    const res = await put(
      `/organizations/${organizationId}/indistinguishable-devices?deviceModelType=BAC&increase=${increase}`,
    );
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const updateOrganization = async (
  organizationId: string,
  organizationUpdated: OrganizationGQL,
): Promise<Done<any> | Failed> => {
  try {
    const res = await put(`/organizations/${organizationId}`, organizationUpdated);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};


export const getOrganizationEmailAlert = async (
  organizationId: string,
  alertCategory: string
): Promise<Done<any> | Failed> => {
  try {
    const res = await get(`/organizations/${organizationId}/email-alert/${alertCategory}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
}

export const updateOrganizationEmailAlert = async (
  organizationId: string,
  alertCategory: string,
  settings: any
): Promise<Done<any> | Failed> => {
  try {
    const res = await put(`/organizations/${organizationId}/email-alert/${alertCategory}`, settings);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
}



// /organizations/{{ account_id }}/email-alert/{{ alertCategory }}