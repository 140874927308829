import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
firebase.performance();
firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();

export function collectionListener<K, T>(
  getCollection: (
    store: firebase.firestore.Firestore,
    k: K,
  ) => firebase.firestore.CollectionReference | firebase.firestore.Query,
  map: (k: firebase.firestore.DocumentSnapshot) => T = e => e.data() as T,
) {
  return (k: K, onChange: (t: T[]) => void, errorCb?: (err: firebase.firestore.FirestoreError) => void) => {
    return getCollection(firestore, k).onSnapshot(data => {
      onChange(data.docs.map(d => map(d)));
    }, errorCb);
  };
}

export function documentListener<K, T>(
  getDocument: (store: firebase.firestore.Firestore, k: K) => firebase.firestore.DocumentReference,
  map: (k: firebase.firestore.DocumentSnapshot) => T | null = e => (e.exists ? (e.data() as T) : null),
) {
  return (k: K, onChange: (t: T | null) => void, errorCb?: (err: firebase.firestore.FirestoreError) => void) => {
    return getDocument(firestore, k).onSnapshot(document => {
      onChange(map(document));
    });
  };
}
