import { useActor } from '@xstate/react';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { PatientRoutes } from '../../App';
import { PatientsContext } from '../../contexts/PractitionerPatientsContext';
import {
  getFullPatientFromState,
  PatientMachineActions,
  PatientMachineCtx,
  PatientMachineStates,
  UIViviPatient,
} from '../../machines/patient.machine';
import { getHealthbandInfo } from '../../services/api/device';
import { done, loading } from '../../utils/remote-data';
import { PatientDetailCard } from '../patient-detail-card';
import './patient-detail.scss';

// type Preference = {

// }

export const PatientHome = () => {
  const { url, params } = useRouteMatch<{ id: string }>();
  const { pathname } = useLocation();
  const { getPatient } = useContext(PatientsContext);
  const patientRef = getPatient(params.id);
  const [patientMachineState, sendPatientMachine] = useActor<any, any>(patientRef || null);
  const [patient, setPatient] = useState(undefined as UIViviPatient | undefined);
  const [deviceInfo, setDeviceInfo] = useState<any>()
  const [device, setDevice] = useState<boolean>(false);

  const getDeviceInfo = async() => {
    if (patient) {
      const data = await getHealthbandInfo(patient.id);
      setDeviceInfo(data);
      setDevice(true)
    }
  }

  useEffect(() => {
    if (!device) {
      getDeviceInfo();
    }
  }, [patient, deviceInfo])

  useEffect(() => {
    if (
      patientRef &&
      getFullPatientFromState(patientRef)?.state === 'LISTENING' &&
      patientMachineState.context.patientData
    ) {
      setPatient(getFullPatientFromState({ state: patientMachineState } as any));
    }
    if (
      patientRef &&
      getFullPatientFromState(patientRef)?.state === 'LISTENING' &&
      !(patientMachineState.context as PatientMachineCtx).detailedPatientData &&
      patientMachineState.value !== PatientMachineStates.LoadingDetails
    ) {
      sendPatientMachine({ type: PatientMachineActions.LoadDetails });
    }
  }, [patientMachineState]);
  const patientDetails = (patientMachineState.context as PatientMachineCtx).detailedPatientData;

  return (
    <div className='patient-home-container'>
      <nav className='main-nav'>
        <Link to='/home' className='back-link'>
          <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M8 13L2 7L8 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
          </svg>
          Back to Triage Page
        </Link>
        <Link to={`${url}/details`} className={pathname.includes('details') ? 'active' : ''}>
          Client Details
        </Link>
        <Link to={`${url}/compliance/locations`} className={pathname.includes('compliance') ? 'active' : ''}>
          Compliance
        </Link>
        <Link to={`${url}/notes`} className={pathname.includes('notes') ? 'active' : ''}>
          Notes
        </Link>
        <Link to={`${url}/utilization`} className={pathname.includes('utilization') ? 'active' : ''}>
          Utilization Review
        </Link>
        {process.env.REACT_APP_ENVIRONMENT === 'development' && (
          <>
            <Link to={`${url}/reports`} className={pathname.includes('reports') ? 'active' : ''}>
              Reports
            </Link>
            <Link to={`${url}/rx`} className={pathname.includes('rx') ? 'active' : ''}>
              RX
            </Link>
          </>
        )}
        <Link to={`${url}/survey`} className={pathname.includes('survey') ? 'active' : ''}>
          Survey
        </Link>
        <Link to={`${url}/insights`} className={pathname.includes('insights') ? 'active' : ''}>
          Insights
        </Link>
      </nav>
      <div className='detail-child-container'>
        <div className='left-container'>
          <PatientDetailCard
            patient={patient}
            patientDetails={patientDetails ? done(patientDetails) : loading()}
            refetchDetails={() => {
              // call load details in patient machine to fetch again patient details
              sendPatientMachine({ type: PatientMachineActions.LoadDetails });
            }}
            deviceInfo={deviceInfo?.status === 'Done' ? deviceInfo.data : {make: '', model: ''}}
          />
        </div>
        <div className='right-container'>{PatientRoutes}</div>
      </div>
    </div>
  );
};
