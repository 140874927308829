import { ActorRefFrom, assign, Machine, send, spawn, StateMachine } from 'xstate';
import { getPractitionerPatients } from '../services/api/patient';
import { createPatientMachine, PatientMachineCtx } from './patient.machine';
import { Patient } from '../interfaces/patient';

export enum PractitionerPatientsMachineStates {
  Loading = 'Loading',
  Loaded = 'Loaded',
  ErrorLoading = 'ErrorLoading',
  Idle = 'Idle',
}
export enum PractitionerPatientMachineStateActions {
  SetPractitioner = 'SetPractitioner',
  ChangePatientInsight = 'ChangePatientInsight',
  LoadPatient = 'LoadPatient',
  UnloadPatient = 'UnloadPatient',
  ReloadPatient = 'ReloadPatient',
  ReloadPractitionerPatients = 'ReloadPractitionerPatients',
}
export type PatientActorRef = ActorRefFrom<StateMachine<PatientMachineCtx, any, any>>;
export interface PractitionerPatientsMachineCtx {
  practitionerId?: string;
  patientsRef: PatientActorRef[];
  patientsRefById: { [id: string]: PatientActorRef };
  view: string;
}
export const PractitionerPatientMachine = Machine<PractitionerPatientsMachineCtx>(
  {
    initial: PractitionerPatientsMachineStates.Idle,
    on: {
      [PractitionerPatientMachineStateActions.SetPractitioner]: {
        target: PractitionerPatientsMachineStates.Loading,
        actions: [
          (ctx, evt) => {
            ctx.patientsRef.forEach(p => p.stop?.());
          },
          assign((ctx, evt) => ({ practitionerId: evt.data.practitionerId, view: evt.data.view })),
        ],
        cond: (ctx, evt) => {
          return evt.data.practitionerId !== ctx.practitionerId;
        },
      },
      [PractitionerPatientMachineStateActions.ChangePatientInsight]: {
        actions: send((ctx, evt) => evt, {
          to: (ctx, evt) => ctx.patientsRef.find(pRef => pRef.id === evt.data.patientId)!,
        }),
      },
      [PractitionerPatientMachineStateActions.LoadPatient]: {
        actions: assign((ctx, msg) => ({
          patientsRefById: !ctx.patientsRefById[msg.data.patientId]
            ? {
                ...ctx.patientsRefById,
                [msg.data.patientId]: spawn(createPatientMachine(msg.data.patientId), {
                  sync: true,
                  name: msg.data.patientId,
                }),
              }
            : ctx.patientsRefById,
        })),
      },
      [PractitionerPatientMachineStateActions.ReloadPatient]: {
        actions: assign((ctx, msg) => {
          const patientIndex = ctx.patientsRef.findIndex(pRef => pRef.id === msg.data.patientId);
          ctx.patientsRef[patientIndex] = spawn(createPatientMachine(msg.data.patientId), {
            sync: true,
            name: msg.data.patientId,
          });
          return {
            patientsRef: ctx.patientsRef,
          };
        }),
      },
      [PractitionerPatientMachineStateActions.ReloadPractitionerPatients]: {
        target: PractitionerPatientsMachineStates.Loading,
        actions: [
          (ctx, evt) => {
            ctx.patientsRef.forEach(p => p.stop?.());
          },
        ],
      },
    },

    states: {
      [PractitionerPatientsMachineStates.Loaded]: {},
      [PractitionerPatientsMachineStates.Loading]: {
        invoke: {
          src: 'getPatientsForPracticioner',
          onDone: {
            actions: assign((ctx, evt) => {
              const patientsRef = (evt.data.data.values as Patient[]).map(p =>
                spawn(createPatientMachine(p), { sync: true, name: p.id }),
              );
              return {
                patientsRef,
                patientsRefById: patientsRef.reduce(
                  (result, patientRef) => {
                    result[patientRef.id] = patientRef;
                    return result;
                  },
                  { ...ctx.patientsRefById },
                ),
              };
            }),
            target: PractitionerPatientsMachineStates.Loaded,
          },
        },
      },
      [PractitionerPatientsMachineStates.ErrorLoading]: {},
      [PractitionerPatientsMachineStates.Idle]: {},
    },
  },
  {
    services: {
      async getPatientsForPracticioner(ctx, evt) {
        return getPractitionerPatients(ctx.practitionerId, ctx.view, ctx.view === 'triageView');
      },
    },
  },
  {
    patientsRef: [],
    patientsRefById: {},
    practitionerId: undefined,
    view: '',
  },
);
