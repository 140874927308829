import React from 'react';
import ExplanationBar from '../explanation-bar';
import ScoreBar from '../score-bar';
import { ReactComponent as ThumbIcon } from '../../../../assets/icons/icn_thumbs_up.svg';
import { ReactComponent as MoodIcon } from '../../../../assets/icons/icn_self-care.svg';
import { ReactComponent as StressIcon } from '../../../../assets/icons/icn_stress.svg';
import { ReactComponent as SleepIcon } from '../../../../assets/icons/icn_sleep.svg';
import { ReactComponent as ActivityIcon } from '../../../../assets/icons/icn_activity.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/icn_phone.svg';
import styles from '../chart.module.scss';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>VIVIHEALTH SCORE</h3>
      <p>
        The ViviHealth score is a general measurement of overall wellness, combining health and wellbeing information
        gathered by Vivi360. The ViviHealth chart plots the ViviHealth Score hourly (from 0 to 100) across your client’s
        recovery journey.
      </p>
      <p>ViviHealth score is a composite score made up of several factors:</p>
      <ul className={styles.list}>
        <li>
          <strong>•</strong> <strong>Engagement Score</strong> (see the Engagement Score for its individual factors)
        </li>
        <li>
          <strong>• Sleep, Stress, Mood</strong> and <strong>Activity scores</strong>
        </li>
        <li>
          <strong>• Adherence score</strong> (poor adherence to treatment directives can cause a &nbsp; &nbsp;
          &emsp;&nbsp;&nbsp;temporary, modest decrease in ViviHealth Score)
        </li>
      </ul>

      <p>Clients can increase their ViviHealth score by:</p>

      <ul className={styles.list}>
        <li>
          <figure>
            <PhoneIcon />
          </figure>
          increasing their <strong>Engagement Score</strong> (Such as: attending meetings, BAC &emsp; &nbsp; check-ins,
          communicating, taking surveys, selfcare, wearing their health &emsp; &nbsp; band)
        </li>
        <li>
          <figure>
            <SleepIcon />
          </figure>
          improving <strong>sleep</strong>
        </li>
        <li>
          <figure>
            <StressIcon />
          </figure>
          practicing healthy ways to better <strong>manage stress</strong>
        </li>
        <li>
          <figure>
            <ActivityIcon />
          </figure>
          increasing <strong>activity</strong> (as directed by their doctor or clinician)
        </li>
        <li>
          <figure>
            <MoodIcon />
          </figure>
          more positive <strong>mood</strong> (less negative thoughts)
        </li>
        <li>
          <figure>
            <ThumbIcon />
          </figure>
          increasing their <strong>Adherence Score</strong> (Such as: passing BACs and avoiding &emsp; &nbsp; geofences)
        </li>
      </ul>
      <p>
        <strong>Vivihealth ranges</strong>
      </p>
      <div className={styles.scoreDescriptionContainer}>
        <div className={styles.scoreContainer}>
          <h5>
            <ScoreBar
              scoreList={[
                { width: '30%', value: 30, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
                { width: '30%', value: 60, color: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)' },
                { width: '20%', value: 80, color: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 70%)' },
                { width: '20%', value: 100, color: 'linear-gradient(90deg, #6BE3B9 100%, #5CC5E2 100%)' },
              ]}
            />
          </h5>
          <ExplanationBar
            rangeList={[
              { width: '30%', text: 'Ask for help' },
              { width: '30%', text: 'Doing Okay' },
              { width: '20%', text: 'Good' },
              { width: '20%', text: 'Great' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
