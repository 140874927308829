import React, { Dispatch, FC, SetStateAction } from 'react';
import { ViviPatient } from '../../interfaces/patient';
import { Loading } from '../common/loading';
import { Expand } from '../../assets/icons';
import { Collapse } from '../../assets/icons';
import { Card } from '../card';
import { HomeActions, PATIENT_STATUS, RELAPSE_RISK_SCORE_RANGE } from '../../utils/constants';
import { UIViviPatient } from '../../machines/patient.machine';
import { RemoteData } from '../../utils/remote-data';
import { NotesResult } from '../../interfaces/note-result';

type Props = {
  loadingCount: number;
  patients: UIViviPatient[];
  filterValue: string;
  criticalCollapsed: boolean;
  setCriticalCollapsed: Dispatch<SetStateAction<boolean>>;
  history: any;
  setSelectedPatient: Dispatch<
    SetStateAction<{ selectedPatient: ViviPatient | undefined; selectedAction: HomeActions | string }>
  >;
  getPatientsAction: () => Promise<void>;
  atRiskCollapsed: boolean;
  setAtRiskCollapsed: Dispatch<SetStateAction<boolean>>;
  stableCollapsed: boolean;
  setStableCollapsed: Dispatch<SetStateAction<boolean>>;
  setNote: Dispatch<SetStateAction<RemoteData<NotesResult>>>;
};

const CardsView: FC<Props> = ({
  loadingCount,
  patients,
  filterValue,
  criticalCollapsed,
  setCriticalCollapsed,
  history,
  setSelectedPatient,
  getPatientsAction,
  atRiskCollapsed,
  setAtRiskCollapsed,
  stableCollapsed,
  setStableCollapsed,
  setNote,
}) => (
  <div>
    {(loadingCount > 0 || patients?.length > 0) && (
      <>
        {loadingCount > 0 && (
          <div className='loadingContainer'>
            <Loading />
          </div>
        )}
        {/* CRITICAL ROW */}
        <div className='rowHeader'>
          {/* LEFT SIDE */}
          <div className='left'>
            <span className='title'>CRITICAL</span>
            <span className='count'>
              (
              {
                patients?.filter(
                  p =>
                    p.relapseRiskScore >= RELAPSE_RISK_SCORE_RANGE.CRITICAL.MIN &&
                    p.relapseRiskScore <= RELAPSE_RISK_SCORE_RANGE.CRITICAL.MAX &&
                    (p?.name?.first?.toLowerCase()?.includes(filterValue.toLowerCase()) ||
                      p?.name?.last?.toLowerCase()?.includes(filterValue.toLowerCase())),
                ).length
              }
              )
            </span>
          </div>
          {/* RIGHT SIDE */}
          <div className='right'>
            <>
              <span className='title'>{criticalCollapsed ? 'Expand' : 'Collapse'} Cards</span>
              {criticalCollapsed ? (
                <Expand className='pointer' onClick={() => setCriticalCollapsed(false)} />
              ) : (
                <Collapse className='pointer' onClick={() => setCriticalCollapsed(true)} />
              )}
            </>
          </div>
        </div>

        {/* CARD VIEW */}

        {/* CRITICAL CARD ROW */}
        <div className='cardRow'>
          <>
            {patients
              ?.filter(
                p =>
                  p.relapseRiskScore >= RELAPSE_RISK_SCORE_RANGE.CRITICAL.MIN &&
                  p.relapseRiskScore <= RELAPSE_RISK_SCORE_RANGE.CRITICAL.MAX &&
                  (p?.name?.first?.toLowerCase()?.includes(filterValue.toLowerCase()) ||
                    p?.name?.last?.toLowerCase()?.includes(filterValue.toLowerCase())),
              )
              .map(patient => {
                return patient.state === 'LOADING_SUBSCRIPTIONS' ? (
                  <div key={patient.id} className='loadingCard'>
                    <Loading />
                  </div>
                ) : (
                  <Card
                    key={patient.id}
                    firebasePatient={patient}
                    isCollapsed={criticalCollapsed}
                    history={history}
                    status={PATIENT_STATUS.CRITICAL}
                    setSelectedPatientAction={setSelectedPatient}
                    getPatientsAction={getPatientsAction}
                    setNote={setNote}
                  />
                );
              })}
          </>
        </div>

        {/* AT RISK ROW */}
       <div className='rowHeader'>
          {/* LEFT SIDE LESS THAN 30 LESS THAN 70 */}
          <div className='left'>
            <span className='title'>AT RISK</span>
            <span className='count'>
              (
              {
                patients?.filter(
                  p =>
                    p.relapseRiskScore >= RELAPSE_RISK_SCORE_RANGE.AT_RISK.MIN &&
                    p.relapseRiskScore <= RELAPSE_RISK_SCORE_RANGE.AT_RISK.MAX &&
                    (p?.name?.first?.toLowerCase()?.includes(filterValue.toLowerCase()) ||
                      p?.name?.last?.toLowerCase()?.includes(filterValue.toLowerCase())),
                ).length
              }
              )
            </span>
          </div>
          {/* RIGHT SIDE */}
          <div className='right'>
            <>
              <span className='title'>{atRiskCollapsed ? 'Expand' : 'Collapse'} Cards</span>
              {atRiskCollapsed ? (
                <Expand className='pointer' onClick={() => setAtRiskCollapsed(false)} />
              ) : (
                <Collapse className='pointer' onClick={() => setAtRiskCollapsed(true)} />
              )}
            </>
          </div>
        </div>

        {/* AT RISK CARD ROW */}
        <div className='cardRow'>
          <>
            {patients
              ?.filter(
                p =>
                  p.relapseRiskScore >= RELAPSE_RISK_SCORE_RANGE.AT_RISK.MIN &&
                  p.relapseRiskScore <= RELAPSE_RISK_SCORE_RANGE.AT_RISK.MAX &&
                  (p?.name?.first?.toLowerCase()?.includes(filterValue.toLowerCase()) ||
                    p?.name?.last?.toLowerCase()?.includes(filterValue.toLowerCase())),
              )
              .map(patient => {
                return patient.state === 'LOADING_SUBSCRIPTIONS' ? (
                  <div key={patient.id} className='loadingCard'>
                    <Loading />
                  </div>
                ) : (
                  <Card
                    key={patient.id}
                    firebasePatient={patient}
                    isCollapsed={atRiskCollapsed}
                    history={history}
                    status={PATIENT_STATUS.AT_RISK}
                    setSelectedPatientAction={setSelectedPatient}
                    getPatientsAction={getPatientsAction}
                    setNote={setNote}
                  />
                );
              })}
          </>
        </div>

        {/* STABLE ROW */}
        <div className='rowHeader'>
          <div className='left'>
            <span className='title'>STABLE</span>
            <span className='count'>
              {console.log(patients)}
              (
              {
                patients?.filter(
                  p =>
                    (!p.relapseRiskScore && p.relapseRiskScore !== null ||  p.relapseRiskScore !== undefined ||
                      (p.relapseRiskScore >= RELAPSE_RISK_SCORE_RANGE.STABLE.MIN &&
                        p.relapseRiskScore <= RELAPSE_RISK_SCORE_RANGE.STABLE.MAX)) &&
                    (p?.name?.first?.toLowerCase()?.includes(filterValue.toLowerCase()) ||
                      p?.name?.last?.toLowerCase()?.includes(filterValue.toLowerCase())),
                ).length
              }
              )
            </span>
          </div>
          {/* RIGHT SIDE */}
          <div className='right'>
            <>
              <span className='title'>{stableCollapsed ? 'Expand' : 'Collapse'} Cards</span>
              {stableCollapsed ? (
                <Expand className='pointer' onClick={() => setStableCollapsed(false)} />
              ) : (
                <Collapse className='pointer' onClick={() => setStableCollapsed(true)} />
              )}
            </>
          </div>
        </div>

        {/* stable CARD ROW */}
        <div className='cardRow'>
          <>
            {patients
              ?.filter(
                p =>
                  (p.relapseRiskScore === 0 || 
                    (p.relapseRiskScore >= RELAPSE_RISK_SCORE_RANGE.STABLE.MIN &&
                      p.relapseRiskScore <= RELAPSE_RISK_SCORE_RANGE.STABLE.MAX)) &&
                  (p?.name?.first?.toLowerCase()?.includes(filterValue.toLowerCase()) ||
                    p?.name?.last?.toLowerCase()?.includes(filterValue.toLowerCase())),
              )
              .map(patient => {
                return patient.state === 'LOADING_SUBSCRIPTIONS' ? (
                  <div key={patient.id} className='loadingCard'>
                    <Loading />
                  </div>
                ) : (
                  <Card
                    key={patient.id}
                    firebasePatient={patient}
                    isCollapsed={stableCollapsed}
                    history={history}
                    status={PATIENT_STATUS.STABLE}
                    setSelectedPatientAction={setSelectedPatient}
                    getPatientsAction={getPatientsAction}
                    setNote={setNote}
                  />
                );
              })}
          </>
        </div>
      </>
    )}
  </div>
);

export default CardsView;
