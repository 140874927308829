import React, { FunctionComponent } from 'react';
import styles from './confirm-update-geofence.module.scss';
import { Geofence } from '../../../../interfaces/geofence';

type Props = {
  selectedGeofence: Geofence;
  closeModal: () => void;
  removeGeofence: () => void;
};

const RemoveGeofenceDialog: FunctionComponent<Props> = ({ selectedGeofence, closeModal, removeGeofence }) => {
  return (
    <section>
      <div className={styles.modalHeader}>
        <h4>Confirm Delete Geofence</h4>
      </div>
      <div className={styles.confirmContainer}>
        <div className={styles.mainContent}>
          <p>
            {' '}
            <span className={styles.geofenceNameText}>{selectedGeofence.name}</span>{' '}
            {selectedGeofence.geofencePoint.address}
          </p>
          <p className={styles.mainText}>
            Are you sure you would like to delete this{' '}
            {selectedGeofence.type === 'NON_COMPLIANT' ? 'non-compliant' : 'special'} geofence?
            <br />
            Geofence violations at this location will still be saved in list view.
          </p>
          <h6 className={styles.smallText}>Note: this cannot be undone.</h6>
        </div>
        <div className={`${styles.rowButtons}`}>
          <button className={styles.cancelButton} onClick={() => closeModal()} type='button'>
            Cancel
          </button>
          <button className={styles.saveButton} onClick={() => removeGeofence()} type='button'>
            Delete Geofence
          </button>
        </div>
      </div>
    </section>
  );
};

export default RemoveGeofenceDialog;
