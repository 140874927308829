import React from 'react';
import './toast.scss';
import { ToastProps } from 'react-toast-notifications';

const errorIcon = (
  <svg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0052 0.824092L16.7697 12.0474C17.4257 13.1462 16.6057 14.5197 15.2528 14.5197H1.72388C0.411986 14.5197 -0.448947 13.1462 0.247999 12.0474L7.01247 0.824092C7.66842 -0.274697 9.34929 -0.274697 10.0052 0.824092ZM9.10331 1.33424C8.85733 0.863335 8.16038 0.863335 7.9144 1.33424L1.14993 12.5576C0.862951 12.9893 1.19093 13.5387 1.72388 13.5387H15.2528C15.8268 13.5387 16.1548 12.9893 15.8678 12.5576L9.10331 1.33424Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.48704 3.96387C8.81501 3.96387 9.10199 4.19932 9.10199 4.51326V9.02616C9.10199 9.3401 8.81501 9.57555 8.48704 9.57555C8.20006 9.57555 7.91309 9.3401 7.91309 9.02616V4.51326C7.91309 4.19932 8.20006 3.96387 8.48704 3.96387Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.48599 10.2422C8.89596 10.2422 9.22393 10.5561 9.22393 10.9486C9.22393 11.341 8.89596 11.655 8.48599 11.655C8.07602 11.655 7.74805 11.341 7.74805 10.9486C7.74805 10.5561 8.07602 10.2422 8.48599 10.2422Z'
      fill='white'
    />
  </svg>
);

const successIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='10' cy='10' r='9.25' stroke='white' strokeWidth='1.5' />
    <path
      d='M8.99283 14.0271L8.99247 14.0268L5.96763 10.9155L5.96758 10.9154C5.74564 10.687 5.74379 10.3183 5.96417 10.0879M8.99283 14.0271L9.41378 13.9996C9.51738 13.9957 9.61455 13.9476 9.68261 13.8662L9.83577 13.9948L9.83586 13.9947C9.83591 13.9947 9.83596 13.9946 9.83602 13.9945L15.0608 7.77313C15.0608 7.77311 15.0608 7.77309 15.0608 7.77307C15.2656 7.52937 15.2421 7.16125 15.0055 6.94731L15.0053 6.94714C14.7642 6.72964 14.3969 6.75681 14.1892 7.00505C14.1891 7.00514 14.189 7.00523 14.1889 7.00532L9.36927 12.7448L6.78264 10.0843L6.78232 10.084C6.55625 9.85254 6.18837 9.85308 5.96417 10.0879M8.99283 14.0271C9.09969 14.1365 9.24572 14.2 9.39979 14.2C9.40263 14.2 9.4129 14.2001 9.42504 14.1993L8.99283 14.0271ZM5.96417 10.0879C5.96414 10.0879 5.9641 10.088 5.96406 10.088L6.10876 10.2261L5.96424 10.0878C5.96422 10.0879 5.9642 10.0879 5.96417 10.0879Z'
      fill='white'
      stroke='white'
      strokeWidth='0.4'
    />
  </svg>
);

export const Toast = ({ appearance = 'error', children = '', onDismiss }: ToastProps): JSX.Element => {
  return (
    <div className='toast'>
      <div className={`icon-container ${appearance}`}>
        {appearance === 'success' ? successIcon : appearance === 'error' ? errorIcon : errorIcon}
      </div>
      <div className='text-container'>{children}</div>
      <div className='close-container'>
        <svg aria-hidden='true' height='16' width='14' viewBox='0 0 14 16' onClick={() => onDismiss()}>
          <path
            fillRule='evenodd'
            d='M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z'
          />
        </svg>
      </div>
    </div>
  );
};
