import moment from 'moment-timezone';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';
import { Heart, Insight as InsightLogo, InsightGood, More, NoSignal, Signal } from '../../assets/icons';
import { Patient, ViviPatient } from '../../interfaces/patient';
import { getLastNote } from '../../services/api/note';
import { dischargePatient, DischargePatientRequest, getPatientDetails } from '../../services/api/patient';
import { EPISODE_OF_CARE, HEX_COLORS, HomeActions, statusColor, VIVIHEALTH_SCORE_RANGE } from '../../utils/constants';
import { done, loading, notAsked, RemoteData } from '../../utils/remote-data';
import DischargeClientConfirmation from '../admin-clients/discharge-client-confirmation';
import UpsertClientContainer from '../admin-clients/upsert-client-container';
import ProfileImage from '../common/profile-image';
import { DropDown } from '../dropdown';
import { caseOf } from '../../utils/case-of';
import { isPermissionsActive } from '../../utils/data-parser';
import { ApprovedIcon } from '../../assets/icons/permissions';
import { NotesResult } from '../../interfaces/note-result';

type Props = {
  firebasePatient: ViviPatient;
  history: any;
  status: string;
  isCollapsed: boolean;
  setSelectedPatientAction: Dispatch<
    SetStateAction<{ selectedPatient: ViviPatient | undefined; selectedAction: HomeActions | string }>
  >;
  getPatientsAction: () => Promise<void>;
  setNote: Dispatch<SetStateAction<RemoteData<NotesResult>>>;
};

export const Card: React.FC<Props> = ({
  firebasePatient,
  history,
  status,
  isCollapsed = false,
  setSelectedPatientAction,
  getPatientsAction,
  setNote,
}): JSX.Element => {
  const { addToast } = useToasts();

  const atRisk = { min: 30, max: 59 };
  const stable = { min: 60, max: 100 };

  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [profileSection, setProfileSection] = useState<number>(1);
  const [patientFullInfo, setPatientFullInfo] = useState<RemoteData<Patient>>(notAsked());
  const [showDischargeClientModal, setShowDischargeClientModal] = useState<boolean>(false);

  const displayToast = (message: string, type: AppearanceTypes) =>
    addToast(message, { appearance: type, autoDismiss: true });

  const getPatientFullInfoAction = async (patientId: string): Promise<void> => {
    setPatientFullInfo(loading());
    const res = await getPatientDetails(patientId);
    setPatientFullInfo(res);
  };

  const getLastNoteAction = async (patientId: string): Promise<RemoteData<NotesResult>> => {
    setNote(loading());
    const res = await getLastNote(patientId);
    const note = done(res.data);
    setNote(note);
    return note;
  };

  const onViewLatestNote = async (): Promise<void> => {
    const note = await getLastNoteAction(firebasePatient.id);
    if (note.status === 'Done' && note.data.size === 0) {
      displayToast(`${firebasePatient.name.first} ${firebasePatient.name.last} does not have a Note yet.`, 'error');
    } else {
      setSelectedPatientAction({ selectedPatient: firebasePatient, selectedAction: 'Notes' });
    }
  };

  const onViewProfile = (profileSection: number) => {
    setShowProfileModal(true);
    setProfileSection(profileSection);
  };

  const dischargePatientAction = async (patientId: string, body: DischargePatientRequest) => {
    const res = await dischargePatient(patientId, body);
    if (res.status === 'Done') {
      await getPatientsAction();
      displayToast('Client discharged successfully', 'success');
    } else {
      displayToast(`Error: ${res.errorApi.message}`, 'error');
    }
  };

  const getNameAdapted = (first: string, last: string): JSX.Element => {
    const name = `${first} ${last}`;
    let firstPart = '';
    let lastPart = '';
    if (name.length > 18) {
      firstPart = `${name.slice(0, 18)}-`;
      lastPart = name.slice(18, name.length);
    }
    if (!firstPart) {
      return (
        <span
          style={{
            fontSize: 16,
            lineHeight: '22px',
            fontWeight: 'normal',
            width: '100%',
            marginTop: 16,
          }}
        >
          {name}
        </span>
      );
    }
    return (
      <div
        style={{
          fontSize: 16,
          lineHeight: '22px',
          fontWeight: 'normal',
          width: 163,
          marginTop: 10,
          marginBottom: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span>{firstPart}</span>
        <span
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {lastPart}
        </span>
      </div>
    );
  };

  const getPermissionsStatus = (): JSX.Element => {
    return firebasePatient?.phonePermissions && isPermissionsActive(firebasePatient.phonePermissions) ? (
      <ApprovedIcon width={15} height={15} />
    ) : (
      <ApprovedIcon width={15} height={15} color='#EFBB55' />
    );
  };

  const getHealthBandStatus = (): string => {
    if (patientFullInfo.status === 'Done') {
      const { deviceName1, deviceName2 } = patientFullInfo.data.devicesIssued!;
      if (!deviceName1 && !deviceName2) {
        return 'Wristband not assigned';
      }
    }
    return `Wristband ${firebasePatient.isConnected ? 'Worn' : 'Not Worn'}`;
  };

  const getTitle = () => {
    if (['undefined', undefined, 'UNDEFINED'].includes(firebasePatient?.insight?.title)) {
      return '--';
    } else {
      return firebasePatient?.insight?.title;
    }
  }

  useEffect(() => {
    if (firebasePatient) {
      patientFullInfo.status === 'Not Asked' && getPatientFullInfoAction(firebasePatient.id);
    }
  }, [firebasePatient]);

  return (
    <div
      style={{
        height: isCollapsed ? 72 : 320,
        transition: 'height .25s ease-out',
        width: 267,
        marginRight: 18,
        boxShadow: '0px 2px 6px #E5E5E5',
        order: firebasePatient.viviScore,
        marginTop: 18,
      }}
    >
      <div
        style={{
          height: 72,
          background: statusColor[status],
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 14.52,
        }}
      >
        <ProfileImage
          image={firebasePatient.image?.url}
          gender={firebasePatient.gender}
          size={48}
          fallbackImageType='white'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            height: '100%',
            width: 160,
            color: '#FFFFFF',
            marginLeft: 10,
            justifyContent: 'flex-start',
          }}
        >
          {getNameAdapted(firebasePatient?.name?.first, firebasePatient?.name?.last)}
          <span
            style={{
              fontSize: 12,
              lineHeight: '13px',
              fontStyle: 'regular',
              marginBottom: 17,
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'uppercase',
            }}
          >
            {EPISODE_OF_CARE[firebasePatient?.episodeOfCare]}
          </span>
        </div>
        <svg
          style={{ marginLeft: 8, cursor: 'pointer' }}
          width='9'
          height='15'
          viewBox='0 0 9 15'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={(): void => history.push(`/patients/${firebasePatient.id}/details`)}
        >
          <path d='M0.999999 14L7.5 7.5L0.999999 1' stroke='white' strokeWidth='2' strokeLinecap='round' />
        </svg>
      </div>
      <div
        style={{
          background: '#FFFFFF',
          height: isCollapsed ? 0 : 248,
          transition: 'height .25s ease-out',
        }}
      >
        <div
          style={{
            display: isCollapsed ? 'none' : 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: 27,
              width: '100%',
              borderBottomColor: '#F5F6FA',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              paddingLeft: 20,
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'flex',
            }}
          >
            {firebasePatient.isConnected ? <Signal /> : <NoSignal />}
            <span
              style={{
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontSize: 10,
                lineHeight: '12px',
                color: '#323335',
                marginLeft: 6.17,
              }}
            >
              {getHealthBandStatus()}
            </span>
            <span
              style={{
                display: 'flex',
                marginLeft: 70,
                marginRight: 10,
              }}
            >
              {getPermissionsStatus()}
            </span>
            <DropDown contentStyle='standard' hideDropdownOnClick>
              <More style={{ cursor: 'pointer' }} />
              <>
                <ul>
                  {firebasePatient?.insight?.hasAlert && !firebasePatient?.insight.clearedAt && (
                    <li
                      onClick={() =>
                        setSelectedPatientAction({
                          selectedPatient: firebasePatient,
                          selectedAction: 'Insight',
                        })
                      }
                    >
                      Clear Alert
                    </li>
                  )}
                  <li onClick={() => onViewLatestNote()}>View Latest Note</li>
                  <li onClick={() => onViewProfile(1)}>View Profile</li>
                  <li onClick={() => onViewProfile(7)}>Permissions State {getPermissionsStatus()}</li>
                </ul>
              </>
            </DropDown>
          </div>
          {/* END OF TOP ROW / START OF SECOND */}
          <div
            style={{
              width: '100%',
              borderBottomColor: '#F5F6FA',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              height: 165,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '100%',
                height: '100%',
                paddingTop: 18,
              }}
            >
              <span style={{ marginLeft: 18 }}>
                {!firebasePatient?.insight && <div style={{ width: 23, height: 20 }}>&nbsp;</div>}
                {!!firebasePatient?.insight &&
                  (firebasePatient?.insight?.positive ? (
                    <InsightGood height='20' width='20' />
                  ) : (
                    <InsightLogo
                      color='#323335'
                      width='23'
                      height='20'
                      flashColor={statusColor[status]}
                      alert={
                        !!firebasePatient?.insight?.hasAlert &&
                        !firebasePatient?.insight?.clearedAt &&
                        !firebasePatient?.insight?.clearedAt
                      }
                    />
                  ))}
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 14,
                  lineHeight: '16px',
                  color: '#323335',
                  width: 106,
                  marginLeft: 10,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 3,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {getTitle()}
              </span>

              <div
                style={{
                  marginLeft: 14,
                  width: 60,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: 30,
                    color: '#323335',
                  }}
                >
                  {firebasePatient.hr ? firebasePatient.hr : '--'}
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                >
                  <span
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: 10,
                      lineHeight: '12px',
                      color: '#323335',
                    }}
                  >
                    BPM
                  </span>
                  <Heart color={HEX_COLORS.CRITICAL} />
                  <span
                    style={{
                      fontSize: 10,
                      color: '#A3A6B3',
                      width: 50,
                      position: 'absolute',
                      top: 20,
                      right: 0,
                    }}
                  >
                    Heart Rate
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 8,
                width: '100%',
              }}
            >
              <div
                style={{
                  alignSelf: 'flex-start',
                  marginLeft: 18,
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 18,
                  lineHeight: '21px',
                }}
              >
                {firebasePatient.viviScore}
              </div>
              <div
                style={{
                  marginRight: 18,
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 10,
                  lineHeight: '12px',
                  color: '#A3A6B3',
                }}
              >
                ViviHealth
              </div>
            </div>
            <div
              style={{
                borderRadius: 100,
                marginBottom: 18,
                width: 231,
                height: 6,
                position: 'relative',
              }}
            >
              <div
                style={{
                  backgroundColor: '#F5F6FA',
                  width: '100%',
                  height: '100%',
                  borderRadius: 100,
                }}
              />
              <div
                style={{
                  background: caseOf()
                    .case(
                      () => firebasePatient.viviScore < VIVIHEALTH_SCORE_RANGE.AT_RISK.MIN,
                      'linear-gradient(90deg, #D18195 0%, #EA6969 100%)',
                    )
                    .case(
                      () => firebasePatient.viviScore < VIVIHEALTH_SCORE_RANGE.STABLE.MIN,
                      'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)',
                    )
                    .case(
                      () => firebasePatient.viviScore >= VIVIHEALTH_SCORE_RANGE.STABLE.MIN,
                      'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)',
                    )
                    .eval(firebasePatient.viviScore),
                  borderRadius: 100,
                  height: '100%',
                  width: `${firebasePatient.viviScore}%`,
                  position: 'absolute',
                  top: 0,
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 8,
                width: '100%',
              }}
            >
              <div
                style={{
                  alignSelf: 'flex-start',
                  marginLeft: 18,
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 18,
                  lineHeight: '21px',
                }}
              >
                {firebasePatient.engagement}
              </div>
              <div
                style={{
                  marginRight: 18,
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 10,
                  lineHeight: '12px',
                  color: '#A3A6B3',
                }}
              >
                Engagement
              </div>
            </div>
            {/* BOTTOM BAR */}
            <div
              style={{
                borderRadius: 100,
                marginBottom: 18,
                width: 231,
                height: 6,
                position: 'relative',
              }}
            >
              <div
                style={{
                  backgroundColor: '#F5F6FA',
                  width: '100%',
                  height: '100%',
                  borderRadius: 100,
                }}
              />

              <div
                style={{
                  background: caseOf()
                    .case(
                      () => firebasePatient.engagement < atRisk.min,
                      'linear-gradient(90deg, #D18195 0%, #EA6969 100%)',
                    )
                    .case(
                      () => firebasePatient.engagement < stable.min,
                      'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)',
                    )
                    .case(
                      () => firebasePatient.engagement >= stable.min && firebasePatient.engagement <= stable.max,
                      'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)',
                    )
                    .eval(firebasePatient.engagement),
                  borderRadius: 100,
                  height: '100%',
                  width: `${firebasePatient.engagement}%`,
                  position: 'absolute',
                  top: 0,
                }}
              />
            </div>
          </div>
          {/* END OF SECOND ROW / START OF THIRD */}
          <div style={{ position: 'relative' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 10,
                  lineHeight: '12px',
                  color: '#A3A6B3',
                  marginLeft: 18,
                  marginTop: 12,
                  width: 93,
                }}
              >
                Last View:{' '}
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 10,
                  lineHeight: '12px',
                  color: '#A3A6B3',
                  marginTop: 12,
                  marginLeft: 58,
                }}
              >
                Viewed By:{' '}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 8,
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 10,
                  lineHeight: '12px',
                  color: '#323335',
                  marginLeft: 18,
                  width: 101,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {firebasePatient?.lastViewed
                  ? moment.utc(firebasePatient?.lastViewed?.timestamp).local().format('MM/DD/YY hh:mm A')
                  : '--'}
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 10,
                  lineHeight: '12px',
                  color: '#323335',
                  marginLeft: 50,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: 97,
                }}
              >
                {firebasePatient?.lastViewed?.practitioner?.name?.first}{' '}
                {firebasePatient?.lastViewed?.practitioner?.name?.last}
                {!firebasePatient?.lastViewed?.practitioner?.name?.first &&
                  !firebasePatient?.lastViewed?.practitioner?.name?.last && <span>--</span>}
              </span>
            </div>
          </div>
        </div>
      </div>
      {firebasePatient && showProfileModal && !showDischargeClientModal && (
        <UpsertClientContainer
          setShow={setShowProfileModal}
          editMode={true}
          selectedPatient={patientFullInfo}
          organization={firebasePatient?.organization}
          firebasePatient={firebasePatient}
          refetchPatient={getPatientsAction}
          setShowDischargeClientModal={setShowDischargeClientModal}
          initialTab={profileSection}
        />
      )}
      {showDischargeClientModal && patientFullInfo.status === 'Done' && (
        <DischargeClientConfirmation
          setShow={setShowDischargeClientModal}
          client={patientFullInfo.data}
          dischargePatientAction={dischargePatientAction}
        />
      )}
    </div>
  );
};
