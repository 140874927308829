import { FieldAttributes, useField } from 'formik';
import React from 'react';
import EditIcon from '../../../assets/icons/icn-edit-pen.svg';
import { Tooltip } from '../tooltip';
import styles from './form.module.scss';

type Props = {
  id?: number;
  name: string;
  label: string;
  disabled?: boolean;
  type?: string;
  size?: string;
  placeholder?: string;
  labelStrong?: boolean;
  showSuccessStyles?: boolean;
  showErrorStyles?: boolean;
  min?: number;
  max?: number;
  openEditEmailModal?: () => void;
  onChangeCustom?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
} & FieldAttributes<any>;

const TextInput: React.FC<Props> = props => {
  const [field, meta] = useField(props);
  const fieldSize =
    props.size === 'small'
      ? styles.field__small
      : props.size === 'smallNoLabel'
      ? styles.field__smallNoLabel
      : props.size === 'big'
      ? styles.field__big
      : styles.field;
  return (
    <div className={fieldSize}>
      {!!props.label && (
        <label style={props.labelStrong ? { fontWeight: 700 } : {}} htmlFor={props.id || props.name}>
          {props.label}
        </label>
      )}
      {props.onChangeCustom ? (
        <input
          className={
            (meta.touched && meta.error) || props.showErrorStyles
              ? styles.inputError
              : props.disabled
              ? styles.inputDisabled
              : props.showSuccessStyles
              ? styles.inputSuccess
              : ''
          }
          {...field}
          name={props.name}
          type={props.type}
          size={props.size}
          onChange={props.onChangeCustom}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={props.value}
          style={props.showErrorStyles ? { color: '#ed6f6a' } : {}}
        />
      ) : (
        <input
          className={
            (meta.touched && meta.error) || props.showErrorStyles
              ? styles.inputError
              : props.disabled
              ? styles.inputDisabled
              : props.showSuccessStyles
              ? styles.inputSuccess
              : ''
          }
          {...field}
          name={props.name}
          type={props.type}
          size={props.size}
          disabled={props.disabled}
          placeholder={props.placeholder}
          style={props.showErrorStyles ? { color: '#ed6f6a' } : {}}
          min={props.min}
          max={props.max}
        />
      )}

      {props.type === 'editableEmail' && (
        <img
          className={styles.editableEmailIcon}
          src={EditIcon}
          onClick={() => (props.openEditEmailModal ? props.openEditEmailModal() : {})}
        />
      )}
      {props.type === 'emailTooltip' && (
        <div className={styles.helperAnchor}>
          <Tooltip baseStyles={styles.emailTooltip} type='left'>
            <p>
              This email address can no longer be changed because the Client has already set up their ViviCompanion
              Account.
            </p>
          </Tooltip>
        </div>
      )}
      {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default TextInput;
