import { QuestionItem } from 'graphql/codegen/schemas';
import * as React from 'react';
import { caseOf } from 'utils/case-of';
import styles from './view-survey-modal-question.module.scss';

interface Props {
  question: QuestionItem;
  index: number;
}
const ViewSurveyModalQuestion: React.FC<Props> = caseOf<Props>()
  .case(
    ({ question }) => ['normal', 'long_answers'].indexOf(question.designType || '') >= 0,
    ({ question, index }: Props) => (
      <>
        <div className={styles.questionCell}>
          <div>
            {index}.{question.text}
          </div>
          <div>{question.subtext}</div>
          <div className={styles.questionAnswers}>
            {question.availableAnswers.map(a => (
              <div key={a.id}>{a.text}</div>
            ))}
          </div>
        </div>
        <div className={styles.questionCell}>
          <span className={styles.scoreLabel}>Score:</span>{' '}
          {typeof question.answer?.value === 'number' ? question.answer?.value : '-'}
        </div>
        <div className={styles.questionCell}>
          <div className={styles.selectedAnswer}>
            {question.availableAnswers.find(a => question.answer?.id === a.id)?.text}
          </div>
        </div>
      </>
    ),
  )
  .case(
    ({ question }) => question.designType === 'multiple_answers',
    ({ question, index }: Props) => (
      <>
        <div className={`${styles.questionCell} ${styles.mainQuestion}`}>
          <div>
            {index}.{question.text}
          </div>
        </div>
        <div className={styles.questionCell} />
        <div className={styles.questionCell} />

        {question.subQuestions?.map((q, subIdx) => (
          <>
            <div key={`text-${q.id}`} className={styles.subQuestionCell}>
              {index}.{subIdx + 1}.{q.text}
              <div className={styles.questionAnswers}>
                {q.availableAnswers.map(a => (
                  <div key={a.id}>{a.text}</div>
                ))}
              </div>
            </div>
            <div key={`score-${q.id}`} className={styles.subQuestionCell}>
              <span className={styles.scoreLabel}>Score:</span>
              {typeof q.answer?.value === 'number' ? q.answer?.value : '-'}
            </div>
            <div key={`answer-${q.id}`} className={styles.subQuestionCell}>
              <div className={styles.selectedAnswer}>{q.availableAnswers.find(a => q.answer?.id === a.id)?.text}</div>
            </div>
          </>
        ))}
      </>
    ),
  )
  .defaultCase(() => <div>Not recognized question layout</div>)
  .compileSingle();

export default ViewSurveyModalQuestion;
