import React, { useMemo, Dispatch, SetStateAction } from 'react';
import Table from '../common/table/table';
import { Column } from 'react-table';
import { Patient, Patients } from '../../interfaces/patient';
import styles from '../admin-clients/admin-clients.module.scss';
import { RemoteData } from '../../utils/remote-data';
import MaleAvatarIcon from '../../assets/icons/icn-inverted-male.svg';
import FemaleAvatarIcon from '../../assets/icons/icn-inverted-female.svg';
import moment from 'moment-timezone';
import { displayFullName } from '../../utils/data-parser';

type Props = {
  patients: RemoteData<Patients>;
  setShow: Dispatch<SetStateAction<boolean>>;
  setPatient: Dispatch<SetStateAction<Patient | undefined>>;
};

const ActiveClientsTable: React.FC<Props> = ({ patients, setShow, setPatient }) => {
  const columns: Column<Patient>[] = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: (originalRow): object => {
          return {
            nameDisplay: displayFullName(originalRow.name),
            profilePicture: originalRow.image ? originalRow.image.base64 : null,
          };
        },
        width: 200,
        Cell: ({ cell: { value, row } }) => (
          <div className={styles.nameContainer}>
            {value.profilePicture ? (
              <img className={styles.profilePicture} src={`data:image/jpeg;base64,${value.profilePicture}`} />
            ) : (
              <img
                className={styles.picturePlaceholder}
                src={row.original.gender === 'FEMALE' ? FemaleAvatarIcon : MaleAvatarIcon}
              />
            )}
            <span className={styles.overflowWrapped}>{value.nameDisplay}</span>
          </div>
        ),
      },
      {
        Header: 'ASSIGNMENTS',
        width: 250,
        accessor: originalRow => {
          return originalRow.careTeam.careTeamMembers.map((tm, idx) => displayFullName(tm.name)).join(', ');
        },
        Cell: ({ cell: { value } }) => <div className={styles.dataFullDisplay}>{value}</div>,
      },
      {
        Header: 'ADMISSION DATE',
        width: 200,
        accessor: 'dateOfAdmission',
        Cell: ({ cell: { value } }) => moment.utc(value).local().format('lll Z'),
      },
      {
        Header: 'STATUS',
        accessor: originalRow =>
          originalRow.status
            ? originalRow.status.charAt(0) + originalRow.status.slice(1).toLowerCase().split('_').join(' ')
            : '',
        width: 120,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ cell }) => (
          <span className={styles.action} onClick={() => onRemovePatient(cell.row.original)}>
            Remove from practitioner's care
          </span>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getHeaderProps = column => {
    return {
      className: styles.th__modal,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getTableProps = () => {
    return {
      className: patients.status === 'Done' && !patients.data.size ? styles.noContent : styles.table,
      style: {
        width: '100%',
        boxShadow: 'none',
      },
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '344px',
        marginRight: '32px',
      },
    };
  };

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: styles.adminClientsTr,
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: `${styles.td} ${styles.tdInline}`,
    };
  };

  const onRemovePatient = (patient: Patient) => {
    setPatient(patient);
  };

  return (
    <div>
      <Table<Patient>
        columns={columns}
        data={patients.status === 'Done' ? patients.data.values : []}
        isLoading={patients.status === 'Loading'}
        error={patients.status === 'Failed'}
        getTableProps={() => getTableProps()}
        getRowProps={row => getRowProps(row)}
        getHeaderProps={column => getHeaderProps(column)}
        getCellProps={cell => getCellProps(cell)}
        getTableBodyProps={() => getTableBodyProps()}
        defaultColumn={{ width: 180, minWidth: 100 }}
        initialState={defaultSorting}
        isModal={true}
      />
      <button className={styles.closeBtnModal} onClick={() => setShow(false)}>
        Close
      </button>
    </div>
  );
};

export default ActiveClientsTable;
