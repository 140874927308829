import React, { FunctionComponent } from 'react';
import styles from './confirm-update-geofence.module.scss';
import { Geofence } from '../../../../interfaces/geofence';

type Props = {
  selectedGeofence: Geofence;
  closeModal: () => void;
};

const ConfirmUpdateGeofence: FunctionComponent<Props> = ({ selectedGeofence, closeModal }) => {
  return (
    <section>
      <div className={styles.modalHeader}>
        <h4>Confirm Edit Geofence</h4>
      </div>
      <div className={styles.confirmContainer}>
        <div className={styles.mainContent}>
          <p>
            {' '}
            <span className={styles.geofenceNameText}>{selectedGeofence.name}</span>{' '}
            {selectedGeofence.geofencePoint.address}
          </p>
          <p className={styles.mainText}>
            Editing this geofence will cause future geofence violations to be associated with the new Type, Name, and/or
            Address. Any prior geofence violations at the previous location will be retained in the list view history.
          </p>
          <h6 className={styles.smallText}>Note: this cannot be undone.</h6>
        </div>
        <div className={`${styles.rowButtons}`}>
          <button className={styles.cancelButton} onClick={() => closeModal()}>
            Cancel
          </button>
          <button className={styles.saveButton} type='submit'>
            Confirm Edit
          </button>
        </div>
      </div>
    </section>
  );
};

export default ConfirmUpdateGeofence;
