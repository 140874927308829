import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from '../../upsert-client.module.scss';
import { ReactComponent as ContactSVG } from '../../../../assets/icons/permissions/icn_contact.svg';
import PermissionCard from './permission-card';
import { CameraIcon } from '../../../../assets/icons/permissions/icn_camera';
import BluetoothSVG from '../../../../assets/icons/permissions/icn_bluetooth.svg';
import MotionFitnessSVG from '../../../../assets/icons/permissions/icn_motion_fitness.svg';
import { ReactComponent as LocationSVG } from '../../../../assets/icons/permissions/icn_location.svg';
import { NotificationsIcon } from '../../../../assets/icons/permissions/icn_notifications';
import { getPhonePermissions } from '../../../../services/api/firestore';
import firebase from 'firebase';
import { PhonePermissions } from '../../../../interfaces/patient';
import { loading, notAsked, RemoteData } from '../../../../utils/remote-data';
import { Loading } from '../../../common/loading';
import { defaultPhonePermissions } from '../../../../utils/constants';

type Props = {
  patientId: string;
};

const PermissionSettings: FunctionComponent<Props> = ({ patientId }) => {
  const [patientPhonePermissions, setPatientPhonePermissions] = useState<RemoteData<PhonePermissions>>(notAsked());
  useEffect(() => {
    setPatientPhonePermissions(loading());
    let unsubscribeCollection: () => void = () => {};
    try {
      unsubscribeCollection = getPhonePermissions(patientId, {
        next: (document: firebase.firestore.DocumentSnapshot) => {
          const phonePermissions = (document?.data() ?? defaultPhonePermissions) as PhonePermissions;
          setPatientPhonePermissions({ data: phonePermissions, status: 'Done' });
        },
      });
    } catch (e) {
      setPatientPhonePermissions({ data: defaultPhonePermissions, status: 'Done' });
    }
    return (): void => {
      unsubscribeCollection();
    };
  }, []);

  return (
    <>
      {patientPhonePermissions.status === 'Done' ? (
        <div className={styles.body__column}>
          <p className={styles.title}>
            Below are the current permission's status for your client ViviCompanion mobile app. If your client has not
            enabled all permissions , certain data and experiences will be unavailable
          </p>
          <div className={styles.cardContainer}>
            <PermissionCard
              title='Contact'
              subtitle='Phone Contacts are available to be automatically added to your circle of support.'
              permissionType={patientPhonePermissions.data.permissions.isContactsApproved}
              image={ContactSVG}
            />
            <PermissionCard
              title='Notifications'
              subtitle='Allows push notifications so the app can give you supportive cues and helpful information.'
              permissionType={patientPhonePermissions.data.permissions.isPushApproved}
              image={NotificationsIcon}
            />
            <PermissionCard
              title='Camera'
              subtitle='Camera access allows photos to be taken for profile photos, BAC, and more.'
              permissionType={patientPhonePermissions.data.permissions.isCameraApproved}
              image={CameraIcon}
            />
            <PermissionCard
              title='Location'
              subtitle='Turn on to allow collection, storage, and use of your device’s precise location and GPS for accountability.'
              permissionType={patientPhonePermissions.data.permissions.locationPermission}
              image={LocationSVG}
            />
            <PermissionCard
              title='Bluetooth'
              subtitle='Bluetooth allows easy access to devices like straps, patches, and BAC monitors.'
              permissionType={patientPhonePermissions.data.permissions.isBluetoothApproved}
              image={() => <img src={BluetoothSVG} alt='bluetooth' />}
            />
            <PermissionCard
              title='Motion & Fitness'
              subtitle='Allow access to your device sensors to determine motion and physical activity.'
              permissionType={patientPhonePermissions.data.permissions.isMotionApproved}
              image={() => <img src={MotionFitnessSVG} alt='motion&fitness' />}
            />
          </div>
        </div>
      ) : (
        <div className={styles.body__column}>
          <Loading />
        </div>
      )}
    </>
  );
};

export default PermissionSettings;
