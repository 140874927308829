import React from 'react';
import styles from './chart.module.scss';
import { displayDateTimeFormat } from '../../../utils/data-parser';
import { getPatientTimezone } from '../../../contexts/PatientContext';

type Props = {
  date?: number;
  dates?: number[];
  title: string;
  value: number;
  name?: string;
  reverse: boolean;
  payload?: any;
};

const minutesToHoursFormat = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  let string = "";
  if (hours > 0) {
    string += ` ${hours} hours`;
  }
  if (minutes) {
    string += ` ${minutes} minutes`;
  }
  return string;
}

export const ChartToolTip: React.FC<Props> = ({ date, dates, title, value, reverse, name, payload }): JSX.Element => {
  const timezone = getPatientTimezone();
  const getText = () => {
    switch (title) {
      case 'FAIL_BAC':
        return `BAC Test Failed. Alcohol=${payload?.level}`
      case 'GEOFENCE':
        return `${name} for ${minutesToHoursFormat(payload.timeSpentMinutes)}`
      case 'SPECIAL_GEOFENCE':
        return `${name} for ${minutesToHoursFormat(payload.timeSpentMinutes)}`
      default:
        return `${title}: ${name ?? value}`;
    }
  }
  return (
    <>
      <div className={`${reverse ? styles.chartToolTipReverse : styles.chartToolTip} ${dates ? styles.hasDates : ''}`}>
        {dates ? (
          <div className={styles.chartTooltipContentDates}>
            <span>{`START: ${displayDateTimeFormat(dates[0], timezone)}`}</span>
            <span>{`END: ${displayDateTimeFormat(dates[1], timezone)}`}</span>
          </div>
        ) : (
          <div className={styles.chartTooltipContent}>
            <span>{displayDateTimeFormat(date!, timezone)}</span>
          </div>
        )}
        <div>
          <span className={styles.chartToolTipTitle}>
            {getText()}
          </span>
        </div>
      </div>
    </>
  );
};
