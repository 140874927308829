import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './score-range.module.scss';

type Props = {
  range: string;
  elemStyles: CSSProperties;
};

const ScoreRange: FunctionComponent<Props> = ({ elemStyles, range, children }) => {
  return (
    <span className={styles.range} style={elemStyles}>
      <span className={styles.rangeValue}>{range}</span>
      <span className={styles.rangeDescription}>{children}</span>
    </span>
  );
};

export default ScoreRange;
