import React, { FunctionComponent, Dispatch, SetStateAction, useContext } from 'react';
import styles from './insight.module.scss';
import moment from 'moment-timezone';
import { Insight } from '../../interfaces/insight';
import { Practitioner } from '../../interfaces/practitioner';
import { RemoteData } from '../../utils/remote-data';
import { insightStringReplacement } from '../../utils/data-parser';
import { getPatientTimezone, PatientContext } from '../../contexts/PatientContext';
import { ReactComponent as InsightCreativityLogo } from '../../assets/icons/icn_creativity.svg';
import { Tooltip } from '../common/tooltip';

type Props = {
  insight: Insight;
  practitioner: RemoteData<Practitioner>;
  setClearInsight: Dispatch<SetStateAction<boolean>>;
};
export const InsightPanel: FunctionComponent<Props> = ({ insight, practitioner, setClearInsight }) => {
  const clientTimezone = getPatientTimezone();
  const { patient } = useContext(PatientContext);
  const clearAlert = insight?.hasAlert && !insight?.clearedAt && !insight?.clearedBy;

  return (
    <div className={`${styles.insightContainer} ${styles[insight?.positive ? 'stable' : 'critical']}`}>
      <div className={styles.top}>
        <span className={styles.panelTitle}>INSIGHT</span>
        <Tooltip baseStyles={styles.infoTooltip} type='left' background='#F5F6FA'>
          <div className={styles.hoverText}>
            Insights provide important information about this client. The corresponding colored line in the chart below
            shows when this insight occurred.
          </div>
        </Tooltip>
      </div>
      {!insight && (
        <>
          <div className={styles.noInsightMiddle}>
            <InsightCreativityLogo />
            <p>No insights</p>
            <span>This patient has no new insights, please check back later. </span>
          </div>
          <div className={styles.noInsightBottom}>
            <button>Clear Alert</button>
          </div>
        </>
      )}
      {!!insight && (
        <>
          <div className={styles.middle}>
            <span className={styles.title}>{insight?.title}</span>
            <span className={styles.description}>
              {!!insight &&
                !!patient &&
                insightStringReplacement(insight.description, insight, patient, clientTimezone)}
            </span>
            <span className={styles.readMore} onClick={() => setClearInsight(true)}>
              READ MORE
            </span>
          </div>
          <div className={styles.bottom}>
            <div className={styles.left}>
              <div className={styles.dateSection}>
                <span className={styles.dateLabel}>Insight Generated:</span>
                <span className={styles.dateValue}>
                  {!!insight && moment.utc(insight?.generatedTimestamp).tz(clientTimezone).format('LLL z')}
                </span>
              </div>
              <div className={styles.actionText}>
                {clearAlert && (
                  <>
                    <span className={styles.actionLabelClear}>Action Required: </span>
                    <span className={styles.actionValueClear}>Clear Alert</span>
                  </>
                )}
                {!!insight?.clearedAt && !!insight?.clearedBy && practitioner.status === 'Done' && (
                  <>
                    <span className={styles.actionLabelCleared}>Cleared By: </span>
                    <span>
                      {practitioner.data.name.first} {practitioner.data.name.last} on{' '}
                      {moment.utc(insight.clearedAt).local().format('MM/DD/YYYY hh:mm a')}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className={styles.right}>
              {clearAlert && <button onClick={() => setClearInsight(true)}>Clear Alert</button>}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
