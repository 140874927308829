import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import styles from './client-stats-container.module.scss';
import { useClientStatsReportQuery } from '../../graphql/codegen/hooks';
import { PtsMetric } from '../../graphql/codegen/schemas';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { EPISODE_OF_CARE } from '../../utils/constants';
import { Loading } from '../common/loading';
import { Checkbox, TextInput } from 'components/common/form';
import { Form, Formik } from 'formik';
import { getOrganizationEmailAlert, updateOrganizationEmailAlert } from 'services/api/organization';
import { useToasts } from 'react-toast-notifications';

type ReportRow = {
    name: string;
    todayCount: number;
    actualWeekCount: number;
    lastWeekCount: number;
    actualMonthCount: number;
    lastMonthCount: number;
};

type PREFERENCE = {
    alertCategory: "INPATIENT" | "OUTPATIENT_AFTERCARE",
    emailAddress: string,
    enabled: boolean,
    insights: Array<{ insightType: "DEVICE_WORN" | "HEART_RATE" | "STRESS" | "SLEEP" | "MOOD" | "EXERCISE" | "STEPS" | "MEETING_ATTENDANCE" | "VIVIHEALTH_SCORE" | "ENGAGEMENT_SCORE" | "RELAPSE_RISK_SCORE" | "ADHERENCE" | "CIRCLE_INTERACTION", positiveEvaluation: boolean, negativeEvaluation: boolean }>
    bacTestsInsights: Array<{
        bacTestResult: "MISSED" | "FAILED" | "PASSED",
        enabled: boolean
    }>,
    geofenceInsights: Array<{ geofenceAlertType: "NON_COMPLIANCE" | "SPECIAL", enabled: boolean }>
}

const INSIGHT_OPTIONS = [
    {
        label: 'Device Worn',
        insightType: 'DEVICE_WORN',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Heart Rate',
        insightType: 'HEART_RATE',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Stress',
        insightType: 'STRESS',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Sleep',
        insightType: 'SLEEP',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Mood',
        insightType: 'MOOD',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Exercise',
        insightType: 'EXERCISE',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Steps',
        insightType: 'STEPS',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Meeting Attendance',
        insightType: 'MEETING_ATTENDANCE',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'ViviHealth Score',
        insightType: 'VIVIHEALTH_SCORE',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Engagement Score',
        insightType: 'ENGAGEMENT_SCORE',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Relapse Risk Score',
        insightType: 'RELAPSE_RISK_SCORE',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Adherence',
        insightType: 'ADHERENCE',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
    {
        label: 'Circle Interaction',
        insightType: 'CIRCLE_INTERACTION',
        positiveEvaluation: false,
        negativeEvaluation: false
    },
]

const AdminPreferences = () => {
    const { addToast } = useToasts();
    const { organization, loading: orgLoading } = useContext(OrganizationContext);
    const [selectedTab, setSelectedTab] = useState<"email" | "surveys">("email");
    const [subTab, setSubTab] = useState<"INPATIENT" | "OUTPATIENT_AFTERCARE">("INPATIENT");
    const [formValues, setFormValues] = useState({
        emailAddress: '',
        enabled: false,
        insights: INSIGHT_OPTIONS.map((i) => { return { insightType: i.insightType, positiveEvaluation: false, negativeEvaluation: false } })
    } as PREFERENCE);

    useEffect(() => {
        // /organizations/{{ account_id }}/email-alert/{{ alertCategory }}

        getOrganizationEmailAlert(organization?.data.org?.id || '', subTab).then((res) => {
            if (res.status === 'Done') {
                setFormValues(res.data);
            }
        })
    },[subTab]);


    const [eocCountList, setEocCountList] = useState<ReportRow[]>([]);
    const [clientsIntakeList, setClientsIntakeList] = useState<{ value: number; label: string }[]>([]);
    const queryDates = useMemo(() => {
        return {
            startOfDay: moment().startOf('day').format(),
            endOfDay: moment().endOf('day').format(),
            startLastWeek: moment().subtract(14, 'd').format(),
            endLastWeek: moment().subtract(7, 'd').format(),
            startLastMonth: moment().subtract(1, 'month').format(),
            startOfMonth: moment().startOf('month').format(),
        };
    }, []);
    const org = organization?.data?.org;

    const { loading, data } = useClientStatsReportQuery({
        variables: {
            id: org?.id,
            metric: PtsMetric.RelapseRisk,
            orgReportGroups: {
                groups: [
                    { name: 'stable', higherBound: 24 },
                    { name: 'risk', lowerBound: 25, higherBound: 54 },
                    { name: 'critical', lowerBound: 55 },
                ],
            },
            ...queryDates,
        },
        skip: orgLoading || !org?.id,
        fetchPolicy: 'cache-and-network',
    });


    if (loading) {
        return (
            <div className={styles.container}>
                <Loading />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <span className={styles.title}>CLINIC PREFERENCES</span>
            <header>
                <nav className={styles.complianceNav}>
                    <ul className={styles.complianceTabs}>
                        <li className={selectedTab == 'email' ? `li active` : styles.li}>
                            Email Alerts
                        </li>
                        {/* <li className={selectedTab == 'surveys' ? `li active` : styles.li}>
                            Surveys
                        </li> */}
                    </ul>
                </nav>
            </header>
            {selectedTab === "email" && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <nav className={styles.complianceNav}>
                        <ul className={styles.complianceTabs}>
                            <li className={subTab === 'INPATIENT' ? `li active` : styles.li} onClick={() => setSubTab("INPATIENT")}>
                                Inpatient
                            </li>
                            <li className={subTab === 'OUTPATIENT_AFTERCARE' ? `li active` : styles.li} onClick={() => setSubTab("OUTPATIENT_AFTERCARE")}>
                                Outpatient/AfterCare
                            </li>
                        </ul>
                    </nav>
                    <div>
                        <Formik
                            initialValues={{
                                emailAddress: formValues.emailAddress
                            }}
                            // validationSchema={Yup.object({
                            //     email: Yup.string().email('Please enter a valid email address.').required(),
                            // })}
                            onSubmit={(values, { setSubmitting }) => {
                                updateOrganizationEmailAlert(organization?.data.org?.id || '', subTab, formValues).then((val) => {
                                    addToast(`Preferences Updated`, {
                                        appearance: 'success',
                                        autoDismiss: true,
                                      });
                                });
                            }}

                        >
                            {({ handleChange }) => (
                                <Form className={styles.form}>
                                    <div style={{
                                        marginTop: 21,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <TextInput
                                            name='emailAddress'
                                            size='big'
                                            placeholder='Email address'
                                            label="Email address to send notifications/alerts:"
                                            onChangeCustom={(c) => {
                                                setFormValues({...formValues, emailAddress: c.target.value})
                                            }}
                                            value={formValues.emailAddress}
                                        />
                                        <span style={{ marginLeft: 83, marginRight: 77 }}>
                                            <Checkbox name='enabled' field='enabled' handleOnChange={() => setFormValues({...formValues, enabled: true})} checked={formValues.enabled} />
                                        </span>

                                        <Checkbox name='disable' field='Disable' handleOnChange={() => setFormValues({...formValues, enabled: false})} checked={!formValues.enabled} />
                                    </div>

                                    <div style={{ marginTop: 32, fontSize: 14, fontWeight: 500, color: '#323335' }}>
                                        <span>Select notification/alert types to receive:</span>

                                        <div style={{ marginTop: 54 }}>
                                            <span>BAC Alert-Insights</span>
                                        </div>
                                        <div style={{ marginTop: 17, marginLeft: 20 }}>
                                            <span style={{ marginRight: 38 }}>
                                                <Checkbox name='FAILED' field='Failed BAC Test'
                                                    handleOnChange={() => {
                                                        const current = formValues.bacTestsInsights?.find((v) => v.bacTestResult === 'FAILED');
                                                        const currentValues = formValues.bacTestsInsights ? formValues.bacTestsInsights?.filter((v) => v.bacTestResult !== 'FAILED') : [];
                                                        setFormValues({
                                                            ...formValues, bacTestsInsights: [...currentValues, {
                                                                bacTestResult: 'FAILED',
                                                                enabled: current?.enabled ? false : true
                                                            }]
                                                        })
                                                    }}
                                                    checked={formValues.bacTestsInsights?.find((item) => item?.bacTestResult === 'FAILED')?.enabled}
                                                    
                                                    />
                                            </span>
                                            <span style={{ marginRight: 38 }}>
                                                <Checkbox name='PASSED' field='Passed BAC Test'
                                                    handleOnChange={() => {
                                                        const current = formValues.bacTestsInsights?.find((v) => v.bacTestResult === 'PASSED');
                                                        const currentValues = formValues.bacTestsInsights ? formValues.bacTestsInsights?.filter((v) => v.bacTestResult !== 'PASSED') : [];
                                                        setFormValues({
                                                            ...formValues, bacTestsInsights: [...currentValues, {
                                                                bacTestResult: 'PASSED',
                                                                enabled: current?.enabled ? false : true
                                                            }]
                                                        })
                                                    }}
                                                    checked={formValues.bacTestsInsights?.find((item) => item?.bacTestResult === 'PASSED')?.enabled}

                                                />
                                            </span>
                                            <span>
                                                <Checkbox name='MISSED' field='Missed BAC Test'
                                                    handleOnChange={() => {
                                                        const current = formValues.bacTestsInsights?.find((v) => v.bacTestResult === 'MISSED');
                                                        const currentValues = formValues.bacTestsInsights ? formValues.bacTestsInsights?.filter((v) => v.bacTestResult !== 'MISSED') : [];
                                                        setFormValues({
                                                            ...formValues, bacTestsInsights: [...currentValues, {
                                                                bacTestResult: 'MISSED',
                                                                enabled: current?.enabled ? false : true
                                                            }]
                                                        })
                                                    }}
                                                    checked={formValues.bacTestsInsights?.find((item) => item?.bacTestResult === 'MISSED')?.enabled}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 19 }}>
                                        <span>Geo-fence Alerts</span>
                                    </div>
                                    <div style={{ marginTop: 17, marginLeft: 20 }}>
                                        <span style={{ marginRight: 38 }}>
                                            <Checkbox name='NON_COMPLIANCE' field='Non-Compliance'
                                                handleChange={() => {
                                                    const current = formValues.geofenceInsights?.find((v) => v.geofenceAlertType === 'NON_COMPLIANCE');
                                                    const currentValues = formValues.geofenceInsights ? formValues.geofenceInsights?.filter((v) => v.geofenceAlertType !== 'NON_COMPLIANCE') : [];
                                                    setFormValues({
                                                        ...formValues, geofenceInsights: [...currentValues, {
                                                            geofenceAlertType: 'NON_COMPLIANCE',
                                                            enabled: current?.enabled ? false : true
                                                        }]
                                                    })
                                                }}
                                                checked={formValues.geofenceInsights?.find((item) => item?.geofenceAlertType === 'NON_COMPLIANCE')?.enabled}
                                            />
                                        </span>
                                        <span>
                                            <Checkbox name='SPECIAL' field='Special'
                                                handleChange={() => {
                                                    const current = formValues.geofenceInsights?.find((v) => v.geofenceAlertType === 'SPECIAL');
                                                    const currentValues = formValues.geofenceInsights ? formValues.geofenceInsights?.filter((v) => v.geofenceAlertType !== 'SPECIAL') : [];
                                                    setFormValues({
                                                        ...formValues, geofenceInsights: [...currentValues, {
                                                            geofenceAlertType: 'SPECIAL',
                                                            enabled: current?.enabled ? false : true
                                                        }]
                                                    })
                                                }}
                                                checked={formValues.geofenceInsights?.find((item) => item?.geofenceAlertType === 'SPECIAL')?.enabled}

                                            />
                                        </span>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: 700,
                                        marginTop: 46
                                    }}>
                                        <span style={{ fontSize: 16, color: '#323335' }}>
                                            Specific Alert-Insights
                                        </span>
                                        <span style={{ fontSize: 16, color: '#323335' }}>
                                            Positive Insights
                                        </span>
                                        <span style={{ fontSize: 16, color: '#323335' }}>
                                            Negative Insights
                                        </span>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: 700,
                                        marginTop: 46
                                    }}>
                                        <>
                                            <span style={{ width: 120, fontSize: 14, color: '#323335', fontWeight: 400 }}>
                                                All
                                            </span>
                                            <span>
                                                <Checkbox name='enableAll'
                                                      checked={formValues.insights.find((i) => !i.positiveEvaluation) ? false : true}
                                                    handleOnChange={(check) => {
                                                        // set all as positive checked
                                                        const insights = formValues.insights.map((i) => {
                                                            return {
                                                                ...i,
                                                                positiveEvaluation: check?.target?.checked
                                                            }
                                                        });
                                                        setFormValues({
                                                            ...formValues,
                                                            insights
                                                        });
                                                    }}
                                                />
                                            </span>
                                            <Checkbox name='disableAll'
                                                checked={formValues.insights.find((i) => !i.negativeEvaluation) ? false : true}
                                                handleOnChange={(check) => {
                                                    // set all as positive checked
                                                    const insights = formValues.insights.map((i) => {
                                                        return {
                                                            ...i,
                                                            negativeEvaluation: check?.target?.checked
                                                        }
                                                    });
                                                    setFormValues({
                                                        ...formValues,
                                                        insights
                                                    });
                                                }}
                                            />
                                        </>

                                    </div>
                                    {INSIGHT_OPTIONS.map((insight_option) => {
                                        return (
                                            <div key={insight_option.label} style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                width: 700,
                                                marginTop: 46
                                            }}>
                                                <>
                                                    <span style={{ width: 120, fontSize: 14, color: '#323335', fontWeight: 400 }}>
                                                        {insight_option.label}
                                                    </span>
                                                    <Checkbox name={`${insight_option.insightType}-positive`}
                                                        handleOnChange={() => {

                                                            setFormValues({
                                                                ...formValues,
                                                                insights: formValues.insights.map((i) => {
                                                                    if (i.insightType === insight_option.insightType) {
                                                                        return {
                                                                            ...i,
                                                                            positiveEvaluation: !i.positiveEvaluation
                                                                        }
                                                                    }
                                                                    return i
                                                                })
                                                            })
                                                        }}
                                                        checked={formValues.insights.find((i) => i.insightType === insight_option.insightType)?.positiveEvaluation}
                                                    />
                                                    <Checkbox name={`${insight_option.insightType}-negative`}
                                                        handleOnChange={() => {

                                                            setFormValues({
                                                                ...formValues,
                                                                insights: formValues.insights.map((i) => {
                                                                    if (i.insightType === insight_option.insightType) {
                                                                        return {
                                                                            ...i,
                                                                            negativeEvaluation: !i.negativeEvaluation
                                                                        }
                                                                    }
                                                                    return i
                                                                })
                                                            })
                                                        }}
                                                        checked={formValues.insights.find((i) => i.insightType === insight_option.insightType)?.negativeEvaluation}
                                                    />
                                                </>
                                            </div>
                                        )
                                    })}
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        marginTop: 20
                                    }}>
                                    <button className={styles.saveBtn}
                                        style={{minWidth: '115px', 
                                        height: '24px', 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        textDecoration: 'none',
                                        outline: 'none',
                                        borderRadius: '3px',
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                        cursor: 'pointer'
                                    }}
                                        type='submit'
                                    >
                                        Save
                                    </button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminPreferences;
