import { destroy, get, patch, post, put } from './base';
import { BacResult } from '../../interfaces/bac-result';
import { AxiosResponse } from 'axios';
import { BacSchedule } from '../../interfaces/bac-schedule';
import moment from 'moment-timezone';
import { done, failed, RemoteData } from '../../utils/remote-data';
import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';

export const getBacResultsByPatient = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<{ values: BacResult[] }>> => {
  try {
    const res = await get(`/bac-results?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getBacTestImage = async (
  patientId: string,
  bacImageId: string,
): Promise<AxiosResponse<{ base64: string }>> => {
  return await get(`/patients/${patientId}/bac-image/${bacImageId}`);
};

export const updateFaceMatch = async (
  patientId: string,
  bacResultId: number,
  faceMatchStatus: string,
): Promise<AxiosResponse<BacResult>> => {
  return await patch(`/bac-results/${bacResultId}`, {
    patientId,
    faceMatchStatus,
  });
};

export const getPatientBacSchedule = async (patientId: string): Promise<AxiosResponse<BacSchedule>> => {
  return await get(`/bac-schedule?patientId=${patientId}`);
};

export const createPatientBacSchedule = async (
  bacSchedule: Partial<BacSchedule>,
  patientId: string,
): Promise<AxiosResponse<BacSchedule>> => {
  return await post(`/bac-schedule`, {
    ...bacSchedule,
    endDate: moment(bacSchedule.endDate).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    patientId,
  });
};

export const updatePatientBacSchedule = async (
  bacSchedule: Partial<BacSchedule>,
  bacScheduleId: string,
  patientId: string,
): Promise<AxiosResponse<BacSchedule>> => {
  return await put(`/bac-schedule/${bacScheduleId}`, {
    ...bacSchedule,
    endDate: moment(bacSchedule.endDate).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    patientId,
  });
};

export const deletePatientBacSchedule = async (patientId: string): Promise<AxiosResponse<BacSchedule>> => {
  return await destroy(`/bac-schedule?patientId=${patientId}`);
};

export const createSingleBacTest = async (
  patientId: string,
  minutesFromNow: number,
): Promise<AxiosResponse<BacSchedule>> => {
  return post(`/bac-single-test`, { patientId, minutesFromNow });
};
