import React, { Dispatch, SetStateAction } from 'react';
import styles from './upsert-client.module.scss';

type Props = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
};

const ClientProfileNav: React.FC<Props> = ({ step, setStep }) => {
  return (
    <div className={styles.clientModalNav}>
      <span className={step === 1 ? styles.stepSelected : ''} onClick={() => setStep(1)}>
        Primary Info
      </span>
      <span className={step === 2 ? styles.stepSelected : ''} onClick={() => setStep(2)}>
        Emergency Contacts
      </span>
      <span className={step === 3 ? styles.stepSelected : ''} onClick={() => setStep(3)}>
        Contact Info
      </span>
      <span className={step === 4 ? styles.stepSelected : ''} onClick={() => setStep(4)}>
        Clinic Info
      </span>
      <span className={step === 5 ? styles.stepSelected : ''} onClick={() => setStep(5)}>
        Assigned Devices
      </span>
      <span className={step === 6 ? styles.stepSelected : ''} onClick={() => setStep(6)}>
        Supporters
      </span>
      <span className={step === 7 ? styles.stepSelected : ''} onClick={() => setStep(7)}>
        ViviCompanion Permissions
      </span>
      <span className={step === 8 ? styles.stepSelected : ''} onClick={() => setStep(8)}>
        Permissions History
      </span>
    </div>
  );
};

export default ClientProfileNav;
