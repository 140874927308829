import { gql } from '@apollo/client';

export const GET_DEMOGRAPHICS_REPORT = gql`
  query DemographicsReport($id: String, $date: LocalDate) {
    organizationReport(id: $id) {
      organizationId
      demographics(date: $date) {
        items {
          variable
          name
          count
          children {
            variable
            name
            count
            children {
              variable
              name
              count
            }
          }
        }
      }
    }
  }
`;
