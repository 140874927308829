import React, { useEffect, useContext, useState, FC } from 'react';
import { ComposedChart, XAxis, YAxis, CartesianGrid, Area, Line, Tooltip, Dot, ReferenceLine } from 'recharts';
import moment from 'moment-timezone';
import { curveLinear } from 'd3-shape';
import styles from '../chart.module.scss';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getTicksH, getTicksDD } from '../utlity';
import { getMoodScores } from '../../../../services/api/chart';
import MoodTooltip from './mood-tooltip';
import { GRAPH_REVERSE_TOOLTIP_COORDINATE, CHARTS_SIZES } from '../../../../utils/constants';
import { Patient } from '../../../../interfaces/patient';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';

type MoodValueDto = {
  pa: number;
  na: number;
  ts: string;
  overall: number;
};

type MoodValueParsed = {
  pa: number[];
  na: number[];
  ts: number;
  overall: number;
};

type Props = {
  start: string;
  end: string;
  urData?: MoodValueDto[];
  isPdf?: boolean;
  reference?: number;
};

export const MoodChart: FC<Props> = ({ start, end, urData, isPdf, reference }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [data, setData] = useState<MoodValueParsed[]>([]);
  const { patient } = useContext(PatientContext);
  const GRAPH_WIDTH_PX = urData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = urData ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;

  const getMoodScoresAction = async (patient: Patient): Promise<void> => {
    const maxDataPoints = Math.floor(GRAPH_WIDTH_PX / 4);
    const { data } = await getMoodScores(patient.id, maxDataPoints, {
      startDate: moment(start).format('YYYY-MM-DD'),
      endDate: moment(end).tz(patientTimezone).format('YYYY-MM-DD'),
      timezone: patientTimezone,
    });
    parseMoodScore(data.points);
  };

  const parseMoodScore = data => {
    const points: MoodValueParsed[] =
      data.map(p => {
        return {
          ts: moment.utc(p.ts).unix() * 1000,
          pa: data.length === 1 ? [p.pa, p.pa] : [100, p.pa],
          overall: p.overall - 50,
          na: data.length === 1 ? [p.na, p.na] : [p.na * -1, -100],
        };
      }) || [];
    setData(points);
  };

  useEffect(() => {
    if (patient) {
      if (!urData) {
        getMoodScoresAction(patient);
      } else {
        parseMoodScore(urData);
      }
    }
  }, [patient, start, end, urData]);

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    if (value === -100) {
      return (
        <text
          fontSize='10'
          x={x - 43}
          y={y}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Neg 100
        </text>
      );
    }
    if (value === 100) {
      return (
        <text
          fontSize='10'
          x={x - 43}
          y={y + 7}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Pos 100
        </text>
      );
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      >
        {Math.abs(value)}
      </text>
    );
  };

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!payload?.[0]?.payload && !active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    const value = payload?.[0]?.payload ?? {};
    const na = value?.na?.[0] ?? 0;
    const pa = value?.pa?.[1] ?? 0;
    return (
      <MoodTooltip
        date={value?.ts}
        title='MOOD'
        value={{ na: na * -1, overall: value?.overall, pa }}
        reverse={reverse}
      />
    );
  };

  const createCustomDot = (dotProps): JSX.Element | null => {
    const { cy } = dotProps;
    // Workaround to remove duplicate dot representing 100 score in each dataValue of each area mood na and pa
    if ([5, 85].includes(cy)) {
      return null;
    }
    dotProps.fill = '#417EB9';
    return <Dot {...dotProps} />;
  };

  return (
    <div id={`moodChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>POSITIVE/NEGATIVE AFFECT & MOOD</span>
        {!urData && (
          <InfoTooltip baseStyles={`${styles.infoTooltip} ${styles.mood}`} type='left' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )}
      </div>
      <ComposedChart
        data={data}
        width={isPdf ? CHARTS_SIZES.UR_PDF_WIDTH : GRAPH_WIDTH_PX}
        height={isPdf ? CHARTS_SIZES.UR_PDF_HEIGHT : GRAPH_HEIGHT_PX}
      >
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>
        {!urData && (
          <XAxis
            xAxisId={0}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            tickSize={16}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}

        {!!urData && (
          <XAxis
            xAxisId={0}
            dy={-10}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={true}
            tickSize={16}
            tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={2}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={3}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && reference && <ReferenceLine y={reference} stroke='green' strokeWidth={1.2} />}
        <YAxis hide={false} tick={YAxisFormatter} interval={0} tickSize={0} ticks={[100, 50, 0, -50, -100]} />
        <CartesianGrid stroke='#A3A6B3' />
        {!urData && <Tooltip content={customToolTip} />}
        <Area
          type={curveLinear}
          dataKey='na'
          stroke='#417EB9'
          fill='url(#d1)'
          activeDot={createCustomDot}
          dot={false}
          strokeWidth='2'
        />

        <Line
          type={curveLinear}
          dataKey='overall'
          stroke='#417EB9'
          fill='url(#d1)'
          strokeWidth='2'
          strokeLinecap='square'
          strokeDasharray='2 3'
          activeDot={true}
          dot={false}
        />

        <Area
          type={curveLinear}
          dataKey='pa'
          stroke='#417EB9'
          fill='url(#d1)'
          isAnimationActive={true}
          activeDot={createCustomDot}
          strokeWidth='2'
        />
      </ComposedChart>
    </div>
  );
};
