import * as React from 'react';
import { useMachine } from '@xstate/react';
import { FunctionComponent, ReactNode } from 'react';
import {
  PractitionerPatientMachine,
  PractitionerPatientMachineStateActions,
  PractitionerPatientsMachineStates,
} from '../machines/patients.machine';

type PatientActorRef = any;

export const AftercarePatientsContext = React.createContext<{
  practitionerId?: string;
  aftercarePractitionerPatientsRef?: PatientActorRef[];
  loadPractitionerPatients: (practitionerId?: string, view?: string, clientId?: string) => any;
  aftercarePractitionerPatientsState: PractitionerPatientsMachineStates;
  reloadPractitionerPatient: (patientId: string) => void;
  reloadPractitionerPatients: () => void;
}>({
  loadPractitionerPatients: () => {},
  reloadPractitionerPatient: pId => {},
  reloadPractitionerPatients: () => {},
  aftercarePractitionerPatientsState: PractitionerPatientsMachineStates.Idle,
});

export const AftercarePatientsContextProvider: FunctionComponent = (props: { children?: ReactNode }) => {
  const [practitionerPatientsState, sendPractitionerPatientsState] = useMachine(PractitionerPatientMachine);

  return (
    <AftercarePatientsContext.Provider
      value={{
        practitionerId: practitionerPatientsState.context.practitionerId,
        aftercarePractitionerPatientsRef: practitionerPatientsState.context.patientsRef,
        loadPractitionerPatients: (practitionerId, view) => {
          sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.SetPractitioner,
            data: { practitionerId, view },
          });
        },
        reloadPractitionerPatient: patientId => {
          sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.ReloadPatient,
            data: { patientId },
          });
        },
        reloadPractitionerPatients: () => {
          sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.ReloadPractitionerPatients,
            data: {},
          });
        },
        aftercarePractitionerPatientsState: practitionerPatientsState.value as PractitionerPatientsMachineStates,
      }}
    >
      {props.children}
    </AftercarePatientsContext.Provider>
  );
};
