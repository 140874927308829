import React, { useContext, useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { PractitionerContext } from '../../contexts/Practitioner';
import { RemoteData, loading, notAsked } from '../../utils/remote-data';
import styles from './admin-devices.module.scss';
import UnassignedDevicesTable from './unassigned-devices-table';
import { Devices, Device, IndistinguishableDevices } from '../../interfaces/device';
import {
  getDevices,
  assignDeviceToPatient,
  unassignDeviceFromPatient,
  getIndistinguishableDevices,
} from '../../services/api/device';
import AssignDeviceModal from './assign-device-modal';
import { Patients, Patient } from '../../interfaces/patient';
import { getPatients } from '../../services/api/patient';
import AssignedDevicesTable from './assigned-devices-table';
import AssignConfirmationModal from './assign-confirmation-modal';
import { deviceStatus } from '../../utils/constants';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import SearchInput from '../common/table/search-input';
import AssignedBacTable from './assigned-bac-table';

export const AdminDevices = (): JSX.Element => {
  const { addToast } = useToasts();
  const { practitioner } = useContext(PractitionerContext);
  const [selectedTable, setSelectedTable] = useState<string>('unassigned');
  const [unassignedDevices, setUnassignedDevices] = useState<RemoteData<Devices>>(notAsked());
  const [assignedDevices, setAssignedDevices] = useState<RemoteData<Devices>>(notAsked());
  const [assignedBACs, setAssignedBACs] = useState<RemoteData<IndistinguishableDevices>>(notAsked());
  const [patients, setPatients] = useState<RemoteData<Patients>>(notAsked());
  const [searchResultDevices, setSearchResultDevices] = useState<Devices | undefined>(undefined);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(undefined);
  const [confirmationModalMode, setConfirmationModalMode] = useState<string>('');
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(undefined);
  const [confirmationModalLoading, setConfirmationModalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('AdminDevicesPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
    }
    fetchDevices();
    getPatientsAction();
    getIndistinguishableDevicesAction(practitioner?.organization.id);
  }, [practitioner]);

  useEffect(() => {
    if (!searchInputValue) {
      setSearchResultDevices(undefined);
    } else if (selectedTable === 'unassigned' && unassignedDevices.status === 'Done') {
      const searchResult = unassignedDevices.data.values.filter(device => device.name.includes(searchInputValue));
      setSearchResultDevices({ values: searchResult });
    } else if (selectedTable === 'assigned' && assignedDevices.status === 'Done') {
      const searchResult = assignedDevices.data.values.filter(device => device.name.includes(searchInputValue));
      setSearchResultDevices({ values: searchResult });
    }
  }, [searchInputValue, selectedTable]);

  useEffect(() => {
    if (selectedDevice && patients.status === 'Done') {
      setSelectedPatient(patients.data.values.find(p => p.id === selectedDevice.patientAssignedId));
    }
  }, [selectedDevice, patients]);

  const getUnassignedDevicesAction = async (): Promise<void> => {
    if (practitioner) {
      setUnassignedDevices(loading());
      const res = await getDevices(practitioner.organization.id, false);
      setUnassignedDevices(res);
    }
  };

  const getAssignedDevicesAction = async (): Promise<void> => {
    if (practitioner) {
      setAssignedDevices(loading());
      const res = await getDevices(practitioner.organization.id, true);
      setAssignedDevices(res);
    }
  };

  const getIndistinguishableDevicesAction = async organizationId => {
    setAssignedBACs(loading());
    const res = await getIndistinguishableDevices(organizationId);
    setAssignedBACs(res);
  };

  const fetchDevices = (): void => {
    getUnassignedDevicesAction();
    getAssignedDevicesAction();
  };

  const getPatientsAction = async (): Promise<void> => {
    if (practitioner) {
      setPatients(loading());
      const res = await getPatients(practitioner.organization.id);
      setPatients(res);
    }
  };

  const clearSelectedDevice = (): void => {
    setSelectedDevice(undefined);
  };

  const handleUnassignDevice = async (unassignReason: string): Promise<void> => {
    if (selectedPatient && selectedDevice) {
      setConfirmationModalLoading(true);
      const result = await unassignDeviceFromPatient(selectedPatient?.id, selectedDevice?.name, unassignReason);
      setConfirmationModalLoading(false);

      if (result.status === 'Done') {
        addToast('Device unassigned successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        setConfirmationModalMode('');
        setSelectedDevice(undefined);
        fetchDevices();
      } else {
        addToast(`Error: ${result.errorApi.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  const handleReassignDevice = async (): Promise<void> => {
    if (selectedDevice && selectedPatient) {
      setConfirmationModalLoading(true);
      const unassignResult = await unassignDeviceFromPatient(
        selectedPatient.id,
        selectedDevice?.name,
        deviceStatus.GOOD,
      );

      if (unassignResult.status === 'Done') {
        const assignResult = await assignDeviceToPatient(selectedPatient?.id, selectedDevice.name);
        setConfirmationModalLoading(false);

        if (assignResult.status === 'Done') {
          addToast('Device reassigned successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
          setConfirmationModalMode('');
          setSelectedDevice(undefined);
          fetchDevices();
        } else {
          addToast(`Error while assigning device: ${assignResult.errorApi.message}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } else {
        setConfirmationModalLoading(false);
        addToast(`Error while unassigning device: ${unassignResult.errorApi.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <div>
      <div className={styles.tableButtons}>
        <span
          className={`${styles.btn} ${styles.btnActive} ${selectedTable === 'unassigned' && styles.btnSelected}`}
          onClick={(): void => setSelectedTable('unassigned')}
        >
          Unassigned Devices
        </span>
        <span
          className={`${styles.btn} ${styles.btnDischarged} ${selectedTable === 'assigned' && styles.btnSelected}`}
          onClick={(): void => setSelectedTable('assigned')}
        >
          Assigned Devices
        </span>
        <span className={styles.searchInput}>
          <SearchInput
            searchValue={searchInputValue || ''}
            handleInputChange={ev => setSearchInputValue(ev.target.value)}
            handleOnClick={ev => setSearchInputValue('')}
            placeholder='Search Device Name'
          />
        </span>
      </div>
      {selectedTable === 'unassigned' ? (
        <UnassignedDevicesTable
          devices={unassignedDevices}
          setSelectedDevice={setSelectedDevice}
          searchResultDevices={searchResultDevices}
        />
      ) : (
        <div className={styles.devicesParentContainer}>
          <div className={styles.devicesSection}>
            <p>Health Band</p>
            <AssignedDevicesTable
              devices={assignedDevices}
              setSelectedDevice={setSelectedDevice}
              searchResultDevices={searchResultDevices}
              changeTable={() => setSelectedTable('unassigned')}
            />
          </div>
          <div className={styles.devicesSection}>
            <p>BACs</p>
            <AssignedBacTable devices={assignedBACs} />
          </div>
        </div>
      )}
      {selectedDevice && !confirmationModalMode && (
        <AssignDeviceModal
          show={!!selectedDevice}
          hide={clearSelectedDevice}
          device={selectedDevice}
          patients={patients}
          fetchDevices={fetchDevices}
          setConfirmationModalMode={setConfirmationModalMode}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
        />
      )}
      <AssignConfirmationModal
        show={!!confirmationModalMode}
        hide={() => setConfirmationModalMode('')}
        mode={confirmationModalMode}
        handleReassign={handleReassignDevice}
        handleUnassign={handleUnassignDevice}
        previousAssignee={
          selectedDevice?.patientAssignedName
            ? `${selectedDevice?.patientAssignedName.first} ${selectedDevice?.patientAssignedName.last}`
            : ''
        }
        nextAssignee={selectedPatient}
        loading={confirmationModalLoading}
      />
    </div>
  );
};
