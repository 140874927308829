import { endOfWeek, startOfWeek } from 'date-fns';
import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GeofenceType } from '../../../interfaces/enums';
import { GeofenceLocation, GeofencePeriods } from '../../../interfaces/geofence';
import { getGeofenceLocations } from '../../../services/api/geofence';
import { loading, RemoteData } from '../../../utils/remote-data';
import DatePicker, { DateRange } from '../../common/date-picker/date-range-picker';
import { DropDown } from '../../dropdown';
import Modal from '../../modal/Modal';
import GeofenceLocationsTable from './geofence-locations-table';
import GeofenceMap from './geofence-map';
import styles from './geofence.module.scss';
import GeofencesTable from './geofences-table';
import GeoLocation from './modals/geofence-location';

type Props = {};
type SelectedTab = 'GeofenceList' | 'Map' | 'Settings';

const Geofence: FunctionComponent<Props> = () => {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('GeofenceList');
  const [showLocation, setShowLocation] = useState(false);
  const [geofenceLocations, setGeofenceLocations] = useState<RemoteData<GeofencePeriods>>(loading());
  const [geofenceTypeFilter, setGeofenceTypeFilter] = useState<string>('all');
  const [selectedGeofence, setSelectedGeofence] = useState<GeofenceLocation>();
  const [showAddGeoModal, setShowAddGeoModal] = useState<boolean>(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });
  const { id } = useParams<{ id: string }>();

  const requestGeofenceLocations = async () => {
    try {
      const res = await getGeofenceLocations(id!, {
        startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
        endDate: moment(dateRangeFilter.endDate).subtract(1, 'days').utc().format('YYYY-MM-DD'),
        timezone: moment.tz.guess(),
      });
      setGeofenceLocations(res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await requestGeofenceLocations();
    })();
  }, [dateRangeFilter]);

  const openGeofenceLocation = (geofenceLocation: GeofenceLocation): void => {
    setSelectedGeofence(geofenceLocation);
    setShowLocation(true);
  };

  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };
  return (
    <>
      <header className={styles.geofenceHeader}>
        <nav>
          <ul className={styles.navLeft}>
            <li
              className={`${selectedTab.includes('GeofenceList') ? styles.active : styles.inactive}`}
              onClick={() => setSelectedTab('GeofenceList')}
            >
              List view
            </li>
            <li
              className={`${selectedTab.includes('Map') ? styles.active : styles.inactive}`}
              onClick={() => setSelectedTab('Map')}
            >
              Map View
            </li>
            <li
              className={`${selectedTab.includes('Settings') ? styles.active : styles.inactive}`}
              onClick={() => {
                setSelectedTab('Settings');
                setGeofenceTypeFilter('all');
              }}
            >
              Settings
            </li>
          </ul>
          <ul className={styles.navRight}>
            {(selectedTab === 'GeofenceList' || selectedTab === 'Map') && (
              <>
                <li className={styles.options}>
                  <DropDown contentStyle='filter'>
                    <span className={styles.dropdownButton}>
                      {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
                      <i className={styles.arrow}>
                        <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                        </svg>
                      </i>
                    </span>
                    <>
                      <DatePicker
                        onDateSelectionChange={onDateChange}
                        dateRange={dateRangeFilter}
                        parentStyle={styles.filterDatepicker}
                      />
                    </>
                  </DropDown>
                </li>
              </>
            )}
            {selectedTab === 'Settings' && (
              <>
                <li className={styles.options}>
                  <a href='#' className='href' onClick={() => setShowAddGeoModal(true)}>
                    {' '}
                    Add New Geofence
                  </a>
                </li>
                <li className={styles.options}>
                  <DropDown contentStyle='filter' hideDropdownOnClick={true}>
                    <span className={styles.dropdownButton}>
                      Geofence Type
                      <i className={styles.arrow}>
                        <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                        </svg>
                      </i>
                    </span>
                    <>
                      <ul>
                        <li onClick={event => setGeofenceTypeFilter('All')}>All</li>
                        <li onClick={event => setGeofenceTypeFilter(GeofenceType.NonCompliant)}>Non-Compliant</li>
                        <li onClick={event => setGeofenceTypeFilter(GeofenceType.Special)}>Special</li>
                      </ul>
                    </>
                  </DropDown>
                </li>
              </>
            )}
          </ul>
        </nav>
      </header>
      {selectedTab === 'GeofenceList' ? (
        <GeofenceLocationsTable geofenceLocations={geofenceLocations} onTableActionClick={openGeofenceLocation} />
      ) : selectedTab === 'Settings' ? (
        <GeofencesTable
          filterType={geofenceTypeFilter}
          showAddModal={showAddGeoModal}
          setShowAddModal={setShowAddGeoModal}
        />
      ) : (
        <GeofenceMap geofenceLocations={geofenceLocations} />
      )}
      {showLocation && (
        <Modal contentStyle='bigContent' show={showLocation} showBtnClose closeModal={() => setShowLocation(false)}>
          <GeoLocation geofenceLocation={selectedGeofence!} />
        </Modal>
      )}
    </>
  );
};

export default Geofence;
