import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { BacResult } from '../../../interfaces/bac-result';
import Table from '../../common/table/table';
import { ReactComponent as CheckLogo } from '../../../assets/icons/pass_icon.svg';
import { BacMiss, BacPending } from '../../../assets/icons';
import { CellProps, Column } from 'react-table';
import { BacModal } from './bac-test';
import styles from './bac.module.scss';
import { RemoteData } from '../../../utils/remote-data';
import { getPatientTimezone } from '../../../contexts/PatientContext';
import { displayTableDateTimeFormat } from '../../../utils/data-parser';
import { caseOf } from '../../../utils/case-of';

type Props = {
  onTableActionClick: (bacTest: BacResult, type: BacModal) => void;
  bacTests: RemoteData<{ values: BacResult[] }>;
};

const BacTable: FunctionComponent<Props> = ({ onTableActionClick, bacTests }) => {
  const timezone = getPatientTimezone();

  const displayIfScheduledBacOrRetake = (bacResult: BacResult): string => {
    if (bacResult.retake) return 'Client Retake';
    return caseOf()
      .case('VIVI_CIRCLE', 'Yes - By Supporter')
      .case('VIVI_CLINIC', 'Yes - By Clinician')
      .defaultCase(() => 'No - By Client')
      .eval(bacResult.sourceApp);
  };
  const getStatusElement = (status: string): ReactNode => {
    return (
      <span className={`${styles[`status${status.toLowerCase()}`]}`}>
        {status.toLowerCase().includes('pass') ? (
          <CheckLogo style={{ marginRight: '6px' }} />
        ) : status.toLowerCase().includes('pending') ? (
          <BacPending style={{ marginRight: '6px' }} />
        ) : (
          <BacMiss
            style={{
              marginRight: '6px',
              color: status.toLowerCase().includes('miss') ? '#EFBB55' : '#ED6F6A',
            }}
          />
        )}
        {status.toLocaleUpperCase()}
      </span>
    );
  };
  // set columns properties and dynamic values for table
  const bacTableProps: Column<BacResult>[] = useMemo(
    () => [
      {
        accessor: 'timestamp',
        Header: 'Date',
        width: 200,
        Cell: ({ cell: { value } }: CellProps<BacResult, string>) => displayTableDateTimeFormat(value, timezone),
        sortType: 'basic',
      },
      {
        accessor: 'status',
        Header: 'Status',
        width: 143,
        Cell: ({ cell: { value } }) => getStatusElement(value),
        sortType: 'basic',
      },
      {
        accessor: 'scheduled',
        Header: 'Scheduled',
        width: 161,
        Cell: ({ cell: { row } }) => displayIfScheduledBacOrRetake(row.original),
        sortType: 'basic',
      },
      {
        accessor: 'level',
        Header: 'Bac Measured',
        width: 182,
        Cell: ({ cell: { value } }) => (
          <span className={isNaN(value) ? styles.noAction : ''}>{isNaN(value) ? 'N/A' : value}</span>
        ),
        sortType: 'alphanumeric',
      },
      {
        accessor: 'location',
        Header: 'Location',
        width: 184,
        Cell: ({ cell: { value, row } }) =>
          value && (
            <span
              className={`${styles.action} ${styles.ellipsis}`}
              onClick={() => onTableActionClick(row.original, 'Location')}
            >
              {value?.address}
            </span>
          ),
        sortType: (rowA, rowB, columnID, desc) => {
          const addressA = rowA.original.location?.address || '';
          const addressB = rowB.original.location?.address || '';
          return !addressA ? -1 : !addressB ? 1 : addressA.localeCompare(addressB);
        },
      },
      {
        accessor: (originalRow, index) => {
          return originalRow.identification.faceMatchStatus;
        },
        id: 'facialMatchStatus',
        Header: 'Facial Match',
        Cell: ({ cell: { value } }) => (
          <span className={`${value === undefined ? styles.noAction : ''}`} style={{ textTransform: 'capitalize' }}>
            {value ? value.toLocaleLowerCase() : 'N/A'}
          </span>
        ),
        sortType: (rowA, rowB, columnID, desc) => {
          const facialMatchA = rowA.original.identification?.faceMatchStatus || '';
          const facialMatchB = rowB.original.identification?.faceMatchStatus || '';
          return !facialMatchA
            ? -1
            : !facialMatchB
            ? 1
            : desc && facialMatchA.toLocaleLowerCase() === 'pending'
            ? 1
            : desc && facialMatchB.toLocaleLowerCase() === 'pending'
            ? -1
            : facialMatchA.localeCompare(facialMatchB);
        },
        width: 115,
      },
      {
        accessor: 'identification',
        id: 'photo',
        Header: '',
        minWidth: 115,
        width: 115,
        Cell: ({ cell: { value, row } }) =>
          !value.faceMatchStatus ? (
            <span className={styles.noAction}> No Photo</span>
          ) : (
            <span className={styles.action} onClick={() => onTableActionClick(row.original, 'FacialMatch')}>
              {value.faceMatchStatus === 'PENDING' ? 'Review Photo' : 'View Photo'}
            </span>
          ),
        disableSortBy: true,
      },
    ],
    [timezone, onTableActionClick],
  );
  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: bacTests.status === 'Done' && !bacTests.data.values.length ? styles.noContent : styles.table,
    };
  };

  return (
    <Table<BacResult>
      data={bacTests.status === 'Done' ? bacTests.data.values : []}
      columns={bacTableProps}
      isLoading={bacTests.status.includes('Loading')}
      error={bacTests.status.includes('Failed')}
      rowStyles={{ background: 'rgba(65, 126, 185, 0.1)' }}
      getRowProps={row => {
        return {
          key: `${row?.id}`,
          style: {
            background: row?.original?.identification?.identical ? '#FFF' : 'rgba(65, 126, 185, 0.1)',
          },
          className: styles.tr,
        };
      }}
      getHeaderProps={column => {
        return {
          className: styles.th,
          key: `${column?.id}`,
          style: {
            width: column?.width,
            minWidth: column?.minWidth,
            maxWidth: column?.maxWidth,
          },
        };
      }}
      getCellProps={cell => {
        return {
          key: `${cell?.column.id}`,
          style: {
            justifyContent: cell?.column.id === 'photo' ? 'flex-end' : 'flex-start',
            padding: cell?.column.id === 'location' ? '11px 18px' : undefined,
            display: 'flex',
          },
          className: styles.td,
        };
      }}
      defaultColumn={{ width: 180, minWidth: 100 }}
      initialState={defaultSorting}
      getTableBodyProps={() => getTableBodyProps()}
      getTableProps={() => getTableProps()}
      emptyDataMessage={'There are no BAC results at this time.'}
    />
  );
};

export default BacTable;
