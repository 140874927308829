import { Done, Failed, RemoteData, done, failed } from '../../utils/remote-data';
import { get, put } from './base';
import { Insights } from '../../interfaces/insight';
import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';

export const getInsights = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<Insights>> => {
  try {
    const res = await get(`/insight-engine/insights?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const clearAlert = async (patientId: string, insightId: number): Promise<Done<any> | Failed> => {
  try {
    const res = await put(`/insight-engine/insights/cleared?patientId=${patientId}&insightId=${insightId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const clearAllAlerts = async (patientId: string): Promise<Done<unknown> | Failed> => {
  try {
    const res = await put(`/insight-engine/insights/clear-all?patientId=${patientId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};
