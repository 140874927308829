import { gql } from '@apollo/client';
import { PRACTITIONER_CLIENTS_ASSIGNED_FIELDS } from '../fragments';

export const GET_PRACTITIONER_REPORT = gql`
  query PractitionerReport(
    $id: String
    $startOfDay: LocalDateTime
    $endOfDay: LocalDateTime
    $endLastWeek: LocalDateTime
    $startLastWeek: LocalDateTime
    $startLastMonth: LocalDateTime
    $metric: PTSMetric
    $orgReportGroups: ClientCountRequestInput
  ) {
    organizationReport(id: $id) {
      organizationId
      today: periodReport(start: $startOfDay, end: $endOfDay, metric: $metric, request: $orgReportGroups) {
        clientsByPractitioner {
          ...clientsByPractitionerFields
        }
      }
      lastWeek: periodReport(start: $startLastWeek, end: $endLastWeek, metric: $metric, request: $orgReportGroups) {
        clientsByPractitioner {
          ...clientsByPractitionerFields
        }
      }
      lastMonth: periodReport(start: $startLastMonth, end: $startOfDay, metric: $metric, request: $orgReportGroups) {
        clientsByPractitioner {
          ...clientsByPractitionerFields
        }
      }
      practitionerByRole {
        role
        count
      }
      devices {
        deviceType
        allCount
        used
      }
    }
  }
  ${PRACTITIONER_CLIENTS_ASSIGNED_FIELDS}
`;
