import React, { FunctionComponent, useMemo } from 'react';
import styles from '../chart.module.scss';
import { displayDateTimeFormat, roundNumber } from '../../../../utils/data-parser';
import { EngagementComponent } from '../../../../interfaces/chart-results';
import { EngagementScoreComponentWeight } from '../../../../utils/constants';
import { HighlightStrongOrLowScoreComponent } from '../utlity';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type EngagementTooltipProps = {
  reverse: boolean;
  date: number;
  data: {
    value: number;
    components?: EngagementComponent;
  };
};

const EngagementTooltip: FunctionComponent<EngagementTooltipProps> = ({ reverse, date, data }) => {
  const timezone = getPatientTimezone();
  const calculateScoreComponentWeight = (component: number, weight: number) => roundNumber(component * weight, 1);
  const minMaxComponents: { lowerComponent: string; strongerComponent: string } = useMemo(() => {
    const orderedComponents = Object.entries(data?.components || {})
      .map((value: [string, number]) => ({
        title: value[0],
        value: value[1],
      }))
      .sort((a, b) => a.value - b.value);
    return {
      lowerComponent: orderedComponents[0]?.title,
      strongerComponent: orderedComponents[orderedComponents.length - 1]?.title,
    };
  }, [data]);

  return (
    <div className={`${styles.chartBigToolTip} ${reverse ? styles.reverse : ''}`}>
      <div className={styles.chartTooltipContent}>
        <span>{displayDateTimeFormat(date, timezone)}</span>
      </div>
      <div className={styles.chartToolTipTitle}>
        <span>ENGAGEMENT: {data.value}</span>
      </div>
      {!!data.components && (
        <div className={styles.chartTooltipComponentsContainer} style={{ marginLeft: '10px' }}>
          <span style={HighlightStrongOrLowScoreComponent('meetingAttendance', minMaxComponents)}>
            . Meetings:{' '}
            {calculateScoreComponentWeight(
              data.components.meetingAttendance,
              EngagementScoreComponentWeight['meetingAttendance'],
            )}{' '}
            / {EngagementScoreComponentWeight['meetingAttendance']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('communication', minMaxComponents)}>
            . Comms:{' '}
            {calculateScoreComponentWeight(
              data.components.communication,
              EngagementScoreComponentWeight['communication'],
            )}{' '}
            / {EngagementScoreComponentWeight['communication']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('testingCompliance', minMaxComponents)}>
            . BACs:{' '}
            {calculateScoreComponentWeight(
              data.components.testingCompliance,
              EngagementScoreComponentWeight['testingCompliance'],
            )}{' '}
            / {EngagementScoreComponentWeight['testingCompliance']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('surveyCompliance', minMaxComponents)}>
            . Surveys:{' '}
            {calculateScoreComponentWeight(
              data.components.surveyCompliance,
              EngagementScoreComponentWeight['surveyCompliance'],
            )}{' '}
            / {EngagementScoreComponentWeight['surveyCompliance']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('selfHelpContent', minMaxComponents)}>
            . Self-Help Use:{' '}
            {calculateScoreComponentWeight(
              data.components.selfHelpContent,
              EngagementScoreComponentWeight['selfHelpContent'],
            )}{' '}
            / {EngagementScoreComponentWeight['selfHelpContent']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('deviceWorn', minMaxComponents)}>
            . Wearing the Wristband:{' '}
            {calculateScoreComponentWeight(data.components.deviceWorn, EngagementScoreComponentWeight['deviceWorn'])} /{' '}
            {EngagementScoreComponentWeight['deviceWorn']}%
          </span>
        </div>
      )}
    </div>
  );
};

export default EngagementTooltip;
