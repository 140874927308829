import * as React from 'react';
import * as _ from 'lodash';
import styles from './view-gad-survey-modal.module.scss';
import GAD_SCORE from './gad-score.png'

interface Props {
  viewSurvey: any;
  patient: any;
  practitioner: any;
}

const questions = {
  'Feeling nervous, anxious or on edge': null,
  'Not being able to stop or control worrying': null,
  'Worrying too much about different things': null,
  'Trouble relaxing': null,
  'Being so restless that it is hard to sit still': null,
  'Becoming easily annoyed or irritated': null,
  'Feeling afraid as if something awful might happen': null,
}
const ViewGADSurveyModalQuestion: React.FC<Props> = (props) => {
  for(let prop in questions) {
    const value = props?.viewSurvey?.questions?.find((question) => question.subtext === prop);
    questions[prop] = value?.answer?.value;
  }



  const topQuestions = props?.viewSurvey?.questions?.filter(
    ({ text }) => text === 'Over the last 2 weeks, how often have you been bothered by the following problems?',
  );
  const scores = {
    'not-at-all': 0,
    'several-days': 0,
    'more-than-half': 0,
    'nearly-every-day': 0,
  };
  topQuestions.map(question => {
    const { answer } = question;
    scores[answer.id] = scores[answer.id] + answer.value;
  });


  const bottomQuestion = props?.viewSurvey?.questions?.find(
    ({ text }) =>
      text ===
      'If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
  );

  return (
    <div className={styles.borderBox}>
      <div className={`${styles.titlerow}`}>
        <div className={styles.questionBox}>
          <span className={`${styles.text}`}>
            Over the last 2 weeks, how often have you been bothered by the following problems?
          </span>
        </div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
          <span className={`${styles.text}`}>Not at all</span>
        </div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
          <span className={`${styles.text}`}>Several days</span>
        </div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
          <span className={`${styles.text}`}>More than half the days</span>
        </div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
          <span className={`${styles.text}`}>Nearly every day</span>
        </div>
      </div>
      {Object.keys(questions).map((question, index) => {
        return (
          <div className={`${styles.row}`} key={index}>
            <div className={styles.questionBox}>
              <span className={`${styles.text} ${styles.fifty}`}>{index + 1}. {question}</span>
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 0 && (
                <span className={`${styles.text} ${styles.selectedAnswer}`}>0</span>
              )}
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 1 && (
                <span className={`${styles.text} ${styles.selectedAnswer}`}>1</span>
              )}
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 2 && (
                <span className={`${styles.text} ${styles.selectedAnswer}`}>2</span>
              )}
            </div>
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              {questions[question] === 3 && (
                <span className={`${styles.text} ${styles.selectedAnswer}`}>3</span>
              )}
            </div>
          </div>
        );
      })}
      <div className={`${styles.titlerow}`}>
        <div className={styles.emptyQuestionBox}> </div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
          <span className={`${styles.text}`}>Add columns</span>
        </div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>{scores['not-at-all']}</div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>{scores['several-days']}</div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>{scores['more-than-half']}</div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>{scores['nearly-every-day']}</div>
      </div>
      <div className={`${styles.titlerow}`}>
        <div className={styles.emptyQuestionBox}> </div>
        <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
          <span className={`${styles.text}`}>Total Score</span>
        </div>
        <div className={`${styles.totalRowBox}`}>
          <div className={`${styles.leftBorderTitle}`}>{Object.values(scores).reduce((a, b) => a + b)}</div>
        </div>
      </div>
      <div className={`${styles.titlerow}`}>
        <div className={styles.bottomQuestionBox}>
          <span className={`${styles.text} ${styles.fifty}`}>8. {bottomQuestion.text}</span>
        </div>
        {bottomQuestion.availableAnswers.map(answer => {
          return (
            <div className={`${styles.leftBorderTitle} ${styles.answer}`}>
              <span
                className={`${styles.text} ${bottomQuestion.answer.id === answer.id ? styles.selectedAnswerLg : ''}`}
              >
                {answer.text}
              </span>
            </div>
          );
        })}
      </div>
      <div style={{ marginTop: 50 }}>
        <img src={GAD_SCORE} />
      </div>
    </div>
  );
};

export default ViewGADSurveyModalQuestion;
