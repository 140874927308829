// FILE IS FOR ANY SHARED LOGIC BETWEEN GRPAHS

import { scaleUtc } from 'd3-scale';
import { utcHour, utcMonth } from 'd3-time';
import moment from 'moment';
import { CSSProperties } from 'react';
import { caseOf } from '../../../utils/case-of';

export const getTicksDD = (start, end, graphWidth) => {
  const longLabelW = 35;
  const durHour = moment.duration(moment(end).diff(start)).asHours(); /* duration in hours */
  const durDD = moment.duration(moment(end).diff(start)).asDays(); /* duration in days */

  /* allowable intervals for niceness */
  const bestHours = [2, 3, 4, 6, 8, 12];
  const bestDays = [1, 2, 3, 4, 7, 14];
  let everyDD: any = durDD * (longLabelW / graphWidth); /* use longest */
  const everyM = 1; /* ultimate tick unit (no processing on this makes the upper limit ~2 years) */
  everyDD = bestDays.find(number => number >= everyDD);

  /* build getTicks array for hours and day/date axes */
  const gTdomain = [new Date(start), new Date(end)];
  /* Once day/date ticks are >= ~1/2 month, hours syncs with month ticks */
  let getTicksDD;
  if (everyDD === undefined) {
    /* fell of the end of bestDays[] => Months */
    getTicksDD = scaleUtc()
      .domain(gTdomain)
      .range([0, 1])
      .ticks(utcMonth.every(everyM))
      .map(entry => +entry);
  } else {
    /*
     * HACK for D3/moments issue
     * Incrementing days "automatically" resets when crossing month boundaries.
     * Sometime makes sense, but not for us!
     * This manually builds the array ignoring month boundaries until auto-switch to Months.
     */
    const range = [] as any[];
    const s = moment.utc(start);
    const e = moment.utc(end);
    let previous: any;
    do {
      range.push((previous = new Date(+s)));
      s.add(everyDD, 'days');
    } while (previous < s && s < e);
    getTicksDD = range;
  }
  return getTicksDD;
};

export const getTicksH = (start, end, graphWidth) => {
  /* Once hours ticks are >= 1 day, they sync with day/date ticks */
  const gTdomain = [new Date(start), new Date(end)];
  /* estimate approximate width of longest axis tick labels for spacing */
  const longLabelW = 35;
  const durHour = moment.duration(moment(end).diff(start)).asHours(); /* duration in hours */
  const everyH: any = durHour * (longLabelW / graphWidth);
  if (everyH === undefined) {
    /* fell of the end of bestHours[] */
    return getTicksDD;
  }
  return scaleUtc()
    .domain(gTdomain)
    .range([0, 1])
    .ticks(utcHour.every(everyH))
    .map(entry => +entry);
};

export const HighlightStrongOrLowScoreComponent = (
  componentName: string,
  minMaxComponent: { lowerComponent: string; strongerComponent: string },
): CSSProperties => {
  return caseOf<string, any, CSSProperties>()
    .case(minMaxComponent.lowerComponent, { color: '#ed6f6a', fontWeight: 'bold' })
    .case(minMaxComponent.strongerComponent, { color: '#6fcf97', fontWeight: 'bold' })
    .defaultCase(() => {
      return {};
    })
    .eval(componentName) as CSSProperties;
};

export const getSleepProgressBarStatus = (score: number): [string, string] => {
  if (score < 49.5) {
    return ['low', 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)'];
  } else if (score >= 49.5 && score < 69.5) {
    return ['Fair', 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)'];
  } else if (score >= 69.5 && score < 85) {
    return ['Good', 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)'];
  } else if (score >= 85) {
    return ['Great', 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)'];
  }
  return ['low', 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)'];
};
