import React, { useContext, useState, useEffect } from 'react';
import { PractitionerContext } from '../contexts/Practitioner';
import { Redirect, useLocation } from 'react-router-dom';
import { ROLE_CODES } from './constants';

type Props = {
  from: string;
};

const CustomRedirect: React.FC<Props> = ({ from }) => {
  const { practitioner } = useContext(PractitionerContext);
  const { state } = useLocation<{ loginReferrer: string }>();
  const [redirect, setRedirect] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (practitioner) {
      let redirectPath = '';
      //check if comes from login with another url
      if (state?.loginReferrer && state.loginReferrer !== '/') {
        redirectPath = state.loginReferrer;
      } else {
        const [isClinician, isAdmin, isAftercare] = [
          !!practitioner?.roles.roles.find(r => r.code === ROLE_CODES.CLINICIAN),
          !!practitioner?.roles.roles.find(r => r.code === ROLE_CODES.ADMIN),
          !!practitioner?.roles.roles.find(r => r.code === ROLE_CODES.AFTERCARE),
        ];
        redirectPath = isClinician ? '/home' : isAftercare ? '/aftercare' : isAdmin ? '/admin/dashboard' : '/';
      }
      setRedirect(redirectPath);
    }
  }, [practitioner]);

  return redirect ? <Redirect to={redirect} from={from} /> : null;
};

export default CustomRedirect;
