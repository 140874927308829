import { useMachine } from '@xstate/react';
import * as React from 'react';
import { Insight } from '../interfaces/insight';
import {
  PractitionerPatientMachine,
  PractitionerPatientMachineStateActions,
  PractitionerPatientsMachineStates,
} from '../machines/patients.machine';

type PatientActorRef = any;
export const PatientsContext = React.createContext<{
  practitionerId?: string;
  practitionerPatientsRef?: PatientActorRef[];
  loadPatient: (patientId: string, clientId: string) => any;
  unloadPatient: (patientId: string, clientId: string) => any;
  getPatient: (patientId: string) => PatientActorRef | null;
  loadPractitionerPatients: (practitionerId?: string, view?: string, clientId?: string) => any;
  practitionerPatientsState: PractitionerPatientsMachineStates;
  changeInsight: (patientId: string, insight: Insight) => any;
}>({
  loadPatient: (pId, clientId) => {},
  unloadPatient: (pId, clientId) => {},
  getPatient: pId => null,
  loadPractitionerPatients: () => {},
  practitionerPatientsState: PractitionerPatientsMachineStates.Idle,
  changeInsight: (patientId: string, insight: Insight) => {},
});

export const PatientsContextProvider = (props: { children: any }) => {
  const [practitionerPatientsState, sendPractitionerPatientsState] = useMachine(PractitionerPatientMachine);

  return (
    <PatientsContext.Provider
      value={{
        practitionerId: practitionerPatientsState.context.practitionerId,
        practitionerPatientsRef: practitionerPatientsState.context.patientsRef,
        loadPatient: (patientId, clientId) =>
          sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.LoadPatient,
            data: { patientId, clientId },
          }),
        unloadPatient: (patientId, clientId) =>
          sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.UnloadPatient,
            data: { patientId, clientId },
          }),
        getPatient: patientId => {
          if (practitionerPatientsState.context.patientsRefById[patientId]) {
            return practitionerPatientsState.context.patientsRefById[patientId];
          }
          return sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.LoadPatient,
            data: { patientId },
          }).context.patientsRefById[patientId];
        },
        loadPractitionerPatients: (practitionerId, view) => {
          sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.SetPractitioner,
            data: { practitionerId: practitionerId, view: view },
          });
        },
        changeInsight: (patientId, insight) => {
          sendPractitionerPatientsState({
            type: PractitionerPatientMachineStateActions.ChangePatientInsight,
            data: {
              patientId: patientId,
              insight,
            },
          });
        },
        practitionerPatientsState: practitionerPatientsState.value as PractitionerPatientsMachineStates,
      }}
    >
      {props.children}
    </PatientsContext.Provider>
  );
};
