import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useStepsQuery } from 'graphql/codegen/hooks';
import { get } from './base';
import {
  AdherenceResult,
  EngagementResult,
  ExerciseResult,
  MoodResult,
  SleepResult,
  VitalsResult,
  VivihealthResult,
  UserStepsData,
} from '../../interfaces/chart-results';
import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';

export const getViviHealthScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<VivihealthResult>> => {
  return await get(`/insight-engine/vivihealth-score?patientId=${patientId}&maxDataPoints=${Math.floor(maxPoints)}`, {
    ...config,
    params: {
      ...castDateTimeFilters(dateFilters),
    },
    cache: true,
  });
};

export const getEngagementScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<EngagementResult>> => {
  return await get(`/insight-engine/engagement-score?patientId=${patientId}&maxDataPoints=${Math.floor(maxPoints)}`, {
    params: {
      ...castDateTimeFilters(dateFilters),
    },
    cache: true,
  });
};

export const getSleepScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<SleepResult>> => {
  return await get('/insight-engine/sleep-score', {
    params: {
      ...castDateTimeFilters(dateFilters),
      maxDataPoints: Math.floor(maxPoints),
      patientId,
    },
    cache: true,
  });
};

export const getMoodScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<MoodResult>> => {
  return await get('/insight-engine/mood-score', {
    params: {
      ...castDateTimeFilters(dateFilters),
      maxDataPoints: Math.floor(maxPoints),
      patientId,
    },
  });
};

export const getExerciseScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<ExerciseResult>> => {
  return await get(`/insight-engine/exercise-score?patientId=${patientId}&maxDataPoints=${Math.floor(maxPoints)}`, {
    params: {
      ...castDateTimeFilters(dateFilters),
    },
  });
};
export const getActivityScore = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<VivihealthResult>> => {
  return getViviHealthScores(patientId, maxPoints, dateFilters, {
    transformResponse: [
      (data: any): VivihealthResult => {
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data);
          } catch (error) {
            return { points: [], size: 0 };
          }
        }
        return {
          points: data.points.flatMap(point =>
            point.version > 0 ? [{ ...point, activityScore: Math.round(point.components.exercise * 100) }] : [],
          ),
          size: data.size,
        };
      },
    ],
  });
};

export const getVitalsScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<VitalsResult>> => {
  return await get('/vitals', {
    params: {
      ...castDateTimeFilters(dateFilters),
      maxDataPoints: Math.floor(maxPoints),
      patientId,
    },
  });
};

export const getStressScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<ExerciseResult>> => {
  return await get('/insight-engine/stress-score', {
    params: {
      ...castDateTimeFilters(dateFilters),
      maxDataPoints: Math.floor(maxPoints),
      patientId,
    },
    cache: true,
  });
};

export const getAdherenceScores = async (
  patientId: string,
  maxPoints: number,
  dateFilters: DateTimeFiltersRequest,
): Promise<AxiosResponse<AdherenceResult>> => {
  return await get(`/insight-engine/adherence-score`, {
    params: {
      patientId,
      maxDataPoints: Math.floor(maxPoints),
      ...castDateTimeFilters(dateFilters),
    },
    cache: true,
  });
};

// export const getPatientSteps = async (
//   patientId: string,
//   maxPoints: number,
//   dateFilters: DateTimeFiltersRequest,
//   start,
//   end
//   ): Promise<any> => {
//   return useMyPatientQuery([])
// };

