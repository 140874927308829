import React from 'react';

export const BacIconWhite = props => {
  return (
    <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4.24141' y='3.50508' width='8.95503' height='14.835' rx='1.7' stroke='#323335' strokeWidth='0.6' />
      <rect x='6.44453' y='6.44648' width='4.54502' height='3.07501' rx='0.7' stroke='#323335' strokeWidth='0.6' />
      <path
        d='M13.427 4.83711H15.9062C16.4382 4.83711 16.8695 5.26839 16.8695 5.80039C16.8695 6.3324 16.4382 6.76368 15.9062 6.76368H13.427V4.83711Z'
        stroke='#323335'
        strokeWidth='0.6'
      />
      <line
        x1='7.17891'
        y1='11.7273'
        x2='10.2539'
        y2='11.7273'
        stroke='#323335'
        strokeWidth='0.6'
        stroke-linecap='round'
      />
      <line
        x1='7.17891'
        y1='13.9314'
        x2='10.2539'
        y2='13.9314'
        stroke='#323335'
        strokeWidth='0.6'
        stroke-linecap='round'
      />
      <path d='M1 3.94001C1 2.31629 2.31629 1 3.94001 1' stroke='#323335' strokeWidth='0.6' stroke-linecap='round' />
      <path
        d='M2.46875 3.94071C2.46875 3.12885 3.12689 2.4707 3.93875 2.4707'
        stroke='#323335'
        strokeWidth='0.6'
        stroke-linecap='round'
      />
    </svg>
  );
};
