import React from 'react';
import { Bar, BarChart, Tooltip, XAxis, Legend, YAxis } from 'recharts';
import styles from './total-clients-chart.module.scss';

type Props = {
  title: string;
  dataSet: { label: string; value: number }[];
  legend: string;
};
const TotalClientsChart = ({ title, dataSet, legend }: Props) => {
  const CustomizedLabel = props => {
    const { x, y, fill, value } = props;
    return (
      <text x={x} y={y} dy={-1} dx={20} fontSize='14' fontFamily='sans-serif' fill={fill} textAnchor='middle'>
        {value}
      </text>
    );
  };

  return (
    <div className={styles.chartContainer}>
      <p className={styles.header}>{title}</p>
      <BarChart
        width={730}
        height={250}
        barCategoryGap='12%'
        maxBarSize={100}
        data={dataSet}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis label={{ value: legend, angle: -90, position: 'insideLeft' }} />
        <XAxis dataKey='label' />
        <XAxis label={{ value: 'Client Admission Month', position: 'insideLeft' }} />
        <Tooltip />
        <Legend verticalAlign='top' wrapperStyle={{ top: '-20px' }} align='center' iconType='square' />
        <Bar name={legend} isAnimationActive={true} dataKey='value' fill='#417EB9' label={<CustomizedLabel />} />
      </BarChart>
    </div>
  );
};

export default TotalClientsChart;
