import React from 'react';
import { ReactComponent as InsightIcon } from '../../../../assets/icons/icn-bkgd-insight-blue.svg';
import { getEventIcon } from '../../../../utils/event-helper';
import { HEX_COLORS } from '../../../../utils/constants';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>Events</h3>
      <p>The events timeline shows important information about the client. There are 4 rows of event icons.</p>
      <p style={{ marginBottom: 20 }}>The rows are defined as follows:</p>
      <p>Row 1 - Well being: Insights about the physiological condition and other information about the client</p>
      <ul style={{ marginLeft: 20 }}>
        <li>
          <figure>
            <InsightIcon />
          </figure>
          Insights
        </li>
        <li>
          <em>Clicking on an insight will open the full insight. </em>
        </li>
      </ul>
      <p>Row 2 - Engagement: Passed or successful events that the client has responded to.</p>
      <ul style={{ marginLeft: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <li>
          <figure>{getEventIcon('MEETING', 16, 24, HEX_COLORS.STABLE, undefined, false)}</figure> Attended Meetings
        </li>
        <li style={{ marginLeft: 10 }}>
          <figure>{getEventIcon('SURVEY', 16, 24, HEX_COLORS.STABLE, undefined, false)}</figure> Survey Completed
        </li>
      </ul>
      <ul style={{ marginLeft: 20, display: 'flex', flexDirection: 'row' }}>
        <li>
          <figure>{getEventIcon('BAC', 16, 24, HEX_COLORS.STABLE, undefined, false)}</figure> BAC Test Completed
        </li>
        <li style={{ marginLeft: 10 }}>
          <figure>{getEventIcon('MEDICATION_TAKE', 16, 24, HEX_COLORS.STABLE, undefined, false)}</figure> Medication Taken
        </li>
      </ul>
      <p style={{ marginTop: 20 }}>Row 3 - Dis-engagement: Events that the client has missed</p>
      <ul style={{ marginLeft: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <li>
          <figure>{getEventIcon('MEETING', 16, 24, HEX_COLORS.CRITICAL, undefined, false)}</figure> Missed meetings
        </li>
        <li style={{ marginLeft: 10 }}>
          <figure>{getEventIcon('SURVEY', 16, 24, HEX_COLORS.CRITICAL, undefined, false)}</figure> Missed surveys
        </li>
      </ul>
      <ul style={{ marginLeft: 20, display: 'flex', flexDirection: 'row' }}>
        <li>
          <figure>{getEventIcon('BAC', 16, 24, HEX_COLORS.CRITICAL, undefined, false)}</figure> Missed BAC tests
        </li>
        <li style={{ marginLeft: 10 }}>
          <figure>{getEventIcon('MEDICATION_TAKE', 16, 24, HEX_COLORS.CRITICAL, undefined, false)}</figure> Missed medications
        </li>
      </ul>
      <p style={{ marginTop: 20 }}>Row 4 - Accountability: Events that caused a violation, failure, or special location</p>
      <ul style={{ marginLeft: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <li>
          <figure>{getEventIcon('FAIL_BAC', 16, 24, HEX_COLORS.CRITICAL, undefined, false)}</figure> Failed BAC test
        </li>
        <li style={{ marginLeft: 10 }}>
          <figure>{getEventIcon('GEOFENCE', 16, 24, HEX_COLORS.CRITICAL, undefined, false)}</figure> Location violation
        </li>
      </ul>
      <ul style={{ marginLeft: 20, display: 'flex', flexDirection: 'row' }}>
        <li>
          <figure>{getEventIcon('GEOFENCE', 16, 24, HEX_COLORS.ACTIVITY, undefined, false)}</figure> Location special
        </li>
      </ul>
    </div>
  );
};

export default InfoBox;
