import React from 'react';

export const CameraIcon = props => {
  return (
    <svg width={props.width ?? 116} height='72' viewBox='0 0 116 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.4574 44.7302C44.4023 42.6799 48.4831 38.9801 51.0047 34.2609C53.5262 29.5418 54.3326 24.0951 53.2865 18.8486C52.2405 13.602 49.4066 8.88006 45.2675 5.48691C41.1285 2.09376 35.9403 0.239258 30.5865 0.239258C25.2327 0.239258 20.0445 2.09376 15.9055 5.48691C11.7664 8.88006 8.93252 13.602 7.88643 18.8486C6.84034 24.0951 7.64675 29.5418 10.1683 34.2609C12.6899 38.9801 16.7706 42.6799 21.7156 44.7302C11.3347 46.3063 3.228 50.5947 0 56.2027H61.2097C57.9939 50.607 49.7038 46.3429 39.4574 44.7302Z'
          fill='#6B6B6B'
        />
        <path
          opacity='0.2'
          d='M30.6414 72.0004C47.5305 72.0004 61.2218 69.9272 61.2218 67.3698C61.2218 64.8124 47.5305 62.7393 30.6414 62.7393C13.7523 62.7393 0.0610352 64.8124 0.0610352 67.3698C0.0610352 69.9272 13.7523 72.0004 30.6414 72.0004Z'
          fill='#999999'
        />
        <path
          d='M99.3953 41.0277L100.202 41.834L100.532 42.1639C101.157 41.926 101.691 41.4988 102.061 40.9421L99.3953 41.0277ZM98.1726 40.4779V42.0906C98.5821 42.2824 99.0287 42.3825 99.4809 42.3839C99.6843 42.4025 99.8889 42.4025 100.092 42.3839L98.2215 40.5267C98.2215 40.5267 98.1726 40.4534 98.1726 40.4779ZM99.4809 36.2627C99.2797 36.2643 99.0792 36.2847 98.8818 36.3238L100.753 38.1931V36.5559C100.354 36.371 99.9206 36.2712 99.4809 36.2627ZM96.7176 38.0099C96.5149 38.4201 96.4102 38.8718 96.4119 39.3294C96.4135 39.5304 96.4339 39.7309 96.473 39.9281L98.3438 38.0587L96.7176 38.0099ZM98.4661 36.4337C97.8336 36.6577 97.2951 37.0881 96.9377 37.6555H99.5787L98.4416 36.5071L98.4661 36.4337ZM102.489 38.7307L100.618 40.5878H102.22C102.421 40.1814 102.526 39.734 102.526 39.2805C102.529 39.0965 102.517 38.9126 102.489 38.7307ZM101.193 36.7758V39.4149L102.318 38.2786C102.085 37.6512 101.657 37.1155 101.095 36.7514L101.193 36.7758ZM96.5953 40.319C96.8234 40.9456 97.2536 41.4785 97.818 41.834V39.2316L96.5953 40.319Z'
          fill='#D6D6D6'
        />
        <path
          d='M102.391 30.2031H96.5708C95.2608 30.2031 94.1987 31.2643 94.1987 32.5734V32.5856C94.1987 33.8947 95.2608 34.9559 96.5708 34.9559H102.391C103.701 34.9559 104.763 33.8947 104.763 32.5856V32.5734C104.763 31.2643 103.701 30.2031 102.391 30.2031Z'
          fill='#6B6B6B'
        />
        <path
          d='M109.446 33.0498H89.5157C89.1557 33.0498 88.8105 33.1927 88.556 33.447C88.3014 33.7014 88.1584 34.0463 88.1584 34.406V44.3636C88.1584 44.7233 88.3014 45.0682 88.556 45.3225C88.8105 45.5769 89.1557 45.7198 89.5157 45.7198H109.446C109.806 45.7198 110.151 45.5769 110.406 45.3225C110.66 45.0682 110.803 44.7233 110.803 44.3636V34.406C110.803 34.0463 110.66 33.7014 110.406 33.447C110.151 33.1927 109.806 33.0498 109.446 33.0498ZM99.4809 43.0318C98.7554 43.0318 98.0462 42.8168 97.443 42.4141C96.8397 42.0113 96.3696 41.4389 96.0919 40.7691C95.8143 40.0994 95.7417 39.3624 95.8832 38.6514C96.0247 37.9404 96.3741 37.2873 96.8871 36.7746C97.4001 36.262 98.0537 35.9129 98.7653 35.7715C99.4768 35.6301 100.214 35.7027 100.885 35.9801C101.555 36.2575 102.128 36.7273 102.531 37.3301C102.934 37.9329 103.149 38.6415 103.149 39.3665C103.149 40.3386 102.763 41.2709 102.075 41.9583C101.387 42.6456 100.454 43.0318 99.4809 43.0318Z'
          fill='#6B6B6B'
        />
        <path
          d='M99.4078 16.0667L100.117 16.7753L100.41 17.0686C100.95 16.8714 101.412 16.5077 101.731 16.03L99.4078 16.0667ZM98.3317 15.5902V16.9952C98.6901 17.1678 99.0834 17.2555 99.4811 17.2518C99.6559 17.2707 99.8321 17.2707 100.007 17.2518L98.3807 15.6146L98.3317 15.5902ZM99.4811 11.9248C99.3064 11.9059 99.1301 11.9059 98.9553 11.9248L100.594 13.562V12.2181C100.249 12.0369 99.8699 11.9288 99.4811 11.9004V11.9248ZM97.0357 13.4521C96.8618 13.8097 96.7738 14.203 96.7789 14.6005C96.7602 14.7793 96.7602 14.9594 96.7789 15.1381L98.4907 13.4398L97.0357 13.4521ZM98.5641 12.0714C98.0184 12.2695 97.551 12.638 97.2313 13.1222H99.5423L98.5396 12.1203L98.5641 12.0714ZM102.086 14.0752L100.459 15.7124H101.865C102.039 15.3547 102.127 14.9615 102.122 14.5639C102.136 14.3929 102.132 14.2209 102.11 14.0507L102.086 14.0752ZM100.948 12.3769V14.6616L101.939 13.672C101.745 13.1276 101.381 12.6604 100.899 12.3402L100.948 12.3769ZM96.9623 15.4802C97.1558 16.0246 97.5204 16.4918 98.0016 16.812V14.5028L97.0112 15.5047L96.9623 15.4802Z'
          fill='#D6D6D6'
        />
        <path
          d='M110.583 10.5811H88.2197C85.2362 10.5811 82.803 13.3667 82.803 16.8V61.5052C82.803 64.9384 85.2484 67.7241 88.2197 67.7241H110.583C113.579 67.7241 116 64.9384 116 61.5052V16.8C116 13.3667 113.579 10.5811 110.583 10.5811ZM102.709 14.6618C102.714 15.3032 102.528 15.9316 102.175 16.4672C101.822 17.0029 101.317 17.4217 100.726 17.6705C100.134 17.9193 99.4819 17.9869 98.8517 17.8647C98.2216 17.7426 97.6419 17.4362 97.1863 16.9843C96.7307 16.5325 96.4197 15.9557 96.2927 15.327C96.1657 14.6982 96.2284 14.046 96.4729 13.4529C96.7174 12.8599 97.1327 12.3528 97.666 11.9959C98.1994 11.6391 98.8268 11.4485 99.4687 11.4485C99.8935 11.4437 100.315 11.5233 100.709 11.6827C101.102 11.8422 101.461 12.0783 101.762 12.3773C102.064 12.6764 102.303 13.0323 102.465 13.4245C102.628 13.8167 102.711 14.2373 102.709 14.6618ZM99.4076 65.9769C98.8283 65.9697 98.2641 65.7913 97.7861 65.4642C97.3081 65.1371 96.9377 64.6759 96.7216 64.1388C96.5055 63.6017 96.4533 63.0127 96.5716 62.446C96.69 61.8793 96.9735 61.3602 97.3866 60.9543C97.7996 60.5484 98.3236 60.2737 98.8927 60.1649C99.4617 60.056 100.05 60.118 100.584 60.3428C101.118 60.5676 101.573 60.9454 101.893 61.4283C102.212 61.9113 102.381 62.478 102.379 63.0569C102.377 63.444 102.299 63.827 102.148 64.1838C101.998 64.5405 101.778 64.864 101.502 65.1355C101.226 65.4069 100.899 65.6211 100.539 65.7655C100.18 65.9099 99.795 65.9818 99.4076 65.9769ZM112.943 56.3248C112.943 56.9999 112.676 57.6476 112.199 58.1261C111.722 58.6047 111.076 58.8751 110.4 58.8784H88.3908C87.7152 58.8751 87.0683 58.6047 86.5917 58.1261C86.1151 57.6476 85.8476 56.9999 85.8476 56.3248V21.1007C85.8476 20.4267 86.1155 19.7803 86.5925 19.3037C87.0694 18.8271 87.7163 18.5593 88.3908 18.5593H110.4C111.073 18.5625 111.718 18.8313 112.195 19.3072C112.671 19.7831 112.94 20.4276 112.943 21.1007V56.3248Z'
          fill='#6B6B6B'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='116' height='72' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
