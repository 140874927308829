import React, { FunctionComponent, useMemo } from 'react';
import styles from '../chart.module.scss';
import { displayDateTimeFormat, roundNumber } from '../../../../utils/data-parser';
import { ViviHealthComponentScores } from '../../../../interfaces/chart-results';
import { VivihealthScoreComponentWeight } from '../../../../utils/constants';
import { HighlightStrongOrLowScoreComponent } from '../utlity';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type VivihealthTooltipProps = {
  reverse: boolean;
  date: number;
  data: {
    value: number;
    components?: ViviHealthComponentScores;
  };
};

const VivihealthTooltip: FunctionComponent<VivihealthTooltipProps> = ({ reverse, date, data }) => {
  const timezone = getPatientTimezone();
  const calculateScoreComponentWeight = (component: number, weight: number) => roundNumber(component * weight, 1);
  const minMaxComponents: { lowerComponent: string; strongerComponent: string } = useMemo(() => {
    let componentList: { title: string; value: number }[] = [];
    if (data?.components) {
      componentList = [
        { value: data.components.engagement, title: 'engagement' },
        { value: data.components.mood, title: 'mood' },
        { value: data.components.sleep, title: 'sleep' },
        { value: data.components.stress, title: 'stress' },
        { value: (data.components.activityCount + data.components.exercise) / 2, title: 'activity' },
        { value: data.components.adherence, title: 'adherence' },
      ];
      componentList.sort((a, b) => {
        return a.value - b.value;
      });
    }
    return {
      lowerComponent: componentList[0]?.title,
      strongerComponent: componentList[componentList.length - 1]?.title,
    };
  }, [data]);

  return (
    <div className={`${styles.chartBigToolTip} ${reverse ? styles.reverse : ''}`}>
      <div className={styles.chartTooltipContent}>
        <span>{displayDateTimeFormat(date, timezone)}</span>
      </div>
      <div className={styles.chartToolTipTitle}>
        <span>VIVIHEALTH: {data.value}</span>
      </div>
      {!!data.components && (
        <div className={styles.chartTooltipComponentsContainer} style={{ marginLeft: '10px' }}>
          <span style={HighlightStrongOrLowScoreComponent('engagement', minMaxComponents)}>
            . Engagement:{' '}
            {calculateScoreComponentWeight(data.components.engagement, VivihealthScoreComponentWeight['engagement'])} /{' '}
            {VivihealthScoreComponentWeight['engagement']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('mood', minMaxComponents)}>
            . Mood: {calculateScoreComponentWeight(data.components.mood, VivihealthScoreComponentWeight['mood'])} /{' '}
            {VivihealthScoreComponentWeight['mood']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('sleep', minMaxComponents)}>
            . Sleep: {calculateScoreComponentWeight(data.components.sleep, VivihealthScoreComponentWeight['sleep'])} /{' '}
            {VivihealthScoreComponentWeight['sleep']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('stress', minMaxComponents)}>
            . Stress: {calculateScoreComponentWeight(data.components.stress, VivihealthScoreComponentWeight['stress'])}{' '}
            / {VivihealthScoreComponentWeight['stress']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('activity', minMaxComponents)}>
            . Activity:
            {calculateScoreComponentWeight(
              data.components.exercise + data.components.activityCount,
              VivihealthScoreComponentWeight['activity'],
            ) / 2}{' '}
            / {VivihealthScoreComponentWeight['activity']}%
          </span>
          <span style={HighlightStrongOrLowScoreComponent('adherence', minMaxComponents)}>
            . Adherence:{' '}
            {calculateScoreComponentWeight(data.components.adherence, VivihealthScoreComponentWeight['adherence'])} /{' '}
            {VivihealthScoreComponentWeight['adherence']}%
          </span>
        </div>
      )}
    </div>
  );
};

export default VivihealthTooltip;
