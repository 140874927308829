import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { RemoteData } from '../../../../utils/remote-data';
import { Meeting } from '../../../../interfaces/meeting';
import { useToasts } from 'react-toast-notifications';
import Modal from '../../../modal/Modal';
import { Form, Formik } from 'formik';
import { deleteMeetingValidator } from '../../../../utils/form-validations';
import { deleteMeeting } from '../../../../services/api/meeting';
import styles from './delete-meeting-form.module.scss';
import m from 'moment-timezone';
import { MeetingType } from '../../../../utils/constants';
import { RadioButton } from '../../../common/form';
import { displayRepeatInfo } from '../../../../utils/data-parser';
import { useParams } from 'react-router-dom';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  getMeetingAction?: () => void;
  selectedMeeting: RemoteData<Meeting>;
};

const DeleteMeetingForm: FunctionComponent<Props> = ({ setShow, selectedMeeting, getMeetingAction }) => {
  const { addToast } = useToasts();
  const timezone = getPatientTimezone();
  const { id }: { id: string } = useParams();
  const deleteMeetingValue = {
    updateType: '',
  };

  const handleDeleteMeeting = async (values: { updateType: string }) => {
    const result = await deleteMeeting(
      { updateType: values.updateType, patientId: id },
      selectedMeeting.status === 'Done' ? selectedMeeting.data.id.toString() : '',
    );
    if (result.status === 'Done') {
      addToast(`Meeting deleted successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
      getMeetingAction && getMeetingAction();
      setShow(false);
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  return (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      {selectedMeeting.status === 'Done' && (
        <Formik
          initialValues={deleteMeetingValue}
          validationSchema={deleteMeetingValidator}
          onSubmit={(values, { setSubmitting }) => {
            handleDeleteMeeting(values);
            setSubmitting(false);
          }}
        >
          {({ touched, errors }) => (
            <Form>
              <section className={styles.deleteMeetingModal}>
                <div className={`${styles.modalHeader}`}>
                  <h4>
                    DELETE MEETING: {selectedMeeting.data.title} -{' '}
                    {m(selectedMeeting.data.occurrenceStartTimestamp).isValid() &&
                      m.utc(selectedMeeting.data.occurrenceStartTimestamp).tz(timezone).format('MMM DD')}{' '}
                    {m.utc(selectedMeeting.data.occurrenceStartTimestamp).tz(timezone).format('hh:mm a')}
                    {' - '}
                    {m.utc(selectedMeeting.data.occurrenceEndTimestamp).tz(timezone).format('hh:mm a')}
                  </h4>
                </div>
                <div className={styles.meetingDetail}>
                  <div className={styles.box}>
                    <span>Address: </span>
                    {selectedMeeting.data.location?.address}
                  </div>
                  <div className={styles.box}>
                    <span>Meeting Type: </span>
                    {MeetingType[selectedMeeting.data.type]} meeting
                  </div>
                  <div className={styles.box}>
                    <span>Recurrence: </span>
                    {displayRepeatInfo(selectedMeeting.data.repeatWeekDays, selectedMeeting.data.repeatEndTimestamp)}
                  </div>
                  <div className={styles.box}>
                    <span>Scheduled by: </span>
                    {selectedMeeting.data.scheduledBy.type}
                  </div>
                </div>
                <div className={styles.mainSection}>
                  <div className={styles.mainText}>Are you sure you would like to delete this meeting?</div>
                  <p className={styles.secondaryText}>
                    Note: this cannot be undone. Once a meeting is deleted, it will be cleared from both the Clinician
                    web app (preceding list) and the Client Mobile app.
                  </p>
                  <div className={styles.applyChangesSection}>
                    <label className={styles.label}>Apply event changes to:</label>
                    <div className={styles.radioButtons}>
                      <RadioButton
                        name='updateType'
                        field='This event only'
                        value='SINGLE_EVENT'
                        displayError={false}
                      />
                      <RadioButton
                        name='updateType'
                        field='This and following events'
                        value='SINGLE_EVENT_AND_FUTURE_EVENTS'
                        displayError={false}
                      />
                      <RadioButton
                        name='updateType'
                        field='All future events'
                        value='ALL_FUTURE_EVENTS'
                        displayError={false}
                      />
                      {!!errors.updateType && touched.updateType && (
                        <span className={styles.errorCheckbox}>Please choose at least one option.</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.bottomButtons}>
                  <div className={styles.rightButtons}>
                    <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
                      Close
                    </button>
                    <button className={styles.submitBtn} type='submit'>
                      Confirm
                    </button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default DeleteMeetingForm;
