import React, { useMemo } from 'react';
import Table from '../common/table/table';
import styles from './admin-devices.module.scss';
import { Column } from 'react-table';
import { RemoteData } from '../../utils/remote-data';
import { IndistinguishableDevices } from '../../interfaces/device';
import { Patient } from '../../interfaces/patient';
import { displayFullName } from '../../utils/data-parser';

type Props = {
  devices: RemoteData<IndistinguishableDevices>;
};

const AssignedBacTable: React.FC<Props> = ({ devices }) => {
  const columns: Column<Patient>[] = useMemo(
    () => [
      {
        Header: 'PATIENT',
        accessor: originalRow => displayFullName(originalRow.name),
        width: 200,
      },
      {
        Header: 'BAC ASSIGNED',
        accessor: () => 'Yes',
        width: 150,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'PATIENT', desc: true }] }),
    [],
  );

  const getRowProps = (row, idx) => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = (cell, idx) => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
      style: {
        justifyContent: 'flex-start',
      },
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className:
        devices.status === 'Done' && !devices.data.values?.[0]?.assignedPatients ? styles.noContent : styles.table,
      style: {
        marginLeft: '18px',
      },
    };
  };

  return (
    <Table<Patient>
      columns={columns}
      data={devices.status === 'Done' && devices.data.values.length ? devices.data.values[0].assignedPatients : []}
      isLoading={devices.status === 'Loading'}
      error={devices.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={(row, idx) => getRowProps(row, idx)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={(cell, idx) => getCellProps(cell, idx)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 180, minWidth: 100 }}
      initialState={defaultSorting}
    />
  );
};

export default AssignedBacTable;
