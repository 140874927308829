import React, { useEffect, useState } from 'react';
import { loading, notAsked, RemoteData } from '../../../../utils/remote-data';
import { UserMobilePermissionsHistory } from '../../../../interfaces/user-mobile-permissions-history';
import { getPatientPermissionsHistory } from '../../../../services/api/patient';
import DatePicker, { DateRange } from '../../../common/date-picker/date-range-picker';
import { endOfMonth, startOfMonth } from 'date-fns';
import moment from 'moment-timezone';
import styles from '../../upsert-client.module.scss';
import { DropDown } from '../../../dropdown';
import PermissionsHistoryTable from './permissions-history-table';

type Props = {
  patientFirebaseUid: string;
};

const PermissionsHistoryContainer = ({ patientFirebaseUid }: Props) => {
  const [patientPermissionsHistory, setPatientPermissionsHistory] = useState<
    RemoteData<UserMobilePermissionsHistory[]>
  >(notAsked());
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });

  const getPatientPermissionsHistoryAction = async () => {
    setPatientPermissionsHistory(loading());
    const res = await getPatientPermissionsHistory(patientFirebaseUid, {
      startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
      endDate: moment(dateRangeFilter.endDate).utc().format('YYYY-MM-DD'),
      timezone: moment.tz.guess(),
    });
    setPatientPermissionsHistory(res);
  };

  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await getPatientPermissionsHistoryAction();
    })();
  }, [dateRangeFilter]);

  return (
    <div>
      <div className={styles.permissionsHeader}>
        <ul className={styles.navRight}>
          <li>
            <DropDown contentStyle='filter'>
              <span className={styles.dropdownButton}>
                {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
                {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
                <i className={styles.arrow}>
                  <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                  </svg>
                </i>
              </span>
              <>
                <DatePicker
                  onDateSelectionChange={onDateChange}
                  dateRange={dateRangeFilter}
                  parentStyle={styles.filterDatepicker}
                />
              </>
            </DropDown>
          </li>
        </ul>
      </div>
      <PermissionsHistoryTable userPermissionsHistory={patientPermissionsHistory} />
    </div>
  );
};

export default PermissionsHistoryContainer;
