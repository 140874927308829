import moment from 'moment-timezone';

type QueryParams = {
  [param: string]: string;
};
export interface FilterRequest {
  [key: string]: boolean;
}

// Date Filters
export interface DateFiltersRequest {
  startDate?: string;
  endDate?: string;
}
export type DateTimeFiltersRequest = DateFiltersRequest & { timezone?: string };

export const castTimeFiltersToParams = (req?: DateFiltersRequest): QueryParams => {
  if (!req) {
    return {};
  }
  const startDateTime =
    req.startDate && moment(req.startDate).isValid() ? moment(req.startDate).utc().format('YYYY-MM-DD') : '';
  const endDateTime =
    req.startDate && moment(req.endDate).isValid() ? moment(req.endDate).utc().format('YYYY-MM-DD') : '';

  return { startDateTime, endDateTime };
};

export const castDateTimeFilters = (req?: DateTimeFiltersRequest): QueryParams => {
  if (!req) {
    return {};
  }
  const startDateTime = moment(req.startDate).isValid()
    ? moment(req.startDate).tz(req.timezone!, true).utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    : '';
  const endDateTime = moment(req.endDate).isValid()
    ? moment(req.endDate).add(1, 'days').tz(req.timezone!, true).utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    : '';
  return { startDateTime, endDateTime };
};
