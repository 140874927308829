import {  GoogleMap,  useLoadScript } from '@react-google-maps/api';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {  Loading } from '../loading';
/// <reference types="@types/googlemaps" />

type Props = {
  mapContainerStyle: React.CSSProperties;
  markers: google.maps.LatLngLiteral[];
  fitBounds: boolean;
  mapOptions: google.maps.MapOptions;
};

const Map: FunctionComponent<Props> = props => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY || '',
    language: 'en',
    version: '3',
    preventGoogleFontsLoading: true,
  });
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const options: google.maps.MapOptions = useMemo(
    () => ({
      zoomControlOptions: {
        position: 9,
      },
      scrollwheel: true,
      clickableIcons: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite'],
        position: 5, // google.maps.ControlPosition.LEFT_TOP
      },
      ...props.mapOptions,
    }),
    [],
  );

  useEffect(() => {
    fitBounds();
  }, [props.markers]);

  const onUnmount = useCallback(map => {
    setMap(undefined);
  }, []);

  const onLoad = useCallback((map: google.maps.Map) => {
    if (props.fitBounds) {
      const bounds = new window.google.maps.LatLngBounds();
      props.markers.map((place, i) => {
        bounds.extend(new window.google.maps.LatLng(place.lat, place.lng));
      });
      map.fitBounds(bounds);
    }
    google.maps.event.addListenerOnce(map, 'idle', () => {
      const zoomLevel = map.getZoom();
      if (typeof zoomLevel == 'number' && zoomLevel > 19) {
        map!.setZoom(13);
      }
    });
    setMap(map);
  }, []);

  const fitBounds = () => {
    if (map) {
      if (props.fitBounds) {
        const bounds = new window.google.maps.LatLngBounds();
        props.markers.map((place, i) => {
          bounds.extend(new window.google.maps.LatLng(place.lat, place.lng));
        });
        map!.fitBounds(bounds);
        setMap(map);
      }
    }
  };

  const renderMap = () => {
    return (
      <GoogleMap
        id='vivihealth-map'
        options={options}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerStyle={props.mapContainerStyle}
      >
        {props.children}
      </GoogleMap>
    );
  };
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Loading style={{ padding: '150px 0' }} />;
};

export default Map;
