import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PractitionerContext } from '../../contexts/Practitioner';
import { Patients } from '../../interfaces/patient';
import { SMSConfig as SMSConfigDTO } from '../../interfaces/sms-config';
import { getPractitionerPatients } from '../../services/api/patient';
import { createPractitioner, editPractitioner, getSMSConfiguration } from '../../services/api/practitioner';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import UpsertPractitionerForm from '../admin-practitioners/upsert-practitioner-form';

export default function withUserProfile(Component) {
  return () => {
    const { practitioner, reloadPractitioner } = useContext(PractitionerContext);

    const [showProfile, setShowProfile] = useState<boolean>(false);
    const [practitionerPatients, setPractitionerPatients] = useState<RemoteData<Patients>>(notAsked());
    const [SMSConfig, setSMSConfig] = useState<RemoteData<SMSConfigDTO>>(notAsked());

    const getPractitionerPatientsAction = useCallback(async (): Promise<void> => {
      if (practitioner && showProfile) {
        setPractitionerPatients(loading());
        const res = await getPractitionerPatients(practitioner.id);
        setPractitionerPatients(res);
      }
    }, [practitioner, showProfile]);

    const getSMSConfigurationAction = useCallback(async (): Promise<void> => {
      if (practitioner && showProfile) {
        setSMSConfig(loading());
        const res = await getSMSConfiguration(practitioner.id);
        setSMSConfig(res);
      }
    }, [practitioner, showProfile]);

    useEffect(() => {
      if (practitioner) {
        getPractitionerPatientsAction();
      }
    }, [practitioner, getPractitionerPatientsAction]);

    return (
      <>
        <Component setShowProfile={setShowProfile} />
        {showProfile && (
          <UpsertPractitionerForm
            createPractitioner={createPractitioner}
            setShow={setShowProfile}
            editPractitioner={editPractitioner}
            organizationId={practitioner?.organization.id}
            getPractitionerPatientsAction={getPractitionerPatientsAction}
            editMode={true}
            selectedPractitioner={practitioner}
            isAuthenticatedPractitionerProfile={true}
            selectedPractitionerPatients={practitionerPatients}
            showRemovePractitionerButton={false}
            getPractitionersAction={reloadPractitioner}
            smsConfig={SMSConfig}
            getSMSConfigurationAction={getSMSConfigurationAction}
          />
        )}
      </>
    );
  };
}
