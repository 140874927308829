export const complianceIndicatorToolTipTitle: string = 'Compliance Indicator';
export const complianceIndicatorToolTipDescription: string = `The Compliance Indicator is an approximate measurement
   informed by a combination of client factors. The system 
   continuously observes and calculates the Compliance 
   percentage based on events and client trends. The lower the percentage, the lower the client’s compliance with their program,
   which may be an indication of increased risk of relapse.`;
export const complianceIndicatorToolTipDescriptionP2: string =   `The Compliance Indicator is currently made up of several factors:`
export const toolTipListItemStrings: string[] = [
    '• BAC tests: including failed, missed, and passed BAC tests',
    '• Meeting attendance',
    '• Sleep duration',
  ];