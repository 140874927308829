import { gql } from '@apollo/client';

export const GET_PATIENT_QUESTIONNAIRES = gql`
  query Questionnaires($id: String!, $start: Instant!, $end: Instant!) {
    questionnairesOfUser(fbUserId: $id, start: $start, end: $end) {
      id
      status
      created
      modified
      value
      questionnaire {
        id
        name
        clinicName
        groupName
      }
      questions {
        id
        text
        subtext
        designType
        answer {
          id
          value
          created
        }
        answered
        modified
        availableAnswers {
          id
          text
        }
        subQuestions {
          id
          text
          subtext
          designType
          answer {
            id
            value
            created
          }
          availableAnswers {
            id
            text
          }
        }
      }
    }
  }
`;
