import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { LastViewed, Patient as PatientDto } from '../interfaces/patient';
import { UserContext } from './User';
import { getPatientById } from '../services/api/patient';

const PatientContext = createContext<{
  patient: PatientDto | undefined;
  setPatient?: Dispatch<SetStateAction<PatientDto | undefined>>;
}>({
  patient: undefined,
});

/* eslint-disable */
export const getPatientLastViewedPractitioner = (): LastViewed => {
  const { patient } = useContext(PatientContext);
  return patient?.lastViewed!;
};

//NOTE: patient timezone is coming from mobile as an offset with number format e.g: -05:00
export const getPatientTimezone = (): string => {
  const { patient } = useContext(PatientContext);
  return patient?.tzName ?? moment.tz.guess();
};

const Patient: FunctionComponent = props => {
  const [patient, setPatient] = useState<PatientDto | undefined>(undefined);
  const { user } = useContext(UserContext);
  const { id } = useParams<{ id: string }>();

  const getPatient = async () => {
    const patientResponse = await getPatientById(id!);
    setPatient(patientResponse.data);
  };

  useEffect(() => {
    if (user) {
      getPatient();
    }
  }, [user]);

  return <PatientContext.Provider value={{ patient, setPatient }}>{props.children}</PatientContext.Provider>;
};

export { Patient, PatientContext };
