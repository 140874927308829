import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ClientStatsReportFieldsFragmentDoc = gql`
  fragment clientStatsReportFields on OrganizationPeriodReport {
    activeClients
    pendingClients
    episodesOfCare {
      episodeOfCareType
      count
    }
    clientTelemetryCounts {
      name
      count
    }
  }
`;
export const ClientsByPractitionerFieldsFragmentDoc = gql`
  fragment clientsByPractitionerFields on PractitionerClientAssignment {
    count
    practitioner {
      id
      name {
        first
        last
        prefix
      }
    }
  }
`;
export const RequestCustomerSurveyDocument = gql`
  mutation RequestCustomerSurvey($fbId: String!, $qId: Int!, $after: Int!) {
    createQuestionnaireInstance(input: { fbUserUid: $fbId, questionnaireId: $qId, timeFromNowInSeconds: $after }) {
      instance {
        id
      }
    }
  }
`;
export type RequestCustomerSurveyMutationFn = Apollo.MutationFunction<
  Types.RequestCustomerSurveyMutation,
  Types.RequestCustomerSurveyMutationVariables
>;

/**
 * __useRequestCustomerSurveyMutation__
 *
 * To run a mutation, you first call `useRequestCustomerSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCustomerSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCustomerSurveyMutation, { data, loading, error }] = useRequestCustomerSurveyMutation({
 *   variables: {
 *      fbId: // value for 'fbId'
 *      qId: // value for 'qId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useRequestCustomerSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RequestCustomerSurveyMutation,
    Types.RequestCustomerSurveyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RequestCustomerSurveyMutation, Types.RequestCustomerSurveyMutationVariables>(
    RequestCustomerSurveyDocument,
    options,
  );
}
export type RequestCustomerSurveyMutationHookResult = ReturnType<typeof useRequestCustomerSurveyMutation>;
export type RequestCustomerSurveyMutationResult = Apollo.MutationResult<Types.RequestCustomerSurveyMutation>;
export type RequestCustomerSurveyMutationOptions = Apollo.BaseMutationOptions<
  Types.RequestCustomerSurveyMutation,
  Types.RequestCustomerSurveyMutationVariables
>;
export const CreateScheduleDocument = gql`
  mutation CreateSchedule($input: QuestionnaireScheduleInput!) {
    addSchedule(input: $input) {
      __typename
      ... on OperationError {
        error
        message
        code
      }
      ... on SuccessQuestionnaireScheduleOperation {
        error
        data {
          scheduleId
          cron
          repeat {
            untilWeeks
            until
          }
          questionnaireName
          questionnaire {
            id
            clinicName
          }
        }
      }
    }
  }
`;
export type CreateScheduleMutationFn = Apollo.MutationFunction<
  Types.CreateScheduleMutation,
  Types.CreateScheduleMutationVariables
>;

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleMutation, { data, loading, error }] = useCreateScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateScheduleMutation, Types.CreateScheduleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateScheduleMutation, Types.CreateScheduleMutationVariables>(
    CreateScheduleDocument,
    options,
  );
}
export type CreateScheduleMutationHookResult = ReturnType<typeof useCreateScheduleMutation>;
export type CreateScheduleMutationResult = Apollo.MutationResult<Types.CreateScheduleMutation>;
export type CreateScheduleMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateScheduleMutation,
  Types.CreateScheduleMutationVariables
>;
export const UpdateQuestionnaireScheduleDocument = gql`
  mutation UpdateQuestionnaireSchedule($id: Int!, $input: QuestionnaireScheduleInput!) {
    updateSchedule(id: $id, input: $input) {
      __typename
      ... on OperationError {
        error
        message
        code
      }
      ... on SuccessQuestionnaireScheduleOperation {
        error
        data {
          scheduleId
          cron
          repeat {
            untilWeeks
            until
          }
          questionnaireName
          questionnaire {
            id
            clinicName
          }
        }
      }
    }
  }
`;
export type UpdateQuestionnaireScheduleMutationFn = Apollo.MutationFunction<
  Types.UpdateQuestionnaireScheduleMutation,
  Types.UpdateQuestionnaireScheduleMutationVariables
>;

/**
 * __useUpdateQuestionnaireScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireScheduleMutation, { data, loading, error }] = useUpdateQuestionnaireScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionnaireScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateQuestionnaireScheduleMutation,
    Types.UpdateQuestionnaireScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateQuestionnaireScheduleMutation,
    Types.UpdateQuestionnaireScheduleMutationVariables
  >(UpdateQuestionnaireScheduleDocument, options);
}
export type UpdateQuestionnaireScheduleMutationHookResult = ReturnType<typeof useUpdateQuestionnaireScheduleMutation>;
export type UpdateQuestionnaireScheduleMutationResult =
  Apollo.MutationResult<Types.UpdateQuestionnaireScheduleMutation>;
export type UpdateQuestionnaireScheduleMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateQuestionnaireScheduleMutation,
  Types.UpdateQuestionnaireScheduleMutationVariables
>;
export const DeleteQuestionnaireScheduleDocument = gql`
  mutation DeleteQuestionnaireSchedule($id: Int!) {
    removeSchedule(id: $id) {
      __typename
      ... on OperationError {
        error
        message
        code
      }
      ... on SuccessQuestionnaireScheduleOperation {
        error
        data {
          scheduleId
        }
      }
    }
  }
`;
export type DeleteQuestionnaireScheduleMutationFn = Apollo.MutationFunction<
  Types.DeleteQuestionnaireScheduleMutation,
  Types.DeleteQuestionnaireScheduleMutationVariables
>;

/**
 * __useDeleteQuestionnaireScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireScheduleMutation, { data, loading, error }] = useDeleteQuestionnaireScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionnaireScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteQuestionnaireScheduleMutation,
    Types.DeleteQuestionnaireScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteQuestionnaireScheduleMutation,
    Types.DeleteQuestionnaireScheduleMutationVariables
  >(DeleteQuestionnaireScheduleDocument, options);
}
export type DeleteQuestionnaireScheduleMutationHookResult = ReturnType<typeof useDeleteQuestionnaireScheduleMutation>;
export type DeleteQuestionnaireScheduleMutationResult =
  Apollo.MutationResult<Types.DeleteQuestionnaireScheduleMutation>;
export type DeleteQuestionnaireScheduleMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteQuestionnaireScheduleMutation,
  Types.DeleteQuestionnaireScheduleMutationVariables
>;
export const ClientStatsReportDocument = gql`
  query ClientStatsReport(
    $orgReportGroups: ClientCountRequestInput
    $id: String
    $startOfDay: LocalDateTime
    $endOfDay: LocalDateTime
    $endLastWeek: LocalDateTime
    $startLastWeek: LocalDateTime
    $startLastMonth: LocalDateTime
    $startOfMonth: LocalDateTime
    $metric: PTSMetric
  ) {
    organizationReport(id: $id) {
      organizationId
      today: periodReport(start: $startOfDay, end: $endOfDay, metric: $metric, request: $orgReportGroups) {
        ...clientStatsReportFields
      }
      thisWeek: periodReport(start: $endLastWeek, end: $startOfDay, metric: $metric, request: $orgReportGroups) {
        ...clientStatsReportFields
      }
      lastWeek: periodReport(start: $startLastWeek, end: $endLastWeek, metric: $metric, request: $orgReportGroups) {
        ...clientStatsReportFields
      }
      thisMonth: periodReport(start: $startOfMonth, end: $startOfDay, metric: $metric, request: $orgReportGroups) {
        ...clientStatsReportFields
      }
      lastMonth: periodReport(start: $startLastMonth, end: $startOfMonth, metric: $metric, request: $orgReportGroups) {
        ...clientStatsReportFields
      }
      clientsInTakeByMonth(monthsBefore: 12) {
        intakeByMonth {
          count
          month
        }
      }
    }
  }
  ${ClientStatsReportFieldsFragmentDoc}
`;

/**
 * __useClientStatsReportQuery__
 *
 * To run a query within a React component, call `useClientStatsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientStatsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientStatsReportQuery({
 *   variables: {
 *      orgReportGroups: // value for 'orgReportGroups'
 *      id: // value for 'id'
 *      startOfDay: // value for 'startOfDay'
 *      endOfDay: // value for 'endOfDay'
 *      endLastWeek: // value for 'endLastWeek'
 *      startLastWeek: // value for 'startLastWeek'
 *      startLastMonth: // value for 'startLastMonth'
 *      startOfMonth: // value for 'startOfMonth'
 *      metric: // value for 'metric'
 *   },
 * });
 */
export function useClientStatsReportQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ClientStatsReportQuery, Types.ClientStatsReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ClientStatsReportQuery, Types.ClientStatsReportQueryVariables>(
    ClientStatsReportDocument,
    options,
  );
}
export function useClientStatsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ClientStatsReportQuery, Types.ClientStatsReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ClientStatsReportQuery, Types.ClientStatsReportQueryVariables>(
    ClientStatsReportDocument,
    options,
  );
}
export type ClientStatsReportQueryHookResult = ReturnType<typeof useClientStatsReportQuery>;
export type ClientStatsReportLazyQueryHookResult = ReturnType<typeof useClientStatsReportLazyQuery>;
export type ClientStatsReportQueryResult = Apollo.QueryResult<
  Types.ClientStatsReportQuery,
  Types.ClientStatsReportQueryVariables
>;
export const DemographicsReportDocument = gql`
  query DemographicsReport($id: String, $date: LocalDate) {
    organizationReport(id: $id) {
      organizationId
      demographics(date: $date) {
        items {
          variable
          name
          count
          children {
            variable
            name
            count
            children {
              variable
              name
              count
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDemographicsReportQuery__
 *
 * To run a query within a React component, call `useDemographicsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemographicsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemographicsReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDemographicsReportQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DemographicsReportQuery, Types.DemographicsReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DemographicsReportQuery, Types.DemographicsReportQueryVariables>(
    DemographicsReportDocument,
    options,
  );
}
export function useDemographicsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.DemographicsReportQuery, Types.DemographicsReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DemographicsReportQuery, Types.DemographicsReportQueryVariables>(
    DemographicsReportDocument,
    options,
  );
}
export type DemographicsReportQueryHookResult = ReturnType<typeof useDemographicsReportQuery>;
export type DemographicsReportLazyQueryHookResult = ReturnType<typeof useDemographicsReportLazyQuery>;
export type DemographicsReportQueryResult = Apollo.QueryResult<
  Types.DemographicsReportQuery,
  Types.DemographicsReportQueryVariables
>;
export const PractitionerReportDocument = gql`
  query PractitionerReport(
    $id: String
    $startOfDay: LocalDateTime
    $endOfDay: LocalDateTime
    $endLastWeek: LocalDateTime
    $startLastWeek: LocalDateTime
    $startLastMonth: LocalDateTime
    $metric: PTSMetric
    $orgReportGroups: ClientCountRequestInput
  ) {
    organizationReport(id: $id) {
      organizationId
      today: periodReport(start: $startOfDay, end: $endOfDay, metric: $metric, request: $orgReportGroups) {
        clientsByPractitioner {
          ...clientsByPractitionerFields
        }
      }
      lastWeek: periodReport(start: $startLastWeek, end: $endLastWeek, metric: $metric, request: $orgReportGroups) {
        clientsByPractitioner {
          ...clientsByPractitionerFields
        }
      }
      lastMonth: periodReport(start: $startLastMonth, end: $startOfDay, metric: $metric, request: $orgReportGroups) {
        clientsByPractitioner {
          ...clientsByPractitionerFields
        }
      }
      practitionerByRole {
        role
        count
      }
      devices {
        deviceType
        allCount
        used
      }
    }
  }
  ${ClientsByPractitionerFieldsFragmentDoc}
`;

/**
 * __usePractitionerReportQuery__
 *
 * To run a query within a React component, call `usePractitionerReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePractitionerReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePractitionerReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startOfDay: // value for 'startOfDay'
 *      endOfDay: // value for 'endOfDay'
 *      endLastWeek: // value for 'endLastWeek'
 *      startLastWeek: // value for 'startLastWeek'
 *      startLastMonth: // value for 'startLastMonth'
 *      metric: // value for 'metric'
 *      orgReportGroups: // value for 'orgReportGroups'
 *   },
 * });
 */
export function usePractitionerReportQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PractitionerReportQuery, Types.PractitionerReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PractitionerReportQuery, Types.PractitionerReportQueryVariables>(
    PractitionerReportDocument,
    options,
  );
}
export function usePractitionerReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PractitionerReportQuery, Types.PractitionerReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PractitionerReportQuery, Types.PractitionerReportQueryVariables>(
    PractitionerReportDocument,
    options,
  );
}
export type PractitionerReportQueryHookResult = ReturnType<typeof usePractitionerReportQuery>;
export type PractitionerReportLazyQueryHookResult = ReturnType<typeof usePractitionerReportLazyQuery>;
export type PractitionerReportQueryResult = Apollo.QueryResult<
  Types.PractitionerReportQuery,
  Types.PractitionerReportQueryVariables
>;
export const OrganizationDocument = gql`
  query Organization($id: String!) {
    org: organization(id: $id) {
      id
      name
      organizationTheme {
        images {
          url
          name
        }
      }
      contact {
        phone
        email
        address {
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      primaryContact {
        name {
          first
          last
          prefix
        }
        phone
        email
      }
      type
    }
  }
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<Types.OrganizationQuery, Types.OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.OrganizationQuery, Types.OrganizationQueryVariables>(OrganizationDocument, options);
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationQuery, Types.OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.OrganizationQuery, Types.OrganizationQueryVariables>(OrganizationDocument, options);
}
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<Types.OrganizationQuery, Types.OrganizationQueryVariables>;
export const QuestionnairesDocument = gql`
  query Questionnaires($id: String!, $start: Instant!, $end: Instant!) {
    questionnairesOfUser(fbUserId: $id, start: $start, end: $end) {
      id
      status
      created
      modified
      value
      questionnaire {
        id
        name
        clinicName
        groupName
      }
      questions {
        id
        text
        subtext
        designType
        answer {
          id
          value
          created
        }
        answered
        modified
        availableAnswers {
          id
          text
        }
        subQuestions {
          id
          text
          subtext
          designType
          answer {
            id
            value
            created
          }
          availableAnswers {
            id
            text
          }
        }
      }
    }
  }
`;

/**
 * __useQuestionnairesQuery__
 *
 * To run a query within a React component, call `useQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useQuestionnairesQuery(
  baseOptions: Apollo.QueryHookOptions<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>(
    QuestionnairesDocument,
    options,
  );
}
export function useQuestionnairesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>(
    QuestionnairesDocument,
    options,
  );
}
export type QuestionnairesQueryHookResult = ReturnType<typeof useQuestionnairesQuery>;
export type QuestionnairesLazyQueryHookResult = ReturnType<typeof useQuestionnairesLazyQuery>;
export type QuestionnairesQueryResult = Apollo.QueryResult<
  Types.QuestionnairesQuery,
  Types.QuestionnairesQueryVariables
>;
export const AvailableQuestionnairesDocument = gql`
  query AvailableQuestionnaires {
    questionnaires {
      id
      name
      version
      clinicName
      groupName
      description
    }
  }
`;

/**
 * __useAvailableQuestionnairesQuery__
 *
 * To run a query within a React component, call `useAvailableQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableQuestionnairesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.AvailableQuestionnairesQuery,
    Types.AvailableQuestionnairesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AvailableQuestionnairesQuery, Types.AvailableQuestionnairesQueryVariables>(
    AvailableQuestionnairesDocument,
    options,
  );
}
export function useAvailableQuestionnairesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AvailableQuestionnairesQuery,
    Types.AvailableQuestionnairesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AvailableQuestionnairesQuery, Types.AvailableQuestionnairesQueryVariables>(
    AvailableQuestionnairesDocument,
    options,
  );
}
export type AvailableQuestionnairesQueryHookResult = ReturnType<typeof useAvailableQuestionnairesQuery>;
export type AvailableQuestionnairesLazyQueryHookResult = ReturnType<typeof useAvailableQuestionnairesLazyQuery>;
export type AvailableQuestionnairesQueryResult = Apollo.QueryResult<
  Types.AvailableQuestionnairesQuery,
  Types.AvailableQuestionnairesQueryVariables
>;
export const QuestionnaireSchedulesOfUserDocument = gql`
  query QuestionnaireSchedulesOfUser($fbId: String!) {
    questionnaireSchedulesOfUser(fbUserId: $fbId) {
      scheduleId
      name
      patientFbUserId
      cron
      cronDescription
      repeat {
        at
        repeatOn
        untilWeeks
        until
        frequencyName
        dayOfMonth
        frequencyDisplayName
        frequencyDescription
      }
      questionnaireName
      questionnaire {
        id
        clinicName
      }
      notificationTitle
      notificationMessage
      timezoneOffset
      created
      createdBy
      modified
      modifiedBy
      endDate
    }
  }
`;

/**
 * __useQuestionnaireSchedulesOfUserQuery__
 *
 * To run a query within a React component, call `useQuestionnaireSchedulesOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireSchedulesOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireSchedulesOfUserQuery({
 *   variables: {
 *      fbId: // value for 'fbId'
 *   },
 * });
 */
export function useQuestionnaireSchedulesOfUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.QuestionnaireSchedulesOfUserQuery,
    Types.QuestionnaireSchedulesOfUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.QuestionnaireSchedulesOfUserQuery, Types.QuestionnaireSchedulesOfUserQueryVariables>(
    QuestionnaireSchedulesOfUserDocument,
    options,
  );
}
export function useQuestionnaireSchedulesOfUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.QuestionnaireSchedulesOfUserQuery,
    Types.QuestionnaireSchedulesOfUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.QuestionnaireSchedulesOfUserQuery, Types.QuestionnaireSchedulesOfUserQueryVariables>(
    QuestionnaireSchedulesOfUserDocument,
    options,
  );
}
export type QuestionnaireSchedulesOfUserQueryHookResult = ReturnType<typeof useQuestionnaireSchedulesOfUserQuery>;
export type QuestionnaireSchedulesOfUserLazyQueryHookResult = ReturnType<
  typeof useQuestionnaireSchedulesOfUserLazyQuery
>;
export type QuestionnaireSchedulesOfUserQueryResult = Apollo.QueryResult<
  Types.QuestionnaireSchedulesOfUserQuery,
  Types.QuestionnaireSchedulesOfUserQueryVariables
>;
export const ScheduleFrequenciesDocument = gql`
  query ScheduleFrequencies {
    scheduleFrequencies {
      name
      displayName
      cronExpression
    }
  }
`;

/**
 * __useScheduleFrequenciesQuery__
 *
 * To run a query within a React component, call `useScheduleFrequenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleFrequenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleFrequenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduleFrequenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ScheduleFrequenciesQuery, Types.ScheduleFrequenciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ScheduleFrequenciesQuery, Types.ScheduleFrequenciesQueryVariables>(
    ScheduleFrequenciesDocument,
    options,
  );
}
export function useScheduleFrequenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ScheduleFrequenciesQuery, Types.ScheduleFrequenciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ScheduleFrequenciesQuery, Types.ScheduleFrequenciesQueryVariables>(
    ScheduleFrequenciesDocument,
    options,
  );
}
export type ScheduleFrequenciesQueryHookResult = ReturnType<typeof useScheduleFrequenciesQuery>;
export type ScheduleFrequenciesLazyQueryHookResult = ReturnType<typeof useScheduleFrequenciesLazyQuery>;
export type ScheduleFrequenciesQueryResult = Apollo.QueryResult<
  Types.ScheduleFrequenciesQuery,
  Types.ScheduleFrequenciesQueryVariables
>;
export const MyPractitionerDocument = gql`
  query MyPractitioner {
    myPractitioner {
      id
      firebaseUid
      active
      acceptedTermsOfUse
      name {
        first
        last
        prefix
      }
      contact {
        cellphone
        homePhone
        email
        homeAddress {
          line1
          line2
          city
          state
          postalCode
          country
        }
        workAddress {
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      organization {
        id
        name
        organizationTheme {
          images {
            name
            url
          }
        }
      }
      roles {
        organizationId
        roles {
          code
          display
        }
      }
      viewSettings {
        triageView
      }
      image
    }
  }
`;

/**
 * __useMyPractitionerQuery__
 *
 * To run a query within a React component, call `useMyPractitionerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPractitionerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPractitionerQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPractitionerQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MyPractitionerQuery, Types.MyPractitionerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MyPractitionerQuery, Types.MyPractitionerQueryVariables>(
    MyPractitionerDocument,
    options,
  );
};
export function useMyPractitionerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MyPractitionerQuery, Types.MyPractitionerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.MyPractitionerQuery, Types.MyPractitionerQueryVariables>(
    MyPractitionerDocument,
    options,
  );
};
export type MyPractitionerQueryHookResult = ReturnType<typeof useMyPractitionerQuery>;
export type MyPractitionerLazyQueryHookResult = ReturnType<typeof useMyPractitionerLazyQuery>;
export type MyPractitionerQueryResult = Apollo.QueryResult<
  Types.MyPractitionerQuery,
  Types.MyPractitionerQueryVariables
>;

export const StepsDocument = gql`
  query Steps ($id: String!, $start: LocalDateTime, $end: LocalDateTime, $tz: String!, $maxPoints: Int! ) {
    user_steps(id: $id, start: $start, end: $end, tz: $tz, maxPoints: $maxPoints ) {
      id
      ts
      count
    }
  }
`;
/**
 * __useStepsQuery__
 *
 * To run a query within a React component, call `useStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepsQuery({
 *   variables: {
 *      patientId: // value for 'patientId',
 *      maxPoints: // value for 'maxPoints',
 *      start: // value for 'start',
 *      end: // value for 'end',
 *      tz: // value for 'tz'
 *   },
 * });
 */
export function useStepsQuery(baseOptions: Apollo.QueryHookOptions<Types.StepsQuery, Types.StepsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(StepsDocument, options);
}

export const StepsAvgDocument = gql`query StepsAverages ($id: String!, $tz: String!) {
  user_steps_averages(id: $id, tz: $tz){
      averagePerDay
      duration
      to
    }
}`

export function useStepsAvgQuery(baseOptions: Apollo.QueryHookOptions<Types.StepsAvgQuery, Types.StepsAvgQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(StepsAvgDocument, options);
}