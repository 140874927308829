import React from 'react';

export const TableErrorIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='122' height='125' fill='none' viewBox='0 0 12 10'>
      <path
        fill='#ED6F6A'
        fillRule='evenodd'
        d='M6.89.568l4.66 7.73C12 9.053 11.437 10 10.505 10H1.187C.284 10-.309 9.054.171 8.297L4.83.567c.451-.756 1.609-.756 2.06 0zm-.62.35a.466.466 0 00-.82 0L.793 8.649c-.198.298.028.676.395.676h9.318c.395 0 .621-.378.423-.675L6.27.919z'
        clipRule='evenodd'
      />
      <path
        fill='#ED6F6A'
        fillRule='evenodd'
        d='M5.846 2.73c.225 0 .423.162.423.378v3.108c0 .217-.198.379-.423.379-.198 0-.396-.162-.396-.379V3.108c0-.216.198-.378.396-.378zM5.845 7.054c.282 0 .508.216.508.486s-.226.487-.508.487a.496.496 0 01-.509-.487c0-.27.226-.486.509-.486z'
        clipRule='evenodd'
      />
    </svg>
  );
};
