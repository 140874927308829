import React from 'react';
import styles from 'components/admin-dashboard/practitioner-device-stats-container.module.scss';
import { USER_ROLES } from '../../../utils/constants';
import { Maybe, PractitionerRoleCount } from '../../../graphql/codegen/schemas';

type Props = {
  practitionerStats?: {
    clinician: PractitionerRoleCount;
    admin: PractitionerRoleCount;
    aftercareManager: PractitionerRoleCount;
  };
};

const PractitionerStats: React.FC<Props> = ({
  practitionerStats = {
    admin: { count: 0, role: 'admin' },
    clinician: { count: 0, role: 'clinician' },
    aftercareManager: { count: 0, role: 'aftercareManager' },
  },
}) => {
  return (
    <div className={styles.statsCard}>
      <span className={styles.title}>PRACTITIONER STATS</span>
      <div className={styles.dataRow}>
        <strong>Role</strong>
        <strong># Practitioners</strong>
        {/* <strong>Additional Role</strong> */}
      </div>
      <div className={styles.dataRow}>
        <span>{USER_ROLES.CLINICIAN}:</span>
        <span>{practitionerStats.clinician.count}</span>
        {/* <span>{practitionerStats.admin.count} - Admin/Mgmt/IT</span> */}
      </div>
      <div className={styles.dataRow}>
        <span>{USER_ROLES.ADMIN}:</span>
        <span>{practitionerStats.admin.count}</span>
      </div>
      <div className={styles.dataRow}>
        <span>{USER_ROLES.AFTERCARE_MANAGER}:</span>
        <span>{practitionerStats?.aftercareManager.count}</span>
      </div>
      <div className={styles.dataRow}>
        {/* <strong>Total</strong>
        <span>
          {practitionerStats.admin.count +
            practitionerStats?.clinician.count +
            practitionerStats.aftercareManager.count}
        </span> */}
      </div>
    </div>
  );
};

export default PractitionerStats; /**/
