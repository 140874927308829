import React, { FunctionComponent } from 'react';
import styles from './table-state.module.scss';
import { TableErrorIcon } from '../../../assets/icons';

const TableError: FunctionComponent = () => {
  return (
    <div className={styles.noDataContainer}>
      <div className='errorLogo'>
        <TableErrorIcon />
      </div>
      <p className={styles.error}>
        {' '}
        There was a problem encountered while loading <br /> your data. Please refresh this page to reload{' '}
      </p>
    </div>
  );
};

export default TableError;
