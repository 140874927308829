import React from 'react';
import { ReactComponent as BacIcon } from '../../../../assets/icons/icn_bac-small.svg';
import { ReactComponent as SelfCareIcon } from '../../../../assets/icons/icn_self-care.svg';
import { ReactComponent as StrapIcon } from '../../../../assets/icons/icn_strap.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/icons/icn_message.svg';

import ExplanationBar from '../explanation-bar';
import ScoreBar from '../score-bar';
import { getEventIcon } from '../../../../utils/event-helper';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>ENGAGEMENT SCORE</h3>
      <p>
        Engagement is a measurement of how your client is keeping up with your treatment program, including their usage
        of the app, health band, and BAC check-ins. The Engagement chart plots the Engagement Score hourly (from 0 to
        100) across your client’s recovery journey.
      </p>
      <p>Client’s can increase their engagement score by:</p>
      <ul>
        <li>
          <figure>{getEventIcon('MEETING', 10, 10, '#444444', undefined, false)}</figure>
          attending <strong>meetings</strong>
        </li>
        <li>
          <figure>
            <BacIcon />
          </figure>
          completing <strong>BAC check-ins</strong>
        </li>
        <li>
          <figure>
            <MessageIcon />
          </figure>
          <strong>starting communications</strong> with supporters through the app’s hub page
        </li>
        <li>
          <figure>{getEventIcon('SURVEY', 11, 14, '#444444', undefined, false)}</figure>
          taking morning and evening <strong>surveys</strong>
        </li>
        <li>
          <figure>
            <SelfCareIcon />
          </figure>
          using <strong>self-care</strong> content in the app
        </li>
        <li>
          <figure>
            <StrapIcon />
          </figure>
          <strong>wearing</strong> their charged health band (for full credit, wristband needs to be worn &emsp; &nbsp;
          20 hours per day)
        </li>
      </ul>
      <p>
        <strong>Engagement ranges</strong>
      </p>
      <h5>
        <ScoreBar
          scoreList={[
            { width: '30%', value: 30, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
            { width: '30%', value: 60, color: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)' },
            { width: '20%', value: 80, color: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 70%)' },
            { width: '20%', value: 100, color: 'linear-gradient(90deg, #6BE3B9 100%, #5CC5E2 100%)' },
          ]}
        />
      </h5>
      <ExplanationBar
        rangeList={[
          { width: '30%', text: 'Effort needed' },
          { width: '30%', text: 'Okay' },
          { width: '20%', text: 'High' },
          { width: '20%', text: 'Engaged' },
        ]}
      />
    </div>
  );
};

export default InfoBox;
