import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import Modal from '../modal/Modal';
import styles from './upsert-client.module.scss';
import { Patient } from '../../interfaces/patient';
import { EPISODE_OF_CARE } from '../../utils/constants';
import { displayFullName } from '../../utils/data-parser';
import { Name } from '../../interfaces/common';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  client: Patient;
  removePatientAction: (patientId: string) => Promise<void>;
};

const RemoveClientConfirmation: FC<Props> = ({ setShow, client, removePatientAction }) => {
  const displayName = useCallback((name: Name) => displayFullName(name), []);

  const onRemove = clientId => {
    removePatientAction(clientId);
    setShow(false);
  };

  return (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.removeModal}>
        <div className={styles.header}>
          <span>{`Remove ${displayName(client.name)}`}</span>
        </div>
        <div className={styles.body}>
          <span className={styles.confirmationText}>
            {`Please confirm that pending client, ${displayName(client.name)}, should be removed from the ${
              EPISODE_OF_CARE[client.episodeOfCare]
            } episode of care`}
          </span>
          <span className={styles.footNote}>
            Note: {displayName(client.name)} will be permanently removed from the current Clients List and will not be
            listed in that regard in the clinic Alumni list or any other clinic lists. This action cannot be undone.
          </span>
        </div>
        <div className={styles.buttons}>
          <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
            Cancel
          </button>
          <button className={styles.submitBtn} type='button' onClick={() => onRemove(client.id)}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveClientConfirmation;
