import React, { useMemo } from 'react';
import { SelectInput } from '../../common/form';
import styles from '../upsert-client.module.scss';
import { RemoteData } from '../../../utils/remote-data';
import { Device, Devices, IndistinguishableDevices } from '../../../interfaces/device';

type Props = {
  formValues: any;
  devices: RemoteData<Devices>;
  indistinguishableDevices: RemoteData<IndistinguishableDevices>;
  editMode: boolean;
};

const AssignedDevices: React.FC<Props> = ({ formValues, devices, indistinguishableDevices, editMode }) => {
  const firstDevice = useMemo(() => formValues?.devicesIssued?.deviceName1, []);
  const secondDevice = useMemo(() => formValues?.devicesIssued?.deviceName2, []);

  const allowBACDropdown = (data: IndistinguishableDevices) => {
    if (data.values.length > 0 && data.values[0].availableDevices > 0) return true;
    return editMode && !!formValues?.devicesIssued?.bacDeviceProvided;
  };

  return (
    <div className={styles.body}>
      <SelectInput
        label='Healthband Assigned Device Number:'
        name='devicesIssued.deviceName1'
        size='big'
        defaultValue={formValues?.devicesIssued?.deviceName1 || undefined}
      >
        <option value={undefined} />
        {firstDevice && <option value={firstDevice}>{firstDevice}</option>}
        {devices.status === 'Done' &&
          devices.data.values
            .filter(d => d.name !== formValues.devicesIssued?.deviceName2)
            .map((dev, idx) => (
              <option key={idx} value={dev.name}>
                {dev.name}
              </option>
            ))}
      </SelectInput>
      {/* Second Band */}
      {/* <SelectInput
        label='Optional 2nd Healthband assigned. Device name:'
        name='devicesIssued.deviceName2'
        size='big'
        defaultValue={formValues?.devicesIssued.deviceName2 || undefined}
      >
        <option value={undefined} />
        {secondDevice && <option value={secondDevice}>{secondDevice}</option>}
        {devices.status === 'Done' &&
          devices.data.values
            .filter(d => d.name !== formValues.devicesIssued?.deviceName1)
            .map((dev, idx) => (
              <option key={idx} value={dev.name}>
                {dev.name}
              </option>
            ))}
      </SelectInput> */}
      {indistinguishableDevices.status === 'Done' && allowBACDropdown(indistinguishableDevices.data) ? (
        <SelectInput
          label='BAC Device Provided:'
          name='devicesIssued.bacDeviceProvided'
          size='big'
          defaultValue={formValues?.devicesIssued?.bacDeviceProvided === true ? 'YES' : 'NO'}
        >
          <option value='' />
          <option value='YES'>Yes</option>
          <option value='NO'>No</option>
        </SelectInput>
      ) : (
        <div className={styles.field__big}>
          <label>BAC Device Provided:</label>
          <input className={styles.inputDisabled} disabled={true} value='No' />
        </div>
      )}
    </div>
  );
};

export default AssignedDevices;
