import React, { FC } from 'react';
import moment from 'moment-timezone';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Patient } from '../../../interfaces/patient';
import { Practitioner } from '../../../interfaces/practitioner';
import { displayFullName } from '../../../utils/data-parser';

const styles = StyleSheet.create({
  docInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: 24,
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 17,
    paddingTop: 17,
    marginBottom: 63,
  },
});

type Props = {
  patient: Patient;
  practitioner: Practitioner;
  dateRange: { start: string; end: string; timestamp?: string };
};

const DocInfoHeader: FC<Props> = ({ patient, practitioner, dateRange }) => {
  const timezone = moment.tz.guess();
  return (
    <View style={styles.docInfo} fixed>
      <Text>Client: {displayFullName(patient.name)}</Text>
      <Text>Practitioner: {displayFullName(practitioner.name)}</Text>
      <Text>Clinic: {practitioner.organization.name}</Text>
      <Text>
        Period:{' '}
        {`${moment.utc(dateRange.start).tz(timezone).format('MMM DD, YYYY')} to ${moment
          .utc(dateRange.end)
          .tz(timezone)
          .format('MMM DD, YYYY')}`}
      </Text>
      <Text>Generated: {moment.utc(dateRange.timestamp!).tz(timezone).format('LLL z')}</Text>
    </View>
  );
};

export default DocInfoHeader;
