import React, { FunctionComponent } from 'react';
import styles from '../upsert-milestone-plan-container.module.scss';
import { DateTimeInput, TextInput } from '../../../common/form';

type RecoveryMilestoneInfoProps = {};

const RecoveryMilestoneInfo: FunctionComponent<RecoveryMilestoneInfoProps> = ({}) => {
  return (
    <div className={styles.body}>
      <TextInput label='Recovery milestones plan name:' name='planName' size='big' />
      <DateTimeInput label='Recovery milestones plan launch date:' name='launchTimestamp' size='big' />
    </div>
  );
};

export default RecoveryMilestoneInfo;
