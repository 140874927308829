import React from 'react';
import { getEventIcon } from 'utils/event-helper';
import { HEX_COLORS } from 'utils/constants';
import { ReactComponent as SpecialIcon } from 'assets/icons/icn-special.svg';
import { ReactComponent as MeetingAttendedIcon } from 'assets/icons/icn_map_pin_attended.svg';
import { ReactComponent as MeetingMissedIcon } from 'assets/icons/icn_map_pin_missed.svg';
import { ReactComponent as BacPassedIcon } from 'assets/icons/icn-bac-passed.svg';
import { ReactComponent as BacFailedIcon } from 'assets/icons/icn-bac-failed.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/map-pin-location-now.svg';
import { ReactComponent as LocationYesterdayIcon } from 'assets/icons/map-pin-location-yesterday.svg';
import { ReactComponent as LocationPastIcon } from 'assets/icons/map-pin-location-past-days.svg';

import markerClusterIcon from 'assets/icons/cluster-marker-icon.png';
import styles from './location-info-box.module.scss';
import { displayDateTimeFormat } from '../../../../utils/data-parser';

const LocationInfoBox = (): JSX.Element => {
  return (
    <div className={styles.infoTooltip}>
      <h3>LOCATION</h3>
      <p>The location path show detailed information about the client’s travels and events.</p>
      <p>Events found on the location map are as follows:</p>

      <ul className={styles.container}>
        <li className={styles.row}>
          <span className={styles.item}>
            <figure>{getEventIcon('GEOFENCE', 16, 24, HEX_COLORS.CRITICAL, undefined, false)}</figure>
            Geofence violations
          </span>
          <span className={styles.item}>
            <figure>
              <SpecialIcon />
            </figure>{' '}
            Geofence Special
          </span>
        </li>
        <li className={styles.row}>
          <span className={styles.item}>
            <figure>
              <MeetingAttendedIcon />
            </figure>{' '}
            Attended meeting
          </span>
          <span className={styles.item}>
            <figure>
              <MeetingMissedIcon />
            </figure>{' '}
            Missed meeting
          </span>
        </li>
        <li className={styles.row}>
          <span className={styles.item}>
            <figure>
              <BacPassedIcon />
            </figure>{' '}
            Passed BAC test
          </span>
          <span className={styles.item}>
            <figure>
              <BacFailedIcon />
            </figure>{' '}
            Missed or failed BAC test
          </span>
        </li>
        <li className={styles.row}>
          <span className={styles.item}>
            <figure>
              <LocationIcon />
            </figure>{' '}
            Location path points today
          </span>
          <span className={styles.item}>
            <figure>
              <LocationYesterdayIcon />
            </figure>{' '}
            Location path points yesterday
          </span>
        </li>
        <li className={styles.row}>
          <span className={styles.item}>
            <figure>
              <LocationPastIcon />
            </figure>{' '}
            Location path points prior to yesterday
          </span>
          <span className={styles.item}>
            <figure>
              <img src={markerClusterIcon} alt='cluster-icon' height={40} width={40} />
            </figure>
            Number of location path points concentrated in an area (zoom in to see individual location path points).
          </span>
        </li>
        <li className={styles.row}>
          <span className={`${styles.item} ${styles.big}`}>
            <figure>
              <LocationIcon />
              <div className={styles.smallBox}>
                <p>{displayDateTimeFormat(new Date().toISOString(), 'CST')}</p>
              </div>
            </figure>
            Date and time of the location path point
          </span>
        </li>
      </ul>
    </div>
  );
};

export default LocationInfoBox;
