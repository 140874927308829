import React, { useMemo, Dispatch, SetStateAction } from 'react';
import Table from '../common/table/table';
import styles from './admin-devices.module.scss';
import { Column } from 'react-table';
import { RemoteData } from '../../utils/remote-data';
import { Devices, Device } from '../../interfaces/device';
import TableEmpty from '../common/table/table-empty';
import moment from 'moment-timezone';
import { displayDeviceType } from '../../utils/data-parser';

type Props = {
  devices: RemoteData<Devices>;
  setSelectedDevice: Dispatch<SetStateAction<Device | undefined>>;
  searchResultDevices: Devices | undefined;
  changeTable: () => void;
};

const AssignedDevicesTable: React.FC<Props> = ({ devices, setSelectedDevice, searchResultDevices, changeTable }) => {
  const columns: Column<Device>[] = useMemo(
    () => [
      {
        Header: 'DEVICE NAME',
        accessor: 'name',
        width: 260,
      },
      {
        Header: 'DEVICE TYPE',
        width: 200,
        accessor: originalRow =>
          displayDeviceType({
            type: originalRow.deviceModel.type,
            firmwareVersion: originalRow.firmwareVersion,
          }),
      },
      {
        Header: 'ASSIGNED TO',
        accessor: originalRow => `${originalRow.patientAssignedName?.first} ${originalRow.patientAssignedName?.last}`,
        width: 150,
      },
      {
        Header: 'DATE ASSIGNED',
        accessor: originalRow =>
          originalRow.patientAssignedTimestamp
            ? moment.utc(originalRow.patientAssignedTimestamp).local().format('lll')
            : '-',
        width: 180,
      },
      {
        Header: '',
        accessor: 'id',
        width: 100,
        Cell: ({ cell }): JSX.Element => (
          <span className={styles.action} onClick={() => setSelectedDevice(cell.row.original)}>
            View
          </span>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getRowProps = (row, idx) => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = (cell, idx) => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: devices.status === 'Done' && !devices.data.values ? styles.noContent : styles.table,
      style: {
        marginLeft: '18px',
      },
    };
  };

  return devices.status === 'Done' && !devices.data.values ? (
    <TableEmpty>
      <span className={styles.noDevicesTitle}>No devices assigned</span>
      <span className={styles.noDevicesSub}>Get started by assigning a device to one of your clients.</span>
      <span className={styles.noDevicesBtn} onClick={() => changeTable()}>
        Assign Device
      </span>
    </TableEmpty>
  ) : (
    <Table<Device>
      columns={columns}
      data={searchResultDevices ? searchResultDevices.values : devices.status === 'Done' ? devices.data.values : []}
      isLoading={devices.status === 'Loading'}
      error={devices.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={(row, idx) => getRowProps(row, idx)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={(cell, idx) => getCellProps(cell, idx)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 180, minWidth: 80 }}
      initialState={defaultSorting}
    />
  );
};

export default AssignedDevicesTable;
