import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styles from './aftercare.module.scss';
import SearchInput from '../common/table/search-input';
import { PractitionerContext } from '../../contexts/Practitioner';
import { PractitionerPatientsMachineStates } from '../../machines/patients.machine';
import { getFullPatientFromState, UIViviPatient } from '../../machines/patient.machine';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import TableView from '../home/table-view';
import Modal from '../modal/Modal';
import BacTestRequest from '../compliance/bac/modals/bac-test-request';
import { AftercarePatientsContext } from '../../contexts/AftercarePatientsContext';
import { Patient, ViviPatient } from '../../interfaces/patient';
import UpsertClientContainer from '../admin-clients/upsert-client-container';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import { dischargePatient, DischargePatientRequest, getPatientDetails } from '../../services/api/patient';
import DischargeClientConfirmation from '../admin-clients/discharge-client-confirmation';

const Aftercare: FunctionComponent = () => {
  const { practitioner } = useContext(PractitionerContext);
  const {
    loadPractitionerPatients,
    reloadPractitionerPatient,
    reloadPractitionerPatients,
    aftercarePractitionerPatientsRef,
    aftercarePractitionerPatientsState,
  } = useContext(AftercarePatientsContext);
  const history = useHistory();
  const { addToast } = useToasts();

  const patientsRef = aftercarePractitionerPatientsRef;
  const isLoadingPatients =
    aftercarePractitionerPatientsState === PractitionerPatientsMachineStates.Loading ||
    aftercarePractitionerPatientsState === PractitionerPatientsMachineStates.Idle;
  const patients: UIViviPatient[] = (patientsRef || []).map(getFullPatientFromState);

  const [searchValue, setSearchValue] = useState<string>('');
  const [{ selectedPatient, selectedAction }, setPatientAction] = useState<{
    selectedPatient: ViviPatient | undefined;
    selectedAction: 'Profile' | 'BacTest' | 'Discharged' | string;
  }>({
    selectedPatient: undefined,
    selectedAction: '',
  });
  const [patientFullInfo, setPatientFullInfo] = useState<RemoteData<Patient>>(notAsked());
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showDischargeClientModal, setShowDischargeClientModal] = useState<boolean>(false);

  const getPatientFullInfoAction = async (patientId: string): Promise<void> => {
    setPatientFullInfo(loading());
    const res = await getPatientDetails(patientId);
    setPatientFullInfo(res);
  };

  const dischargePatientAction = async (patientId: string, body: DischargePatientRequest) => {
    const res = await dischargePatient(patientId, body);

    if (res.status === 'Done') {
      reloadPractitionerPatients();
      setShowProfileModal(false);
      addToast('Client discharged successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('Aftercare Page', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
      loadPractitionerPatients(practitioner?.id, 'aftercareView');
    }
  }, [practitioner]);

  useEffect(() => {
    if (!showProfileModal) {
      setPatientAction({ selectedPatient: undefined, selectedAction: '' });
      setPatientFullInfo(notAsked());
      setShowDischargeClientModal(false);
    }
  }, [showProfileModal]);

  useEffect(() => {
    if (selectedPatient && selectedAction === 'Profile') {
      setShowProfileModal(true);
      patientFullInfo.status === 'Not Asked' && getPatientFullInfoAction(selectedPatient.id);
    }
  }, [selectedAction, selectedPatient]);

  useEffect(() => {
    reloadPractitionerPatients();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.menuRow}>
          <SearchInput
            searchValue={searchValue}
            handleInputChange={ev => setSearchValue(ev.target.value)}
            handleOnClick={ev => setSearchValue('')}
            placeholder='Search for Client Name...'
          />
        </div>
        <div>
          {/* listening state means every patient firebase subscription has been created */}
          <TableView
            isLoading={isLoadingPatients || !patients.every(p => p.state === 'LISTENING')}
            history={history}
            patients={patients}
            filterValue={searchValue}
            aftercarePage={true}
            setSelectedPatientAction={setPatientAction}
          />
        </div>
      </div>
      <Modal
        contentStyle='content'
        show={!!selectedPatient && selectedAction === 'BacTest'}
        showBtnClose
        closeModal={() => setPatientAction({ selectedPatient: undefined, selectedAction: '' })}
      >
        <BacTestRequest
          patient={selectedPatient!}
          closeModal={() => setPatientAction({ selectedPatient: undefined, selectedAction: '' })}
        />
      </Modal>
      {!!selectedPatient && selectedAction === 'Profile' && (
        <UpsertClientContainer
          setShow={setShowProfileModal}
          editMode={true}
          selectedPatient={patientFullInfo}
          organization={selectedPatient?.organization}
          firebasePatient={selectedPatient}
          getPatientsAction={() => reloadPractitionerPatient(selectedPatient?.id)}
          refetchPatient={() => getPatientFullInfoAction(selectedPatient?.id)}
          setShowDischargeClientModal={setShowDischargeClientModal}
        />
      )}
      {showDischargeClientModal && patientFullInfo.status === 'Done' && (
        <DischargeClientConfirmation
          setShow={setShowDischargeClientModal}
          client={patientFullInfo.data}
          dischargePatientAction={dischargePatientAction}
        />
      )}
    </div>
  );
};

export default Aftercare;
