import React, { CSSProperties, MouseEventHandler, PropsWithChildren, useState } from 'react';
import {
  Cell,
  HeaderGroup,
  Row,
  TableProps,
  TableCellProps,
  TableHeaderProps,
  TableInstance,
  TableOptions,
  TableRowProps,
  useFlexLayout,
  useSortBy,
  useTable,
  TableBodyProps,
  useExpanded,
} from 'react-table';
import { Scrollbars } from 'react-custom-scrollbars';
import { renderTrackVerticalDefault } from 'react-custom-scrollbars/lib/Scrollbars/defaultRenderElements';
import styles from './table.module.scss';
import { Arrow } from '../../../assets/icons';
import TableEmpty from './table-empty';
import TableError from './table-error';
import TableLoading from './table-loading';

export interface CustomTableProps<T extends object = {}> extends TableOptions<T> {
  onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
  onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
  onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
  onClick?: (row: Row<T>) => void;
  isLoading?: boolean;
  error: boolean;
  getTableProps: () => TableProps;
  getRowProps: (propGetter?: Row<T>, index?: number) => TableRowProps;
  getHeaderProps: (propGetter?: HeaderGroup<T>) => TableHeaderProps;
  getCellProps: (propGetter?: Cell<T>, index?: number) => TableCellProps; // Cell<T>
  getTableBodyProps: () => TableBodyProps;
  rowStyles?: CSSProperties;
  emptyDataMessage?: string;
  isModal?: boolean;
  renderRowSubComponent?: (row: Row<T>, rowProps?: TableRowProps) => JSX.Element;
}

// const defaultColumn = {
//     // When using the useFlexLayout:
//     minWidth: 150, // minWidth is only used as a limit for resizing
//     width: 180, // width is used for both the flex-basis and flex-grow
//     maxWidth: 200, // maxWidth is only used as a limit for resizing
// }

const Table = <T extends { id?: number | string }>({
  data,
  columns,
  isLoading,
  error,
  rowStyles,
  getTableProps,
  getRowProps,
  getHeaderProps,
  getCellProps,
  getTableBodyProps,
  emptyDataMessage,
  isModal,
  renderRowSubComponent,
  ...rest
}: PropsWithChildren<CustomTableProps<T>>) => {
  const [hoverOnTable, setHoverOnTable] = useState<boolean>(false);

  const { headerGroups, rows, prepareRow } = useTable<T>(
    {
      columns,
      data,
      ...(rest.initialState && {
        initialState: rest.initialState,
      }),
      ...(rest.defaultColumn && {
        defaultColumn: rest.defaultColumn,
      }),
    },
    useSortBy,
    useFlexLayout,
    useExpanded,
  );
  return (
    <div
      className={styles.contentTable}
      onMouseEnter={() => setHoverOnTable(true)}
      onMouseLeave={() => setHoverOnTable(false)}
    >
      <div {...getTableProps()}>
        <div>
          {headerGroups.map(headerGroup => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className={`${styles.tr} ${isModal ? styles.tableHeaderModal : styles.tableHeader}`}
            >
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps([getHeaderProps(column), column.getSortByToggleProps()])}>
                  {column.render('Header')}
                  <span>{column.isSorted ? <Arrow directionUp={!column.isSortedDesc} /> : ''}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
        {!isLoading && !error && rows?.length ? (
          <Scrollbars
            {...getTableBodyProps()}
            autoHide={false}
            renderTrackHorizontal={props => <div {...props} className='track-horizontal' style={{ display: 'none' }} />}
            renderThumbHorizontal={props => <div {...props} className='thumb-horizontal' style={{ display: 'none' }} />}
            renderTrackVertical={props =>
              hoverOnTable ? (
                renderTrackVerticalDefault({ ...props, className: `track-vertical ${styles.trackVerticalCustom}` })
              ) : (
                <div {...props} className='track-vertical' style={{ display: 'none' }} />
              )
            }
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <div {...row.getRowProps(getRowProps(row, i))}>
                    {row.cells.map((cell, idx) => {
                      return <div {...cell.getCellProps(getCellProps(cell, idx))}>{cell.render('Cell')}</div>;
                    })}
                  </div>
                  {row.isExpanded &&
                    renderRowSubComponent &&
                    renderRowSubComponent(row, row.getRowProps(getRowProps(row, i)))}
                </React.Fragment>
              );
            })}
          </Scrollbars>
        ) : isLoading ? (
          <TableLoading />
        ) : error ? (
          <TableError />
        ) : (
          <TableEmpty isModal={isModal}>
            {' '}
            <p> {emptyDataMessage || 'No data available.'}</p>
          </TableEmpty>
        )}
      </div>
    </div>
  );
};

export default Table;
